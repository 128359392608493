<template>
    <div class="attachment">
        <button class="attachment__download" @click.prevent="browserDownload">
            <i class="mdi mdi-file attachment__thumbnail" />
            <div class="attachment__details">
                <p class="attachment__details__name" :title="filename">
                    {{ filename }}
                </p>
                <p class="attachment__details__size">{{ sizeText }}</p>
            </div>
            <i class="mdi mdi-download attachment__cta-icon" />
        </button>
        <div class="attachment__actions" v-if="isIssueOpen">
            <!--
            <a @click.prevent="preview">{{$t('attachment.preview')}}</a>
            <a @click.prevent="rename">{{$t('attachment.rename')}}</a>
            -->
            <a @click.prevent="remove">{{ $t('general.remove') }}</a>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { attachments } from '@/api';
import { ISSUES } from '@/store/issues';
import pretty from 'prettysize';
import { presentDownload } from '@/api/downloads';
import { AUTH } from '@/store/auth';

@Component
export default class IssueAttachment extends Vue {
    @Prop({ type: Object })
    issue;
    @Prop({ type: Object })
    attachment;

    get filename() {
        if (!this.attachment) return undefined;
        return this.attachment.name;
    }

    get altText() {
        return this.$t('attachment.image_alt', { filename: this.filename });
    }

    get authToken() {
        return this.$store.getters[AUTH.GET_SESSION].token;
    }

    async browserDownload() {
        const blob = await attachments.download(
            this.issue.project_id,
            this.issue.iid,
            this.attachment.id,
        );
        presentDownload(blob, this.filename);
    }

    get sizeText() {
        return pretty(this.attachment.size);
    }

    preview() {
        console.log('preview attachment', this.attachment);
    }

    rename() {
        console.log('rename attachment', this.attachment);
    }

    remove() {
        this.$store.dispatch(ISSUES.REMOVE_ATTACHMENT, {
            issue: this.issue,
            attachment: this.attachment,
        });
    }

    get isIssueOpen() {
        return this.issue.state === 'opened';
    }
}
</script>
