import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormatting from './numbers';

Vue.use(VueI18n);

// Import DateLanguages for all available languages
import en from './en.json';
import nl from './nl.json';

const messages = {
    en,
    nl,
};

export const DEFAULT_LANGUAGE = 'nl';
export const LANGUAGES = [DEFAULT_LANGUAGE, 'en'];

const numberFormats = LANGUAGES.reduce((obj, lang) => {
    obj[lang] = numberFormatting;
    return obj;
}, {});

// Create a new vuei18n object with the locale and all messages.
const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    messages,
    numberFormats,
});

export default i18n;

export function localizeEnum(scope, value) {
    const fullKey = `${scope}.${value}`;
    if (i18n.te(fullKey)) {
        return i18n.t(fullKey);
    } else {
        return value;
    }
}

/**
 * Localizes a timespan of seconds into a workweek duration.
 * Using gitlabs standard of 8h per day, 5d per week and 4w per month.
 * @param {number} seconds
 * @param {string} fallback - The string to present if the time is too small to present
 * @returns {string|null}
 */
export function localizeWorktime(seconds, fallback = i18n.t('common.worktime.none')) {
    console.assert(typeof seconds === 'number', 'seconds not a number (%s)', typeof seconds);
    console.assert(Number.isFinite(seconds), 'seconds not finite');

    const DURATION_SECOND = 1;
    const DURATION_MINUTE = DURATION_SECOND * 60;
    const DURATION_HOUR = DURATION_MINUTE * 60;

    let hours = (seconds / DURATION_HOUR).toFixed(2);

    if (hours > 0) {
        return i18n.t('common.worktime.hours', { hours });
    } else {
        return fallback;
    }
}
