<template>
    <form @submit.prevent="saveComment" class="block__form" style="margin-top: 25px;">
        <div class="vue-simplemde--with-uploads">
            <markdown-editor
                :configs="mdeConfigs"
                v-model="commentField"
                ref="editCommentEditor"
                @drop="onAttachmentDropped"
            />
            <div class="vue-simplemde--with-uploads__upload-button">
                <upload-button
                    @upload="onManualUpload"
                    button-class="button--link"
                    icon-class="mdi mdi-upload"
                />
            </div>
        </div>
        <button
            type="submit"
            class="button--cta"
            :class="{ loading: commentBusy }"
            :disabled="commentBusy"
        >
            {{ $t('issue_comments.button_save_comment') }}
        </button>

        <button
            class="button--alt"
            style="margin-left: 10px;"
            :class="{ loading: commentBusy }"
            :disabled="commentBusy"
            @click.prevent="$emit('cancel')"
        >
            {{ $t('general.cancel') }}
        </button>
    </form>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import MarkdownEditor from '@/components/general/markdown-editor';
import UploadButton from '@/components/general/UploadButton';
import { attachments } from '../../api';

@Component({
    components: {
        MarkdownEditor,
        UploadButton,
    },
})
export default class EditCommentForm extends Vue {
    @Prop({ type: Object })
    comment;
    @Prop({ type: Object })
    issue;

    data() {
        return {
            commentField: this.comment.message,
        };
    }

    commentBusy = false;

    get canPostComment() {
        return !this.commentBusy && this.commentField.length;
    }

    get mdeConfigs() {
        return {
            toolbar: [
                'bold',
                'italic',
                'strikethrough',
                '|',
                'heading',
                'heading-smaller',
                'heading-bigger',
                '|',
                'quote',
                'code',
                '|',
                'unordered-list',
                'ordered-list',
                '|',
                'link',
                '|',
                'preview',
                '|',
                'guide',
            ],
            shortcuts: {
                // disable side by side and fullscreen shortcuts since they break the Vue Modal
                toggleSideBySide: '',
                toggleFullScreen: '',
            },
            dragDrop: true,
            placeholder: this.$t('issue_comments.new_comment_placeholder'),
            status: false,
            spellChecker: false,
        };
    }

    onAttachmentDropped(e) {
        const files = Array.from(e.dataTransfer.files);
        this.addAttachmentsToComment(files);
    }

    onManualUpload(file) {
        this.addAttachmentsToComment([file]);
    }

    async addAttachmentsToComment(files) {
        if (!files.length) return;
        const uploadIdentifier = '[Upload...]()';
        this.$refs.editCommentEditor.insertText(uploadIdentifier);
        try {
            const response = await attachments.addCommentFiles(
                this.issue.project_id,
                this.issue.iid,
                files,
            );
            let index = this.commentField.indexOf(uploadIdentifier);
            if (index > -1) {
                this.commentField = this.commentField.replace(
                    uploadIdentifier,
                    response.markdown.trim(),
                );
            } else {
                this.commentField = this.commentField + response.markdown.trim();
            }
        } catch (error) {
            this.commentField = this.commentField.replace(uploadIdentifier, '[error]');
        }
    }

    saveComment() {
        if (!this.canPostComment) return;
        this.commentBusy = true;
        this.$emit('updateComment', this.comment, this.commentField);
    }
}
</script>
