import { attachments, issues } from '@/api';
import Vue from 'vue';
import { PROJECTS } from './projects';

const namespace = 'issues/';

export const ISSUES = Object.freeze({
    STORE: namespace + 'store',
    GET: namespace + 'get',
    GET_FROM_CACHE: namespace + 'get_cached',
    ADD_ATTACHMENT: namespace + 'add_attachment',
    REMOVE_ATTACHMENT: namespace + 'remove_attachment',
    CREATED_NEW: namespace + 'created_new',
});

function isDetailed(issue) {
    return issue.files !== undefined && issue.files !== null;
}

function normalizeIssue(original) {
    return {
        // Define detail fields, so they are reactive
        files: null,
        progress_comments: null,
        // Fill instance, if original is detailed the null's are overwritten
        ...original,
    };
}

function issueFromCache(cache, project_id, iid, detailsRequired) {
    const projectCache = cache[project_id];
    if (!projectCache) return undefined;
    const instance = projectCache[iid];
    if (!instance) return undefined;
    if (detailsRequired && !isDetailed(instance)) return undefined;
    return instance;
}

function ensureCacheEntry(cache, key) {
    if (!cache[key]) {
        Vue.set(cache, key, {});
    }
    return cache[key];
}

export default {
    state: () => {
        return {
            cache: {},
            loading: [],
            showCreationNotification: false,
        };
    },
    mutations: {
        [ISSUES.STORE]: (state, issues) => {
            issues.forEach(issue => {
                issue = normalizeIssue(issue);
                const projectCache = ensureCacheEntry(state.cache, issue.project_id);
                const cachedIssue = ensureCacheEntry(projectCache, issue.iid);
                Object.keys(issue).forEach(key => {
                    Vue.set(cachedIssue, key, issue[key]);
                });
            });
        },
        [ISSUES.ADD_ATTACHMENT]: (state, { issue, attachment }) => {
            issue = issueFromCache(state.cache, issue.project_id, issue.iid, true);
            if (issue) {
                issue.files.push(attachment);
            }
        },
        [ISSUES.REMOVE_ATTACHMENT]: (state, { issue, attachment }) => {
            issue = issueFromCache(state.cache, issue.project_id, issue.iid, true);
            console.log('removing from', issue);
            if (issue) {
                const removeIndex = issue.files.findIndex(att => att.id === attachment.id);
                issue.files.splice(removeIndex, 1);
            }
        },
        [ISSUES.CREATED_NEW]: (state, { showCreationNotification, showAttachmentError }) => {
            state.showCreationNotification = showCreationNotification;
            state.showAttachmentError = showAttachmentError;
        },
    },
    actions: {
        [ISSUES.GET]: async ({ state, dispatch }, { project_id, iid, refetch, details }) => {
            // If not forcing refetch, check the cache and resolve to the cached issue if present
            if (!refetch) {
                const cachedIssue = issueFromCache(state.cache, project_id, iid, details);
                if (cachedIssue) {
                    return Promise.resolve(cachedIssue);
                }
            }
            const issue = await issues.single({ project_id, iid });
            console.assert(
                typeof project_id === 'number',
                'project_id not a number (%s instead)',
                typeof project_id,
            );
            const [storedIssue] = await dispatch(ISSUES.STORE, [issue]);
            return storedIssue;
        },
        [ISSUES.STORE]: async ({ state, commit, dispatch }, issues) => {
            const projectLessIssues = issues.filter(issue => !issue.project);
            if (projectLessIssues.length) {
                const projects = await dispatch(PROJECTS.FETCH);
                projectLessIssues.forEach(issue => {
                    const project_id = issue.project_id;
                    const project = projects.find(p => p.id === project_id);
                    console.assert(project, 'No project for id %d', project_id);
                    Vue.set(issue, 'project', project);
                });
            }
            commit(ISSUES.STORE, issues);
            return issues.map(issue => issueFromCache(state.cache, issue.project_id, issue.iid));
        },
        [ISSUES.ADD_ATTACHMENT]: async ({ commit }, { issue, name, content, extraOptions }) => {
            const id = await attachments.add(
                issue.project_id,
                issue.iid,
                name,
                content,
                extraOptions,
            );
            const attachment = {
                id,
                name,
                content,
                size: content.length || content.size,
            };
            commit(ISSUES.ADD_ATTACHMENT, { issue, attachment });
            return attachment;
        },
        [ISSUES.REMOVE_ATTACHMENT]: async ({ commit }, { issue, attachment }) => {
            commit(ISSUES.REMOVE_ATTACHMENT, { issue, attachment });
            try {
                await attachments.remove(issue.project_id, issue.iid, attachment.id);
            } catch (error) {
                commit(ISSUES.ADD_ATTACHMENT, { issue, attachment });
                throw error;
            }
        },
    },
    getters: {
        [ISSUES.GET_FROM_CACHE]: state => ({ project_id, iid, details }) =>
            issueFromCache(state.cache, project_id, iid, details),
    },
};
