<template>
    <div class="block">
        <h2>{{ $t('project.users.title') }}</h2>
        <!-- TODO: Nienke: Styling of the list -->
        <ul v-for="user in users" :key="user.id">
            <li>{{ user.name }}</li>
        </ul>
    </div>
</template>
<style scoped lang="scss">
.block_list {
    li {
        display: block;
    }
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import Optional from 'optional-js';

@Component({
    mixins: [ProjectsStoremap],
})
export default class ProjectUsers extends Vue {
    @Prop()
    projectId;

    /**
     * @returns {Optional<Project>}
     */
    get project() {
        return Optional.ofNullable(this.projects.find(p => p.id === this.projectId));
    }

    /**
     * @returns {ProjectUser[]}
     */
    get users() {
        return this.project.map(/** @type {Project} */ project => project.users).orElse([]);
    }
}
</script>
