<template>
    <div class="tabs">
        <div class="tabs__bar">
            <button
                v-for="(tab, index) in tabs"
                :key="index"
                @click.prevent="show(tab)"
                :class="currentTab === tab ? 'button--cta' : 'button'"
            >
                {{ tab.name(component) }}
            </button>
        </div>
        <div class="tabs__content" v-if="currentTab">
            <component :is="currentTab.impl" :component="component" />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ActionsTab from './ActionsTab';
import TogglesTab from './TogglesTab';
import StateTab from './StateTab';
import { getDevbarOptions } from '@/devbar/decorators';

/**
 * @typedef {Object} TabConfig
 * @property {ComponentOptions<Vue>} impl
 * @property {function(Vue)} name
 */

@Component
export default class ComponentTabs extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, default: null })
    component;

    /** @member {Array<TabConfig>} */
    tabs = [
        {
            impl: StateTab,
            name() {
                return 'State';
            },
        },
        {
            impl: TogglesTab,
            name(component) {
                const options = getDevbarOptions(component) || { toggles: [] };
                return `Toggles (${options.toggles.length})`;
            },
        },
        {
            impl: ActionsTab,
            name(component) {
                const options = getDevbarOptions(component) || { actions: [] };
                return `Actions (${options.actions.length})`;
            },
        },
    ];
    /** @member {TabConfig} */
    currentTab = null;

    created() {
        this.currentTab = this.tabs[0];
    }

    show(tab) {
        this.currentTab = tab;
    }
}
</script>
