function buildConverters(type) {
    switch (type) {
        case 'string':
            return {
                deserialize: string => string,
                serialize: value => value,
            };
        case 'number':
            return {
                deserialize: string => (string ? parseInt(string) : undefined),
                serialize: value => value,
            };
        case 'float':
            return {
                deserialize: string => (string ? parseFloat(string) : undefined),
                serialize: value => value,
            };
        default:
            throw new Error(`type conversion for '${type}' not supported`);
    }
}

export function ReactiveRouteParamMixin({
    routeParam,
    propName = routeParam,
    readonly = false,
    setter = null,
    type = 'string',
    deserialize,
    serialize,
}) {
    const newConverters = buildConverters(type);
    deserialize = deserialize || newConverters.deserialize;
    serialize = deserialize || newConverters.deserialize;
    const reactive = {
        get: function() {
            const param = this.$route.params[routeParam];
            return deserialize(param);
        },
    };
    if (readonly) {
        reactive.set = function() {
            throw new Error(`Reactive binding for ${propName} is configured as readonly`);
        };
    } else if (setter) {
        if (typeof setter === 'function') {
            reactive.set = function(value) {
                setter.call(this, value);
            };
        } else {
            console.assert(typeof setter === 'string');
            reactive.set = function(value) {
                this[setter](value);
            };
        }
    } else {
        reactive.set = function(value) {
            const params = {};
            params[routeParam] = serialize(value);
            // TODO: Make this global behavour by overriding Router.prototype.push()
            // https://github.com/vuejs/vue-router/issues/2881#issuecomment-528751982
            this.$router.push({ params }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
        };
    }
    return {
        computed: {
            [propName]: reactive,
        },
    };
}

import { createDecorator } from 'vue-class-component';

export function ReactiveRouteParam(options = {}) {
    return createDecorator((vm, key) => {
        options = {
            routeParam: key,
            ...options,
            propName: key,
        };
        const mixin = ReactiveRouteParamMixin(options);
        if (!vm.mixins) {
            vm.mixins = [];
        }
        vm.mixins.push(mixin);
    });
}
