<template>
    <div class="attachment">
        <div class="attachment__download">
            <a class="attachment__thumbnail--icon" @click.prevent="action">
                <i class="mdi" :class="icon"></i>
            </a>
            <div class="attachment__details">
                <p class="attachment__details__name">{{ upload.file.name }}</p>
                <p class="attachment__details__size">{{ uploadInfo }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Upload, UPLOAD_STATE } from './Upload';

@Component
export default class UploadQueueItem extends Vue {
    @Prop({ type: Upload, required: true })
    /** @member {Upload} */
    upload;

    action() {
        switch (this.upload.state) {
            case UPLOAD_STATE.UPLOADING:
                this.cancel();
                break;
            case UPLOAD_STATE.DONE:
            case UPLOAD_STATE.WAITING:
                this.remove();
                break;
            case UPLOAD_STATE.CANCELLED:
            case UPLOAD_STATE.ERROR:
                this.retry();
                break;
            default:
                throw new Error('unknown upload state');
        }
    }

    get icon() {
        switch (this.upload.state) {
            case UPLOAD_STATE.CANCELLED:
            case UPLOAD_STATE.ERROR:
                return 'mdi-restart';
            default:
                return 'mdi-close';
        }
    }

    get uploadInfo() {
        let result = this.upload.localizedStatus;
        if (
            this.upload.state === UPLOAD_STATE.UPLOADING ||
            this.upload.state === UPLOAD_STATE.DONE
        ) {
            result += ` (${this.upload.localizedProgress})`;
        }
        return result;
    }

    cancel() {
        this.$emit('cancel', this.upload);
    }

    remove() {
        this.$emit('remove', this.upload);
    }

    retry() {
        this.$emit('retry', this.upload);
    }
}
</script>
