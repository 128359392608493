var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-multiselect',{attrs:{"options":_vm.labels,"label":"name","multiple":_vm.multiple,"track-by":"id","taggable":_vm.canCreate,"select-label":_vm.$t('label_dropdown.select_label'),"selected-label":_vm.$t('label_dropdown.selected_label'),"deselect-label":_vm.$t('label_dropdown.deselect_label'),"placeholder":_vm.multiple ? _vm.$t('label_dropdown.placeholder_multiple') : _vm.$t('label_dropdown.placeholder'),"disabled":_vm.disabled},on:{"tag":_vm.createTag},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var option = ref.option;
var search = ref.search;
var remove = ref.remove;
return [_c('project-label-badge',{staticClass:"multiselect__tag",attrs:{"color":option.color}},[(option.id !== -1)?_c('span',[_vm._v(_vm._s(_vm.getOptionLabel(option)))]):_c('span',[_vm._v(_vm._s(_vm.$t('common.labels.all')))]),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return remove(option)},"mousedown":function($event){$event.preventDefault();return remove(option)}}})])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [(option.isTag)?_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('label_dropdown.create_new', { label: option.label }))+"\n        ")]):(option.id !== -1)?_c('span',[_vm._v(_vm._s(option.name)+" ("+_vm._s(option.openIssues)+")")]):_c('span',[_vm._v(_vm._s(_vm.$t('common.labels.all')))])]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.id > 0)?_c('span',[_vm._v(_vm._s(option.name)+" ("+_vm._s(option.openIssues)+")")]):_c('span',[_vm._v(_vm._s(_vm.$t('common.labels.all')))])]}}]),model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}},[_c('template',{slot:"noResult"},[(_vm.multiple)?_c('span',[_vm._v(_vm._s(_vm.$t('label_dropdown.no_result')))]):_c('span',[_vm._v(_vm._s(_vm.$t('general.not_found')))])]),_c('template',{slot:"noOptions"},[_vm._v("\n        "+_vm._s(_vm.$t('label_dropdown.no_result'))+"\n    ")]),_c('template',{slot:"maxElements"},[_vm._v("\n        "+_vm._s(_vm.$t('label_dropdown.max_elements'))+"\n    ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }