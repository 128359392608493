import axios from 'axios';
import { ISSUES } from '@/store/issues';
import store from '@/store';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';
import i18n from '@/languages';

export const UPLOAD_STATE = Object.freeze({
    UPLOADING: 0,
    DONE: 1,
    ERROR: 2,
    CANCELLED: 3,
    WAITING: 4,
});

export class Upload {
    /** @member {Object} */
    issue;
    /** @member {File} */
    file;
    /** @member {number} */
    state;
    /** @member {number} */
    errorCode;
    /** @member {number} */
    progress;
    /**
     * @member {{cancel: Function, token: CancelToken}}
     * @private
     */
    _cancelSource;

    /**
     * Starts a new upload and creates an object which represents the upload process
     * @param {Object} issue - The issue to upload a new attachment for
     * @param {File} file - The file to upload
     */
    constructor(issue, file) {
        this.issue = issue;
        this.file = file;
        this.state = UPLOAD_STATE.WAITING;
        this.errorCode = ERROR_CODES.NONE;
        this.progress = 0;
        this._cancelSource = axios.CancelToken.source();
    }

    /**
     * Starts the upload and returns the promise which resolves to when the upload is done.
     * @returns {Promise<void>}
     */
    start() {
        console.assert(this.state === UPLOAD_STATE.WAITING, 'starting upload again');
        this.state = UPLOAD_STATE.UPLOADING;
        return store
            .dispatch(ISSUES.ADD_ATTACHMENT, {
                issue: this.issue,
                name: this.file.name,
                content: this.file,
                extraOptions: {
                    cancelToken: this._cancelSource.token,
                    onUploadProgress: this._onProgress.bind(this),
                },
            })
            .then(
                () => {
                    console.log('[upload] marked done');
                    this.state = UPLOAD_STATE.DONE;
                },
                error => {
                    if (axios.isCancel(error)) {
                        console.log('[upload] marked cancelled');
                        this.state = UPLOAD_STATE.CANCELLED;
                    } else {
                        console.log('[upload] marked error');
                        this.state = UPLOAD_STATE.ERROR;
                        this.errorCode = error.errorCode;
                    }
                    return Promise.reject(error);
                },
            );
    }

    /**
     * Handler for the progress callback from the xhr request axios creates.
     * @param {ProgressEvent} event
     * @private
     */
    _onProgress(event) {
        this.progress = event.loaded / event.total;
    }

    createRetry() {
        return new Upload(this.issue, this.file);
    }

    cancel() {
        console.log('[upload] cancelling');
        this._cancelSource.cancel();
    }

    get localizedStatus() {
        switch (this.state) {
            case UPLOAD_STATE.WAITING:
                return i18n.t('common.upload-state.waiting');
            case UPLOAD_STATE.UPLOADING:
                return i18n.t('common.upload-state.busy');
            case UPLOAD_STATE.CANCELLED:
                return i18n.t('common.upload-state.cancelled');
            case UPLOAD_STATE.DONE:
                return i18n.t('common.upload-state.done');
            case UPLOAD_STATE.ERROR:
                return i18n.t('common.upload-state.error');
        }
        return undefined;
    }

    get localizedErrorCode() {
        return localizeNetworkErrorCode(this.errorCode);
    }

    get localizedProgress() {
        return i18n.n(this.progress, 'percentage');
    }

    get isWaiting() {
        return this.state === UPLOAD_STATE.WAITING;
    }

    get isBusy() {
        return this.state === UPLOAD_STATE.UPLOADING;
    }

    get isDone() {
        return this.state === UPLOAD_STATE.DONE;
    }

    get isCancelled() {
        return this.state === UPLOAD_STATE.CANCELLED;
    }

    get isError() {
        return this.state === UPLOAD_STATE.ERROR;
    }
}

export class FakeUpload extends Upload {
    constructor(issue, state) {
        super(issue, { name: 'fake-upload.png', size: 1000 });
        this.state = state;
        this.progress = 0.5;
    }

    async start() {
        this.state = UPLOAD_STATE.UPLOADING;
    }

    createRetry() {
        return new FakeUpload(this.issue, this.state);
    }

    cancel() {
        this.state = UPLOAD_STATE.CANCELLED;
    }
}
