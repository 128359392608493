<template>
    <p>SSO</p>
</template>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AUTH } from '@/store/auth';

@Component
export default class SSOHandler extends Vue {
    name = 'sso';

    async created() {
        const ssoTestKey = 'sso.performed';
        const performedSsoAlready = sessionStorage.getItem(ssoTestKey);
        const token = this.$route.query.token;
        if (performedSsoAlready || !token) {
            this.$router.replace({
                name: 'Home',
            });
            return;
        }
        this.$store
            .dispatch(AUTH.SSO, token)
            .then(() => {
                sessionStorage.setItem(ssoTestKey, true);
                const redirect = this.$route.query.redirect;
                if (redirect) {
                    this.$router.replace(redirect);
                } else {
                    this.$router.replace({
                        name: 'Home',
                    });
                }
            })
            .catch(error => {
                this.$router.replace({
                    name: 'Home',
                });
                throw error;
            });
    }
}
</script>
