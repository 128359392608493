<template>
    <div class="issues--full_width">
        <div>
            <div class="row issues__header">
                <div class="col-10">
                    <h1>{{ $t('common.features') }}</h1>
                    <projects-dropdown
                        v-model="project_id"
                        class="multiselect--alt"
                        v-if="!hasMonoProject"
                    />
                </div>
                <div class="col">
                    <router-link class="pull-right button--cta" :to="createIssueRoute">
                        <i class="mdi mdi-plus-circle-outline" />
                        {{ $t('feature_modal.add_feature') }}
                    </router-link>
                </div>
            </div>
            <div class="issues__search-row">
                <div class="col-12 col-md order-last order-md-first">
                    <search-box
                        v-model="searchQuery"
                        @search="submitQuery"
                        :placeholder="$t('general.search_feature')"
                    />
                </div>
                <div class="col-12 col-md-auto order-first order-md-last">
                    <issue-view-links />
                </div>
            </div>
        </div>

        <scrum-board :filter="filter" />
    </div>
</template>

<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import ScrumBoard from '@/components/scrum/ScrumBoard';
import ProjectsDropdown from '@/components/general/ProjectsDropdown';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import IssueViewLinks from '@/components/issues/IssueViewLinks';
import SearchBox from '@/components/general/SearchBox';
import { BREADCRUMBS } from '@/store/breadcrumbs';
import { MonoProjectMixin } from '@/mixins/mono-project';
import { MonoProjectRedirectMixin } from '@/mixins/mono-project-redirect';

@Component({
    components: { SearchBox, IssueViewLinks, ProjectsDropdown, ScrumBoard },
    mixins: [ProjectIdMixin, MonoProjectMixin, MonoProjectRedirectMixin],
})
export default class ScrumBoardPage extends Vue {
    get filter() {
        return {
            project_id: this.project_id,
            query: this.confirmedSearchQuery,
        };
    }

    get linkNewIssue() {
        return {
            name: 'Issue.New',
            params: {
                project_id: this.$route.params.project_id,
            },
        };
    }

    searchQuery = '';
    confirmedSearchQuery = null;

    submitQuery({ updateRoute = true } = {}) {
        this.confirmedSearchQuery = this.searchQuery;
        if (updateRoute) {
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    search: this.filter.query,
                },
            });
        }
    }

    @Watch('$route.query.search', { immediate: true })
    onRouteSearchChanged(newValue) {
        if (!newValue) {
            newValue = '';
        }
        this.searchQuery = newValue;
        this.submitQuery({ updateRoute: false });
    }

    created() {
        this.$store.commit(BREADCRUMBS.ISSUES_PAGE_VISIT, 'Scrumboard');
    }

    get createIssueRoute() {
        return {
            name: 'Issue.New',
            params: {
                project_id: this.$route.params.project_id,
            },
        };
    }
}
</script>
