<template>
    <div class="block">
        <h2>{{ $t('issue_comments.title') }}</h2>
        <issue-comment
            v-for="(comment, index) in comments"
            :key="index"
            :comment="comment"
            :issue="issue"
            @reply="reply"
            @updateComment="updateComment"
            @deleteComment="deleteComment"
            ref="commentEntry"
        />
        <form @submit.prevent="postComment" class="block__form">
            <h2>
                {{ $t('issue_comments.new_comment') }}
            </h2>
            <div class="vue-simplemde--with-uploads">
                <markdown-editor
                    :configs="mdeConfigs"
                    v-model="commentField"
                    ref="newCommentEditor"
                    @drop="onAttachmentDropped"
                />
                <div class="vue-simplemde--with-uploads__upload-button">
                    <upload-button
                        @upload="onManualUpload"
                        button-class="button--link"
                        icon-class="mdi mdi-upload"
                    />
                </div>
            </div>
            <div v-if="localizedCommentError" class="alert--error">
                <p>{{ localizedCommentError }}</p>
            </div>
            <button
                type="submit"
                class="button--cta"
                :class="{ loading: commentBusy }"
                :disabled="commentBusy"
            >
                {{ $t('issue_comments.button_post_comment') }}
            </button>
            <span v-if="canReopenIssue" class="checkbox--aligned_with_button">
                <input
                    class="checkbox__cb"
                    type="checkbox"
                    v-model="commentReopen"
                    id="new-comment-reopen"
                />
                <label class="checkbox__label" for="new-comment-reopen">
                    {{ $t('issue_comments.checkbox_reopen') }}
                </label>
            </span>
        </form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import IssueComment from '@/components/issues/IssueComment';
import { localizeNetworkErrorCode } from '@/api/util/network-errors';
import { issues } from '@/api';
import { ISSUES } from '@/store/issues';
import MarkdownEditor from '@/components/general/markdown-editor';
import { attachments } from '../../api';
import { DebugToggleComputed } from '@/devbar/decorators';
import UploadButton from '@/components/general/UploadButton';

@Component({
    components: { UploadButton, IssueComment, MarkdownEditor },
})
export default class IssueComments extends Vue {
    @Prop({ type: Object })
    issue;

    get comments() {
        return this.issue.progress_comments;
    }

    get mdeConfigs() {
        return {
            toolbar: [
                'bold',
                'italic',
                'strikethrough',
                '|',
                'heading',
                'heading-smaller',
                'heading-bigger',
                '|',
                'quote',
                'code',
                '|',
                'unordered-list',
                'ordered-list',
                '|',
                'link',
                '|',
                'preview',
                '|',
                'guide',
            ],
            shortcuts: {
                // disable side by side and fullscreen shortcuts since they break the Vue Modal
                toggleSideBySide: '',
                toggleFullScreen: '',
            },
            dragDrop: true,
            placeholder: this.$t('issue_comments.new_comment_placeholder'),
            status: false,
            spellChecker: false,
        };
    }

    postComment() {
        if (!this.canPostComment) return;
        this.commentError = false;
        this.commentBusy = true;
        issues
            .addComment(this.issue, this.commentField)
            .then(
                () => {
                    // TODO: Succes toaster
                    this.commentField = '';
                    this.commentReopen = false;
                    // TODO: Put this logic in issues vuex store
                    this.$store.dispatch(ISSUES.GET, {
                        project_id: this.issue.project_id,
                        iid: this.issue.iid,
                        refetch: true,
                    });
                },
                e => {
                    this.commentError = e.errorCode;
                },
            )
            .finally(() => {
                this.commentBusy = false;
            });
    }

    updateComment(comment, msg) {
        // Append the user name to the comment
        let newMsg = '--' + comment.commenter.name + '--' + '\n' + msg;
        this.commentError = false;
        issues
            .updateComment(this.issue, comment, newMsg)
            .then(
                () => {
                    // TODO: Succes toaster
                    // TODO: Put this logic in issues vuex store
                    this.$store.dispatch(ISSUES.GET, {
                        project_id: this.issue.project_id,
                        iid: this.issue.iid,
                        refetch: true,
                    });
                },
                e => {
                    this.commentError = e.errorCode;
                },
            )
            .finally(() => {
                this.$refs.commentEntry.forEach(c => {
                    if (c.isEditing) {
                        c.isEditing = false;
                        return;
                    }
                });
            });
    }

    deleteComment(id) {
        this.commentError = false;
        issues
            .deleteComment(this.issue, id)
            .then(
                () => {
                    // TODO: Succes toaster
                    // TODO: Put this logic in issues vuex store
                    this.$store.dispatch(ISSUES.GET, {
                        project_id: this.issue.project_id,
                        iid: this.issue.iid,
                        refetch: true,
                    });
                },
                e => {
                    this.commentError = e.errorCode;
                },
            )
            .finally(() => {
                this.$refs.commentEntry.forEach(c => {
                    if (c.isEditing) {
                        c.isEditing = false;
                        return;
                    }
                });
            });
    }

    commentField = '';
    commentReopen = true;
    commentBusy = false;
    commentError = '';

    get localizedCommentError() {
        return localizeNetworkErrorCode(this.commentError);
    }

    get canPostComment() {
        return !this.commentBusy && this.commentField.length;
    }

    async reply(comment) {
        this.commentField =
            comment.message
                .split('\n')
                .map(s => '> ' + s)
                .join('\n') + '\n\n';
        // Wait for a tick so the change to commentField propagates to the editor and it has updated.
        await this.$nextTick();
        // When clicking on reply, focus the markdown editor so the user can start typing their reply.
        if (this.$refs.newCommentEditor) {
            this.$refs.newCommentEditor.$el.scrollIntoView(true);
            // Find the internal CodeMirror instance and use its api to set focus to the last line
            const cmInstance = this.$refs.newCommentEditor.simplemde.codemirror;
            cmInstance.setCursor(cmInstance.lineCount(), 0);
            cmInstance.focus();
        }
    }

    onAttachmentDropped(e) {
        const files = Array.from(e.dataTransfer.files);
        this.addAttachmentsToComment(files);
    }

    async addAttachmentsToComment(files) {
        if (!files.length) return;
        const uploadIdentifier = '[Upload...]()';
        this.$refs.newCommentEditor.insertText(uploadIdentifier);
        try {
            const response = await attachments.addCommentFiles(
                this.issue.project_id,
                this.issue.iid,
                files,
            );
            let index = this.commentField.indexOf(uploadIdentifier);
            if (index > -1) {
                this.commentField = this.commentField.replace(
                    uploadIdentifier,
                    response.markdown.trim(),
                );
            } else {
                this.commentField = this.commentField + response.markdown.trim();
            }
        } catch (error) {
            this.commentField = this.commentField.replace(uploadIdentifier, '[error]');
        }
    }

    @DebugToggleComputed({ label: 'can reopen(true)', factory: () => true })
    @DebugToggleComputed({ label: 'can reopen(false)', factory: () => false })
    get canReopenIssue() {
        return this.issue.state === 'closed';
    }

    onManualUpload(file) {
        this.addAttachmentsToComment([file]);
    }
}
</script>
