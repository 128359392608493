<template>
    <div>
        <div class="row">
            <div class="col-12">
                <project-details :project-id="projectId" />
            </div>
        </div>
        <div v-if="projectExists" class="row">
            <div class="col-12 col-lg-6">
                <project-invoices :project-id="projectId" />
                <issue-hotlist :project-id="projectId" />
            </div>
            <div class="col-12 col-lg-6">
                <project-users :project-id="projectId" />
                <project-labels :project-id="projectId" />
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import IssueHotlist from '@/components/blocks/IssueHotlist';
import ProjectLabels from '@/components/blocks/ProjectLabels';
import ProjectDetails from '@/components/blocks/ProjectDetails';
import LabelCreatorModal from '@/components/modals/LabelCreatorModal';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import ProjectUsers from '@/components/blocks/ProjectUsers';
import ProjectInvoices from '@/components/blocks/ProjectInvoices';

@Component({
    components: {
        ProjectUsers,
        ProjectInvoices,
        LabelCreatorModal,
        ProjectDetails,
        ProjectLabels,
        IssueHotlist,
    },
    mixins: [ProjectIdMixin, ProjectsStoremap],
})
export default class ProjectSettingsPage extends Vue {
    get projectId() {
        return this.project_id;
    }

    get projectExists() {
        return this.projects.some(project => project.id === this.projectId);
    }
}
</script>
