import { PROJECTS } from '@/store/projects';

export const MonoProjectMixin = {
    computed: {
        hasMonoProject() {
            return this.$store.getters[PROJECTS.IS_MONO];
        },
        monoProjectId() {
            return this.$store.getters[PROJECTS.GET_MONO_PROJECT_ID];
        },
    },
};
