<template>
    <vue-multiselect
        :options="labels"
        label="name"
        :multiple="multiple"
        track-by="id"
        v-model="label"
        :taggable="canCreate"
        @tag="createTag"
        :select-label="$t('label_dropdown.select_label')"
        :selected-label="$t('label_dropdown.selected_label')"
        :deselect-label="$t('label_dropdown.deselect_label')"
        :placeholder="
            multiple ? $t('label_dropdown.placeholder_multiple') : $t('label_dropdown.placeholder')
        "
        :disabled="disabled"
    >
        <template slot="tag" slot-scope="{ option, search, remove }">
            <project-label-badge class="multiselect__tag" :color="option.color">
                <span v-if="option.id !== -1">{{ getOptionLabel(option) }}</span>
                <span v-else>{{ $t('common.labels.all') }}</span>
                <i
                    aria-hidden="true"
                    tabindex="1"
                    @keydown.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon"
                ></i>
            </project-label-badge>
        </template>
        <template slot="noResult">
            <span v-if="multiple">{{ $t('label_dropdown.no_result') }}</span>
            <span v-else>{{ $t('general.not_found') }}</span>
        </template>
        <template slot="noOptions">
            {{ $t('label_dropdown.no_result') }}
        </template>
        <template slot="maxElements">
            {{ $t('label_dropdown.max_elements') }}
        </template>
        <template slot="option" slot-scope="{ option }">
            <span v-if="option.isTag">
                {{ $t('label_dropdown.create_new', { label: option.label }) }}
            </span>
            <span v-else-if="option.id !== -1">{{ option.name }} ({{ option.openIssues }})</span>
            <span v-else>{{ $t('common.labels.all') }}</span>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            <span v-if="option.id > 0">{{ option.name }} ({{ option.openIssues }})</span>
            <span v-else>{{ $t('common.labels.all') }}</span>
        </template>
    </vue-multiselect>
</template>
<script>
import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import ProjectLabelBadge from '@/components/general/ProjectLabelBadge';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import { PROJECTS } from '@/store/projects';
import { ProjectLabel } from '@/api/modules/projects';

const MODEL_EVENT = 'input';

@Component({
    components: {
        ProjectLabelBadge,
        VueMultiselect,
    },
    mixins: [ProjectsStoremap],
})
export default class LabelDropdown extends Vue {
    @Model(MODEL_EVENT)
    _value;

    @Prop({ type: Number })
    project;
    @Prop({ type: Boolean, default: true })
    selectAll;
    @Prop({ type: Boolean, default: false })
    multiple;
    @Prop({ type: Boolean, default: false })
    disabled;
    @Prop({ type: Boolean, default: false })
    allowCreation;

    /**
     * @returns {ProjectLabel}
     */
    get allLabelsOption() {
        const all = new ProjectLabel(this.projectObject);
        all.id = -1;
        all.name = 'All labels';
        return all;
    }

    /**
     * @returns {ProjectLabel | ProjectLabels[]}
     */
    get label() {
        return this._value || (this.multiple ? [this.allLabelsOption] : this.allLabelsOption);
    }

    set label(value) {
        const allLabels = this.multiple ? [this.allLabelsOption] : this.allLabelsOption;
        this.$emit(MODEL_EVENT, value !== null ? value : this.selectAll ? allLabels : null);
    }

    /**
     * @returns {Project}
     */
    get projectObject() {
        return this.projects.find(p => p.id === this.project);
    }

    /**
     * @returns {ProjectLabel[]}
     */
    get labels() {
        if (this.project === undefined) {
            if (this.selectAll) return [this.allLabelsOption];
            return [];
        } else {
            const project = this.projectObject;
            const result = project ? project.labels.slice() : [];
            if (this.selectAll) {
                result.unshift(this.allLabelsOption);
            }
            return result;
        }
    }

    /**
     * @param {ProjectLabel} label
     * @returns {string}
     */
    getOptionLabel(label) {
        let result = label.name;
        if (label.issues) {
            result += ` (${label.openIssues})`;
        }
        return result;
    }

    get canCreate() {
        return !!(this.allowCreation && this.projectObject);
    }

    async createTag(query) {
        console.assert(this.canCreate, 'creating tag while creation is not possible');
        const tempLabel = new ProjectLabel(this.projectObject);
        tempLabel.name = query;
        tempLabel.color = '#6287BB';
        if (this.multiple) {
            this.label = [...this.label, tempLabel];
        } else {
            this.label = tempLabel;
        }
        const newLabel = await this.$store.dispatch(PROJECTS.ADD_LABEL, {
            project: this.projectObject,
            label: tempLabel,
        });
        if (this.multiple) {
            // Replace the placeholder inplace with the new label
            // Copy the existing array and replace at the correct index
            const labels = this.label.slice();
            const index = labels.indexOf(tempLabel);
            console.assert(index >= 0, 'the placeholder tag has disappeared!');
            labels.splice(index, 1, newLabel);
            this.label = labels;
        } else {
            this.label = newLabel;
        }
    }
}
</script>
