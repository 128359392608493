<template>
    <modal-wrapper
        modalClass="modal"
        :modalName="name"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <form class="form" @reset.prevent="close" @submit.prevent="save">
            <div class="modal__header">
                <h2>{{ $t('label_modal.create_label') }}</h2>
            </div>
            <div class="modal__content">
                <p class="alert--error" v-if="errorCode">
                    {{ localizedErrorMessage }}
                </p>
                <div class="form__fields">
                    <div class="form__field">
                        <label class="form__label">{{ $t('label_modal.project') }}</label>
                        <span class="capitalize">{{ project.name }}</span>
                    </div>
                    <div class="form__field">
                        <label class="form__label">{{ $t('label_modal.name') }}*</label>
                        <input type="text" v-model="label.name" required />
                    </div>
                    <div class="form__field">
                        <label class="form__label">{{ $t('label_modal.color') }}*</label>
                        <swatches v-model="label.color" colors="text-advanced" />
                    </div>
                </div>
            </div>
            <div class="modal__footer">
                <button class="button--alt pull-left" type="reset" :disabled="busy">
                    {{ $t('general.cancel') }}
                </button>
                <button
                    class="button--cta pull-right"
                    type="submit"
                    :class="{ loading: busy }"
                    :disabled="busy"
                >
                    {{ $t('general.save') }}
                </button>
            </div>
        </form>
    </modal-wrapper>
</template>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ModalWrapper from '@/components/general/ModalWrapper';
import vSelect from 'vue-select';
import { PROJECTS } from '@/store/projects';
import { LOCALE } from '@/store/locale';
import { localizeNetworkErrorCode } from '@/api/util/network-errors';
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js';
import IssueStateDropdown from '@/components/general/IssueStateDropdown';
import markdownEditor from 'vue-simplemde/src/index';
import LabelDropdown from '@/components/general/LabelDropdown';
import Swatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.min.css';

export const MODE_NEW = 1;
export const MODE_EDIT = 2;
export const GENERATED_CONTENT_BARRIER =
    "Don't edit below this line!\n------------------------------";

function defaultLabel() {
    return {
        color: '#4A86E8',
        name: '',
    };
}

function defaultProject() {
    return {
        name: undefined,
        id: undefined,
    };
}

@Component({
    components: {
        Swatches,
        LabelDropdown,
        IssueStateDropdown,
        Datepicker,
        vSelect,
        ModalWrapper,
        markdownEditor,
    },
})
export default class LabelCreatorModal extends Vue {
    name = 'label-creator-modal';
    label = defaultLabel();
    project = defaultProject();
    busy = false;
    errorCode = null;

    get language() {
        return this.languages[this.$store.getters[LOCALE.GET]];
    }

    get projects() {
        return this.$store.getters[PROJECTS.GET_ALL];
    }

    get selectedProject() {
        return this.projects.find(project => project.id === this.project.id);
    }

    set selectedProject(project) {
        if (project) {
            this.project = {
                name: project.name,
                id: project.id,
            };
        } else {
            this.project = defaultProject();
        }
    }

    beforeOpen(event) {
        if (event.params) {
            this.project = this.projects.find(project => project.id === event.params);
        } else {
            console.log("LabelCreatorModal can't be opened without setting a project");
            this.close();
        }
    }

    beforeClose() {
        this.project = defaultProject();
        this.label = defaultLabel();
        this.errorCode = null;
    }

    save() {
        this.busy = true;
        this.makeSaveRequest()
            .then(label => {
                this.$emit('created', label);
                this.close();
            })
            .catch(error => {
                this.errorCode = error.errorCode;
                throw error;
            })
            .finally(() => {
                this.busy = false;
            });
    }

    makeSaveRequest() {
        return this.$store.dispatch(PROJECTS.ADD_LABEL, {
            project: this.project,
            label: this.label,
        });
    }

    get localizedErrorMessage() {
        return localizeNetworkErrorCode(this.errorCode);
    }

    close() {
        this.$modal.hide(this.name);
    }
}
</script>
