<template>
    <div class="block">
        <router-link :to="newLabelRoute" class="button--cta pull-right hidden-xs hidden-sm">
            <i class="mdi mdi-plus-circle-outline"></i>
            <span>{{ $t('general.add') }}</span>
        </router-link>

        <h2>{{ $t('project.labels.title') }}</h2>

        <router-link
            :to="newLabelRoute"
            class="button--cta  block__button--top visible-xs visible-sm"
        >
            <i class="mdi mdi-plus-circle-outline"></i>
            <span>{{ $t('general.add') }}</span>
        </router-link>

        <p>{{ $t('project.labels.explanation') }}</p>

        <ul class="block_list--tags">
            <li v-for="label in labels" :key="label.id">
                <router-link :to="filteredIssuesRoute(label)">
                    <project-label-badge :color="label.color" class="tag--hover">
                        {{ label.name }}

                        <span @click.prevent="deleteLabel(label)" class="tag__icon">
                            <span
                                v-tooltip.right="$t('general.remove')"
                                class="mdi mdi-trash-can"
                            ></span>
                        </span>

                        <router-link :to="editLabelRoute(label)" tag="span" class="tag__icon">
                            <span
                                v-tooltip.right="$t('general.edit')"
                                class="mdi mdi-pencil"
                            ></span>
                        </router-link>
                    </project-label-badge>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import ProjectLabelBadge from '@/components/general/ProjectLabelBadge';
import { PROJECTS } from '@/store/projects';
import Optional from 'optional-js';

@Component({
    components: { ProjectLabelBadge },
    mixins: [ProjectsStoremap],
})
export default class ProjectLabels extends Vue {
    @Prop()
    projectId;

    /**
     * @returns {Optional<Project>}
     */
    get project() {
        return Optional.ofNullable(this.projects.find(p => p.id === this.projectId));
    }

    /**
     * @returns {ProjectLabel[]}
     */
    get labels() {
        return this.project.map(/** @type {Project} */ project => project.labels).orElse([]);
    }

    editLabelRoute(label) {
        return {
            name: 'ProjectLabel',
            params: {
                project_id: this.projectId,
                labelId: label.id,
            },
        };
    }

    get newLabelRoute() {
        return {
            name: 'ProjectLabel',
            params: {
                project_id: this.projectId,
                labelId: 'new',
            },
        };
    }

    filteredIssuesRoute(label) {
        return {
            name: 'Issues',
            params: {
                project_id: this.projectId,
            },
            query: {
                label: label.id,
            },
        };
    }

    async deleteLabel(label) {
        if (!label) return;
        return this.$store.dispatch(PROJECTS.DELETE_LABEL, { label });
    }
}
</script>
