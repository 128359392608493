import { ReactiveRouteParamMixin } from '@/mixins/reactive-route-param';

export const ALL_PROJECTS = -1;

const ALL_PROJECTS_ROUTE_VALUE = 'all';

export const ProjectIdMixin = ReactiveRouteParamMixin({
    routeParam: 'project_id',
    propName: 'project_id',
    type: 'number',
    setter: function(id) {
        if (id === this.project_id) return;
        if (!id || id === ALL_PROJECTS) {
            this.$router.push({
                params: { project_id: ALL_PROJECTS_ROUTE_VALUE },
            });
        } else {
            this.$router.push({ params: { project_id: id } });
        }
    },
    deserialize: function(string) {
        if (!string || string === ALL_PROJECTS_ROUTE_VALUE) {
            return null;
        } else {
            const parsed = parseInt(string);
            return Number.isNaN(parsed) ? null : parsed;
        }
    },
});
