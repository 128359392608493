import decode from 'jwt-decode';
import { featurebot } from '../util/servers';
import { cleanBearerPrefix, saveServerToken } from '../util/token-storage';
import { ApiObject } from '@/api/util/api-object';

export class UserInfo extends ApiObject {
    /** @member {string} */ name;
    /** @member {string} */ email;

    constructor() {
        super();
        this.name = '';
        this.email = '';
    }

    parseApiData({ id, name, email }) {
        super.parseApiData({ id });
        this.name = name;
        this.email = email;
        return this;
    }

    getEditableClone() {
        return new UserInfo().parseApiData(this);
    }
}

export class UserSession {
    /** @member {EncodedJwtToken} */
    token;
    /** @member {UserInfo} */
    user;
    /** @member {Date} */
    expiresAt;

    /**
     * @param {EncodedJwtToken} token
     */
    constructor(token) {
        this.token = token;
        const data = decode(token);
        this.user = new UserInfo().parseApiData(data);
        this.expiresAt = new Date(data.exp * 1000);
    }

    /**
     * Gets the full name of the user
     * @returns {string}
     */
    get fullname() {
        return this.user.name;
    }

    /**
     * Indicates if the authenticated user instance is valid; requirements are:
     * - expiration moment is in the future
     * - issue moment before expiration moment
     * @returns {boolean}
     */
    get isValid() {
        return this.expiresAt > new Date();
    }
}

export const authApi = {
    sessions: {
        /**
         * Starts a new authenticated user session
         * @param {string} username - The username/email of the user that identifies them.
         * @param {string} password - The password to login with.
         * @returns {Promise<UserSession>}
         * @example
         * const session = await login('username', 'password');
         * localStorage.setItem('token', session.token);
         * console.log('Welcome back', session.fullname);
         */
        async login(username, password) {
            const payload = { email: username, password };
            const data = await featurebot.post(`auth/login/`, payload);
            // TODO: Write code to retrieve token from api response
            /** @type EncodedJwtToken */
            const token = cleanBearerPrefix(data.token);
            if (!token) throw new Error('No session token received after login');
            const session = new UserSession(token);
            saveServerToken(featurebot, token);
            return session;
        },
        /**
         * Performs a login through a single-sign-on token
         * @param {string} ssoToken
         * @returns {Promise<UserSession>}
         */
        async sso(ssoToken) {
            const payload = { sso: ssoToken };
            const response = await featurebot.post('auth/login/', payload);
            const token = cleanBearerPrefix(response.token);
            if (!token) throw new Error('No session token received after login');
            saveServerToken(featurebot, token);
            return new UserSession(token);
        },
        /**
         * Ends the active user session
         * The interceptors are expected to inject the session token of the session that will be ended.
         * @returns {Promise<void>}
         */
        async logout() {
            saveServerToken(featurebot, null);
            await featurebot.delete(`auth/logout/`);
        },
    },
    accounts: {
        changePassword(oldPassword, newPassword, newPasswordRepeat) {
            console.assert(newPassword !== oldPassword);
            console.assert(newPassword === newPasswordRepeat);
            const payload = {
                current_password: oldPassword,
                password: newPassword,
                password2: newPasswordRepeat,
            };
            return featurebot.put('password/change/', payload);
        },
        requestPasswordReset(email) {
            const payload = { email };
            return featurebot.post('password/forgotten/', payload);
        },
        completePasswordReset(email, token, password, passwordRepeat) {
            console.assert(password === passwordRepeat);
            const payload = {
                email,
                uniquehash: token,
                password: password,
                password2: passwordRepeat,
            };
            return featurebot.put('password/reset/', payload);
        },
    },
};
