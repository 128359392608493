<template>
    <div v-if="!component">
        No component
    </div>
    <div v-else>
        <template v-if="dataKeys.length">
            Data
            <object-tree :object="component" :keys="dataKeys" />
        </template>
        <template v-if="computedKeys.length">
            Computed
            <object-tree :object="component" :keys="computedKeys" />
        </template>
        <template v-if="propertyKeys.length">
            Props
            <object-tree :object="component" :keys="propertyKeys" />
        </template>
    </div>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ObjectTree from '@/devbar/tabs/ObjectTree';

@Component({
    components: { ObjectTree },
})
export default class StateTab extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, default: null })
    component;

    get dataKeys() {
        if (!this.component) return [];
        return Object.keys(this.component.$data).filter(key => {
            return !key.startsWith('devbar__computedToggle');
        });
    }

    get computedKeys() {
        if (!this.component || !this.component.$options.computed) return [];
        return Object.keys(this.component.$options.computed);
    }

    get propertyKeys() {
        if (!this.component || !this.component.$options.props) return [];
        return Object.keys(this.component.$options.props);
    }

    value(key) {
        const val = this.component[key];
        if (val === undefined) {
            return '[undefined]';
        } else {
            return `[${typeof val}] ${JSON.stringify(val, null, 1)}`;
        }
    }
}
</script>
