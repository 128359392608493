<template>
    <span :class="'tag' + cssClass" :style="cssStyle">
        <slot />
    </span>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ProjectLabelBadge extends Vue {
    @Prop()
    color;

    get rgb() {
        return hexToRgb(this.color);
    }

    get brightness() {
        return colorBrightness(this.rgb);
    }

    get cssClass() {
        const isDark = this.brightness < 184;
        return isDark ? '--dark' : '--light';
    }

    get cssStyle() {
        return `background:${this.color}`;
    }
}

function colorBrightness({ r, g, b }) {
    return r * 0.299 + g * 0.587 + b * 0.114;
}

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}
</script>
