import { featurebot, frontend } from './servers';

// This module manages api tokens for servers and backends

/**
 * @typedef {string} EncodedJwtToken
 */

/**
 * Removes a possible 'Bearer ' prefix before a jwt token, also trims whitespace
 * @param {string} rawToken
 * @returns {EncodedJwtToken}
 */
export function cleanBearerPrefix(rawToken) {
    const bearerPrefix = 'Bearer ';
    if (rawToken.startsWith(bearerPrefix)) {
        rawToken = rawToken.substring(bearerPrefix.length);
    }
    return rawToken.trim();
}

/**
 * Gets the key under which to store a token for a given server
 * @param {AxiosInstance} server
 * @returns {string}
 * @throws Error - Thrown if the server is not a known instance
 */
function getStorageKey(server) {
    switch (server) {
        case featurebot:
            return 'customer-portal.tokens.featurebot';
        case frontend:
            return 'customer-portal.tokens.frontend';
        default: {
            const error = new Error('unknown server');
            error.server = server;
            throw error;
        }
    }
}

/**
 * Gets the active token to be used for authentication against a given server
 * @param {AxiosInstance} server
 * @returns {?EncodedJwtToken}
 * @throws Error - Thrown if the server is not a known instance
 */
export function loadServerToken(server) {
    const key = getStorageKey(server);
    return localStorage.getItem(key);
}

/**
 * Sets the active token to be used for authentication against a given server
 * @param {AxiosInstance} server
 * @param {?EncodedJwtToken} token - The token to set for authentication, if null the saved token is removed.
 * @throws Error - Thrown if the server is not a known instance
 */
export function saveServerToken(server, token) {
    const key = getStorageKey(server);
    if (token) {
        localStorage.setItem(key, token);
    } else {
        localStorage.removeItem(key);
    }
}
