<template>
    <button @click.prevent="startSnipe" :class="{ sniping }" class="button">
        <i class="mdi mdi-target"></i>
    </button>
</template>
<style scoped lang="scss">
@import '../devbar';

.button {
    @extend .button--mdi;

    &.sniping {
        background: $color-cta;
        color: $color-default;
    }
}
</style>
<style>
body.devbar--sniping * {
    cursor: crosshair !important;
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getDevbarOptions } from '@/devbar/decorators';
import { componentIsExternal } from '@/devbar/component-info';

@Component
export default class SniperButton extends Vue {
    @Prop({ type: Boolean, default: false })
    filterExternal;

    sniping = false;

    startSnipe() {
        if (!this.sniping) {
            this.listenerMouseMove = e => this.onSnipeMouseMove(e);
            this.listenerMouseDown = e => this.onSnipeMouseDown(e);
            this.listenerClick = e => this.onSnipeClick(e);
            document.addEventListener('mousemove', this.listenerMouseMove, true);
            document.addEventListener('mousedown', this.listenerMouseDown, true);
            document.addEventListener('click', this.listenerClick, true);
            document.body.classList.add('devbar--sniping');
            this.sniping = true;
        }
    }

    endSnipe() {
        if (this.sniping) {
            document.removeEventListener('mousemove', this.listenerMouseMove, true);
            document.removeEventListener('mousedown', this.listenerMouseDown, true);
            document.removeEventListener('click', this.listenerClick, true);
            document.body.classList.remove('devbar--sniping');
            this.sniping = false;
        }
    }

    beforeDestroy() {
        this.endSnipe();
    }

    /**
     * @param {MouseEvent} e
     */
    onSnipeMouseMove(e) {
        e.stopPropagation();
        e.preventDefault();
        const clickedElem = this.getElementsAt(e);
        const component = this.findVue(clickedElem);
        this.$emit('request-hover', component);
    }

    /**
     * @param {MouseEvent} e
     */
    onSnipeMouseDown(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * @param {MouseEvent} e
     */
    onSnipeClick(e) {
        e.stopPropagation();
        e.preventDefault();
        this.endSnipe();
        const clickStack = this.getElementsAt(e);
        const component = this.findVue(clickStack);
        if (component) {
            console.log('[sniper-button] Sniped', component);
            this.$emit('request-focus', component);
        }
    }

    /**
     * Returns the list of elements that is under the cursor, ignoring "mouse-events:none"
     * @param {MouseEvent} e
     * @returns {Array<Element>}
     */
    getElementsAt(e) {
        const clickPos = { x: e.clientX, y: e.clientY };
        const clickedElems = document.elementsFromPoint(clickPos.x, clickPos.y);
        let foundDeeperElement;
        do {
            foundDeeperElement = false;
            for (let child of clickedElems[0].children) {
                const bounds = child.getBoundingClientRect();
                if (
                    clickPos.x >= bounds.x &&
                    clickPos.x < bounds.x + bounds.width &&
                    clickPos.y >= bounds.y &&
                    clickPos.y < bounds.y + bounds.height
                ) {
                    clickedElems.unshift(child);
                    foundDeeperElement = true;
                    break;
                }
            }
        } while (foundDeeperElement);
        return clickedElems;
    }

    /**
     * @param {Array<Element>} elements
     * @returns {Vue}
     */
    findVue(elements) {
        for (let element of elements) {
            // If the element has a vue component and it is not filtered out then select it
            let vue = element.__vue__;
            if (!vue) continue;
            // Dig deeper in case child nodes are sharing the same element when single-wrapping
            while (vue.$children.length === 1 && vue.$children[0].$el === vue.$el) {
                vue = vue.$children[0];
            }
            // Travel up to the first snipe-able vue component
            const root = vue.$root;
            while (!this.canSnipe(vue) && vue !== root) {
                vue = vue.$parent;
            }
            if (vue === root) {
                // If the user sniped the root app (from main.js) then pick the <App/> element
                // The root element has no $vnode
                return vue.$children[0];
            } else {
                return vue;
            }
        }
        // If no suitable element could be sniped return the root elem
        return this.$devbar.inspectRoot;
    }

    /**
     * Returns if a given component can be targeted by the snipe.
     * @param {?Vue} component
     * @returns {boolean}
     */
    canSnipe(component) {
        if (!component || !component.$vnode) {
            return false;
        }
        // Filter out external components
        if (this.filterExternal && componentIsExternal(component)) {
            return false;
        }
        // Filter out @DevbarNoSnipe marked components
        const data = getDevbarOptions(component);
        if (data && data.nosnipe) {
            return false;
        }
        // Component survived requirements
        return true;
    }
}
</script>
