import Vue from 'vue';

/**
 * @class
 * @template K, V
 */
export class FetchCache {
    // Storage
    /**
     * @private
     * @type {Map<K, V>}
     */
    items = new Map();
    /**
     * @private
     * @type {Map<K, Promise<V>>}
     */
    promises = new Map();
    /**
     * @private
     * @type {{ids: Array<K>}}
     */
    reactive = Vue.observable({ ids: [] });
    /**
     * @private
     * @returns {Array<K>}
     */
    get ids() {
        return this.reactive.ids;
    }
    /**
     * @private
     * @type {function(Array<K>):Promise<Array<V>>}
     */
    fetchFunc = null;
    /**
     * @private
     * @type {number}
     */
    defaultLifetime = 10 * 1000;

    /**
     * Creates a new FetchCache instance
     * @param {'single'|'bulk'} mode - The type of fetch implementation; fetching each item individually or supporting bulk fetches with multiple ids at a time.
     * @param {function(K):Promise<V>|function(Array<K>):Promise<Array<V>>} fetch - The operation that fetches the values for given item(s).
     * @param {?number} lifetime - The default lifetime given to entries once fetched. Defaults to 10 seconds.
     */
    constructor({ mode, fetch, lifetime }) {
        // Option: fetch & mode
        if (typeof mode !== 'string') throw new TypeError('mode option must be a string');
        if (typeof fetch !== 'function') throw new TypeError('fetch option must be a function');
        switch (mode) {
            case 'single': {
                // Convert single-fetch into bulk impl
                this.fetchFunc = ids => Promise.all(ids.map(id => fetch(id)));
                break;
            }
            case 'bulk':
                this.fetchFunc = fetch;
                break;
            default:
                throw new TypeError(`mode option has unsupported value: ${mode}`);
        }

        // Option: lifetime
        if (lifetime) {
            if (typeof lifetime !== 'number')
                throw new TypeError('lifetime option must be a number');
            this.defaultLifetime = lifetime;
        }
    }

    /**
     * Perform the fetch operation to get a value into the cache.
     * The actual fetch operation may be skipped if the value in the cache is still fresh.
     * If a request for an id has already started then the request is reused for this fetch request.
     * @param {Array<K>} ids - Array of ids to fetch the values for.
     * @param {?boolean} forceRefresh - Whether to fetch values for the ids even if a cached value is still fresh.
     * @param {?number} lifetime - The lifetime of the newly fetched items
     * @returns {Promise<Array<V>>} - An array of values for the given ids, order corrosponds to that of the ids array.
     */
    async fetch({ ids, forceRefresh = false, lifetime = this.defaultLifetime }) {
        if (!Array.isArray(ids)) throw new TypeError('ids must be an array');
        if (typeof lifetime !== 'number') throw new TypeError('lifetime must be a number');
        // Figure out what ids need to be fetched
        const now = new Date().getTime();
        const outdatedIds = forceRefresh
            ? ids
            : ids.filter(id => !this.ids.includes(id) || this.items.get(id).deadline <= now);
        // Perform async fetch
        if (outdatedIds.length) {
            const alreadyFetchingIds = [];
            const requireFetchingIds = [];
            outdatedIds.forEach(id => {
                if (this.promises.has(id)) {
                    alreadyFetchingIds.push(id);
                } else {
                    requireFetchingIds.push(id);
                }
            });

            // Create a promise to wait for the ongoing fetch(es) to complete.
            const alreadyFetchingPromise = Promise.all(
                alreadyFetchingIds.map(id => this.promises.get(id)),
            );
            const fetchPromise = this.fetchFunc(requireFetchingIds);
            // Create two promises so we can order the individual promises after the async/await code in this code block
            const fetchPrimaryPromise = fetchPromise.then(x => x);
            const fetchSecondaryPromise = fetchPromise.then(x => x);
            // Populate the 'promises' map. We use the seconary promise such that the logic attached to the primary promise
            // is guaranteed to have completed before promises chained from the secondary promise are marked as resolved.
            requireFetchingIds.forEach((id, index) => {
                // Create a promise that results into the specific value from the bulk promise
                const promise = fetchSecondaryPromise.then(values => {
                    this.promises.delete(id);
                    return values[index];
                });
                this.promises.set(id, promise);
            });

            // Wait for fetches to complete, and then add them to the cache. This logic must complete before the individual
            // item promises (created above) complete. We wrote this code using async/await so without splitting the promise
            // this logic would be chained after the promises created above.
            const items = await fetchPrimaryPromise;
            requireFetchingIds.forEach((id, index) => {
                const value = items[index];
                this.set({
                    id,
                    value,
                    lifetime,
                });
            });
            // Wait for the ongoing fetches to complete
            await alreadyFetchingPromise;
        }
        // All items are present in cache and fresh
        return ids.map(id => this.get(id));
    }

    /**
     * Performs a fetch() but for a single id, returning the fetched item directly instead of in an array.
     * @param options - Options passed to fetch(), specify the item's key in the `id` property.
     * @returns {Promise<V>}
     */
    async fetchSingle(options) {
        const items = await this.fetch({ ...options, ids: [options.id] });
        return items[0];
    }

    /**
     * Remove the entry for a specific item from the cache.
     * Ongoing promises to fetch the value of this item are not affected.
     * @param {K} id - The id to remove from the cache, along with the value.
     */
    delete(id) {
        const index = this.ids.indexOf(id);
        if (index >= 0) {
            this.ids.splice(index, 1);
            this.items.delete(id);
        }
    }

    /**
     * Removes all cached items for which the deadline has passed.
     * Removed items will no longer be returned by get() calls.
     */
    deleteOutdated() {
        const now = new Date().getTime();
        this.ids
            .filter(id => this.items.get(id).deadline <= now)
            .forEach(id => {
                this.delete(id);
            });
    }

    /**
     * Drops all items from the cache, pending fetches are NOT cancelled.
     * Pending fetch promises are cleared, so fetches will trigger new requests.
     */
    clear() {
        this.ids.splice(0, this.ids.length);
        this.items.clear();
        this.promises.clear();
    }

    /**
     * Gets a value by id from the cache.
     * Returns the value stored under the given id, or the fallback parameter if no cached value exists.
     * @param {K} id
     * @param {?V} fallback - The value returned if the item is not in the cache, defaults to null
     * @param {?boolean} allowOutdated - When false, only return the cached value if it is still fresh
     * @returns {V|null}
     */
    get(id, fallback = null, allowOutdated = true) {
        if (this.ids.includes(id)) {
            const entry = this.items.get(id);
            if (allowOutdated || entry.deadline >= new Date().getTime()) {
                return entry.value;
            }
        }
        return fallback;
    }

    /**
     * Sets a value in the cache directly.
     * @param {K} id - The id under which to store the value
     * @param {V} value - The value to store in the cache
     * @param {?number} lifetime - The lifetime the value is guaranteed to be valid for, in milliseconds. If not provided the default lifetime is assigned.
     */
    set({ id, value, lifetime = this.defaultLifetime }) {
        const existingIndex = this.ids.indexOf(id);
        if (existingIndex >= 0) {
            // This will cause the vue observable to trigger a reactive update
            this.ids.splice(existingIndex, 1, id);
        } else {
            this.ids.push(id);
        }
        this.items.set(id, {
            value: Vue.observable(value),
            deadline: new Date().getTime() + lifetime,
        });
    }

    /**
     * Indicates whether the given id has a value in the cache.
     * @param {K} id
     * @param {?boolean} allowOutdated - When false, ignore keys for which the value has expired freshness
     * @returns {boolean} - True if the id has a value in the cache, returns false even when value exists if allowOutdated is set to false
     */
    has(id, allowOutdated = true) {
        if (!this.ids.includes(id)) return false;
        return allowOutdated ? true : this.items.get(id).deadline >= new Date().getTime();
    }
}
