import { featurebot } from './util/servers';
import axios from 'axios';
import { GENERATED_CONTENT_BARRIER } from '@/constants';

const STANDARD_PER_PAGE = 20;

function removeDuplicates(arr) {
    let uniqueArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (uniqueArray.indexOf(arr[i]) === -1) {
            uniqueArray.push(arr[i]);
        }
    }
    uniqueArray.sort(function(a, b) {
        const nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
        if (nameA < nameB)
            //sort string ascending
            return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
    });
    return uniqueArray;
}

export const issues = {
    search({
        page = 0,
        per_page = STANDARD_PER_PAGE,
        query,
        state,
        scrumLabel,
        project_id,
        sortColumn,
        sortAscending = true,
        label = null,
        sprint,
        input,
    }) {
        console.assert(
            !state ||
                ['opened', 'closed', 'this-sprint', 'next-sprint', 'needs-input'].includes(state) ||
                typeof state === 'number',
            'invalid state argument %s',
            state,
        );
        const source = axios.CancelToken.source();
        const promise = featurebot
            .get(`issues/page/${page + 1}/`, {
                params: {
                    per_page,
                    query: query || '',
                    project: project_id,
                    state: state === 'needs-input' ? 'opened' : state,
                    scrum_label: scrumLabel ? scrumLabel : undefined,
                    column: sortColumn,
                    direction: sortAscending ? 'ASC' : 'DESC',
                    label: label && label >= 0 ? label : undefined,
                    needs_customer_input: input ? true : undefined,
                    current_milestone: sprint == 1 ? true : undefined,
                    next_milestone: sprint == 2 ? true : undefined,
                },
                cancelToken: source.token,
            })
            .then(data => {
                return {
                    issues: data.issues.map(issue => adjustReceivedIssue(issue)),
                    pagination: {
                        pages: data.amount_of_pages,
                    },
                    issues_count: data.counters,
                };
            });
        promise.cancelSource = source;
        return promise;
    },
    getPriorities(project_id) {
        console.assert(project_id);
        const source = axios.CancelToken.source();
        const promise = featurebot
            .get(`issues/priority_mode/${project_id}/`, {
                cancelToken: source.token,
            })
            .then(data => {
                return {
                    issues: data.issues,
                    pagination: {
                        pages: 1,
                    },
                };
            });
        promise.cancelSource = source;
        return promise;
    },
    setPriorities(issues) {
        const priorities = issues.map((issue, index) => ({
            id: issue.id,
            priority: index + 1,
        }));
        return featurebot.put('/issues/order/', { priorities });
    },
    create(issue) {
        const payload = new FormData();
        const payloadData = {
            project_id: issue.project_id,
            title: issue.title,
            description: issue.description,
            labels: removeDuplicates(issue.labels || []).map(l => l.id),
        };
        if (issue.labels) {
            payloadData.labels = removeDuplicates(issue.labels).map(l => l.id);
        }
        payload.append('data', JSON.stringify(payloadData));
        if (issue.files && issue.files.length) {
            issue.files.forEach((file, index) => {
                payload.append('file_' + index, file, index + '_' + file.name);
            });
        }
        return featurebot.post('issues/', payload).then(data => {
            const newIssue = payloadData;
            newIssue.id = data.id;
            newIssue.iid = data.iid;
            newIssue.priority = null;
            return newIssue;
        });
    },
    update(issue) {
        const projectId = issue.project_id;
        const issueIid = issue.iid;
        const payload = new FormData();
        const payloadData = {};
        if (issue.title) {
            payloadData.title = issue.title;
        }
        if (issue.description) {
            payloadData.description = glueBarrier(issue);
        }
        if (issue.state) {
            payloadData.state = issue.state;
        }
        if (issue.labels !== undefined) {
            payloadData.labels = removeDuplicates(issue.labels || []).map(l => l.id);
        }
        if (issue.subscribed !== undefined) {
            payloadData.subscribe = issue.subscribed;
        }
        payload.append('data', JSON.stringify(payloadData));
        return featurebot.put(`issues/${projectId}/${issueIid}/`, payload).then(() => {
            return { ...issue };
        });
    },
    single(issue) {
        const projectId = issue.project_id;
        const issueIid = issue.iid;
        return featurebot.get(`issues/${projectId}/${issueIid}/`).then(data => {
            const newIssue = data.issue;
            newIssue.project_id = issue.project_id;
            newIssue.project = issue.project;
            return adjustReceivedIssue(newIssue);
        });
    },
    addComment(issue, comment, reopen) {
        const projectId = issue.project_id;
        const issueIid = issue.iid;
        const payload = { comment, reopen };
        return featurebot.post(`issues/${projectId}/${issueIid}/comment/`, payload);
    },
    updateComment(issue, comment, body) {
        const projectId = issue.project_id;
        const issueIid = issue.iid;
        const commentId = comment.id;
        const payload = { body };
        return featurebot.put(`/issues/${projectId}/${issueIid}/comment/${commentId}/`, payload);
    },
    deleteComment(issue, id) {
        const projectId = issue.project_id;
        const issueIid = issue.iid;
        return featurebot.delete(`/issues/${projectId}/${issueIid}/comment/${id}/`);
    },
    async addAttachment(issue, name, content) {
        const payload = new FormData();
        payload.append(name, content, name);
        const response = await featurebot.post(
            `issues/${issue.project_id}/${issue.iid}/files/`,
            payload,
        );
        return response.added_file_ids[0];
    },
};

function adjustReceivedIssue(issue) {
    if (!issue.description) {
        issue.description = ' ';
    }
    const sections = issue.description.split(GENERATED_CONTENT_BARRIER);
    issue.description = sections[0];
    issue.barrierContent = sections.length > 1 ? sections[1] : '';
    issue.scrumlabels = issue.scrum_labels;
    if (!issue.subscribers) {
        issue.subscribers = [];
        if (issue.subscribed) {
            const decode = require('jwt-decode');
            const token = localStorage.getItem('customer-portal.tokens.featurebot');
            const user = decode(token);
            issue.subscribers.push(user.id);
        }
    }
    issue.subscribers = issue.subscribers || [];
    delete issue.scrum_labels;
    // The timestamps returned from the api are UTC, but for momentjs to parse them correctly they need a Z suffix to indicate timezone +0
    if (issue.add_date && !issue.add_date.endsWith('Z')) {
        issue.add_date = issue.add_date + 'Z';
    }
    return issue;
}

function glueBarrier(issue) {
    if (issue.barrierContent && issue.barrierContent.length) {
        return `${issue.description}\n${GENERATED_CONTENT_BARRIER}${issue.barrierContent}`;
    } else {
        return issue.description;
    }
}

export const attachments = {
    /**
     * Adds an attachment to an existing issue
     * @param {number} projectId - The  project the issue belongs to
     * @param {number} issueId - The  issue to add the attachment to
     * @param {string} name - The name of the file being uploaded
     * @param {string|Blob} content - The content of the file being uploaded
     * @param {?AxiosRequestConfig} extraOptions - Extra options for the request, such as cancel token and progress callback
     * @returns {Promise<number>} Promise that resolves to the id of the new attachment once the async server call completes
     */
    async add(projectId, issueId, name, content, extraOptions) {
        console.assert(!!projectId, 'project id is required');
        console.assert(!!issueId, 'issue id is required');
        const payload = new FormData();
        payload.append(name, content, name);
        /** @property {number[]} added_file_ids */
        const response = await featurebot.post(
            `issues/${projectId}/${issueId}/files/`,
            payload,
            extraOptions,
        );
        return response.added_file_ids[0];
    },
    /**
     * Downloads the contents of an attachment and provides a promise for the data
     * @param {number} projectId - The project the issue belongs to
     * @param {number} issueId - The issue the attachment belongs to
     * @param {number} attachmentId - The attachment to download
     * @param {?AxiosRequestConfig} extraOptions - Extra options for the request, such as cancel token and progress callback
     * @returns {Promise<Blob>} Promise that resolves to the binary data of the attachment
     */
    async download(projectId, issueId, attachmentId, extraOptions = null) {
        console.assert(!!projectId, 'project id is required');
        console.assert(!!issueId, 'issue id is required');
        console.assert(!!attachmentId, 'attachment id is required');
        const options = {
            ...extraOptions,
            responseType: 'blob',
        };
        /** @type {Blob} */
        return await featurebot.get(
            `issues/${projectId}/${issueId}/files/${attachmentId}/`,
            options,
        );
    },
    /**
     * @param {number} projectId
     * @param {string} hash
     * @param {string} filename
     * @returns {Promise<Blob>}
     */
    async downloadFromGitlab(projectId, hash, filename) {
        console.assert(!!projectId, 'project id is required');
        console.assert(!!hash, 'hash is required');
        console.assert(!!filename, 'filename is required');
        const options = {
            responseType: 'blob',
        };
        /** @type {Blob} */
        return await featurebot.get(`issues/${projectId}/${hash}/${filename}`, options);
    },
    /**
     * Deletes an attachment from the system
     * @param {number} projectId - The project the issue belongs to
     * @param issueId - The issue the attachment belongs to
     * @param attachmentId - The attachment to delete
     * @param {?AxiosRequestConfig} extraOptions - Extra options for the request, such as cancel token and progress callback
     * @returns {Promise<void>} Promise that resolves when the attachment has been deleted
     */
    async remove(projectId, issueId, attachmentId, extraOptions = null) {
        console.assert(!!projectId, 'project id is required');
        console.assert(!!issueId, 'issue id is required');
        console.assert(!!attachmentId, 'attachment id is required');
        await featurebot.delete(
            `issues/${projectId}/${issueId}/files/${attachmentId}/`,
            extraOptions,
        );
    },
    getPreviewUrl(issue, file) {
        return `${featurebot.defaults.baseURL}issues/${issue.project_id}/${issue.iid}/files/${file.id}/preview/`;
    },
    /**
     * Uploads a set of files for an issue comment
     * @param {number} projectId
     * @param {number} issueId
     * @param {Array<File>} files
     * @returns {Promise<{fileIds:Array<number>,markdown:string}>}
     */
    async addCommentFiles(projectId, issueId, files) {
        const payload = new FormData();
        files.forEach(file => {
            payload.append(file.name, file);
        });
        const response = await featurebot.post(
            `/issues/${projectId}/${issueId}/comment/files/`,
            payload,
        );
        return {
            fileIds: response.added_file_ids,
            markdown: response.markdown,
        };
    },
};

export const issueSubscribers = {
    async subscribe(projectId, issueId, userId) {
        await featurebot.post(`/issues/${projectId}/${issueId}/subscribers/`, userId, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
    async unsubscribe(projectId, issueId, userId) {
        await featurebot.delete(`/issues/${projectId}/${issueId}/subscribers/${userId}/`);
    },
};

export const scrumlabels = {
    /**
     * @returns {Promise<{id:number,name:string,color:string,priority:number}>}
     */
    async get() {
        const { labels } = await featurebot.get('scrum_labels/');
        labels.sort((a, b) => a.priority - b.priority);
        return labels;
    },
};

export const milestones = {
    /**
     * @returns {Promise<{id:number,title:string,start:string,end:string}>}
     */
    async get() {
        // This formats the input to a two digit string (so 1 becomes 01)
        const twoDigits = num => num.toLocaleString(undefined, { minimumIntegerDigits: 2 });

        const { milestones } = await featurebot.get('milestones/');
        return milestones
            .filter(m => m.start !== null && m.end !== null)
            .map(m => {
                const startDate = new Date(m.start);
                const endDate = new Date(m.end);

                return {
                    id: m.id,
                    title: m.title,
                    start:
                        twoDigits(startDate.getDate()) + '-' + twoDigits(startDate.getMonth() + 1),
                    end: twoDigits(endDate.getDate()) + '-' + twoDigits(endDate.getMonth() + 1),
                };
            });
    },
};
