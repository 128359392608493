<template>
    <form @submit.prevent="submit" class="search">
        <input v-model="query" type="search" :placeholder="placeholder" class="search__input" />
        <input type="submit" :value="$t('general.search')" class="search__button button--cta" />
    </form>
</template>

<script>
import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';

const MODEL_EVENT = 'input';

@Component
export default class SearchBox extends Vue {
    @Prop({ type: String })
    placeholder;
    @Model(MODEL_EVENT)
    value;

    get query() {
        return this.value;
    }

    set query(value) {
        this.$emit(MODEL_EVENT, value);
    }

    submit() {
        this.$emit('search');
    }
}
</script>
