<template>
    <div class="block">
        <h1>{{ headerText }}</h1>
        <form class="form" @reset.prevent="close" @submit.prevent="save">
            <div class="form__fields row">
                <div class="form__field col-12">
                    <label class="form__label">{{ $t('feature_modal.name') }}</label>
                    <input type="text" v-model="issue.title" required :readonly="isReadonly" />
                </div>
                <div class="form__field col-12">
                    <label class="form__label">{{ $t('feature_modal.project') }}</label>
                    <projects-dropdown
                        v-model="issue.project_id"
                        :allow-all="false"
                        :disabled="isReadonly || !isNew || hasMonoProject"
                    />
                </div>
                <div class="form__field col-12">
                    <label class="form__label">{{ $t('feature_modal.description') }}</label>
                    <span class="form__label--secondary">{{ $t('general.optional') }}</span>
                    <markdown-editor
                        :configs="mdeConfigs"
                        v-model="issue.description"
                        :class="{ 'markdown-editor--readonly': isReadonly }"
                    />
                </div>
                <div class="form__field col-12">
                    <label class="form__label">{{ $t('feature_modal.labels') }}</label>
                    <span class="form__label--secondary">{{ $t('general.optional') }}</span>
                    <label-dropdown
                        :project="issue.project_id"
                        :selectAll="false"
                        :allow-creation="true"
                        :multiple="true"
                        v-model="issue.labels"
                        :disabled="isReadonly || !issue.project_id"
                    />
                </div>
            </div>
        </form>
        <div>
            <button
                :disabled="!canSubmit"
                @click.prevent="save"
                class="button--cta"
                :class="{ loading: saving }"
            >
                {{ $t('general.save') }}
            </button>
            <button :disabled="saving" @click.prevent="goBack" class="button--link">
                {{ $t('general.cancel') }}
            </button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js';
import * as lang from 'vuejs-datepicker/dist/locale';
import MarkdownEditor from 'vue-simplemde/src/index';
import { LOCALE } from '@/store/locale';
import ProjectsDropdown from '@/components/general/ProjectsDropdown';
import IssueStateDropdown from '@/components/general/IssueStateDropdown';
import LabelDropdown from '@/components/general/LabelDropdown';
import LabelCreatorModal from '@/components/modals/LabelCreatorModal';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import UploadQueue from '@/components/attachments/UploadQueue';
import { DebugToggleComputed } from '@/devbar/decorators';
import { MonoProjectMixin } from '@/mixins/mono-project';

@Component({
    components: {
        UploadQueue,
        Datepicker,
        MarkdownEditor,
        ProjectsDropdown,
        IssueStateDropdown,
        LabelDropdown,
        LabelCreatorModal,
    },
    mixins: [ProjectsStoremap, MonoProjectMixin],
})
export default class IssueEditor extends Vue {
    @Prop({ type: Boolean, default: false })
    editMode;
    @Prop({ type: Object, default: () => ({}) })
    issue;
    @Prop({ type: Boolean, default: false })
    saving;

    languages = lang;

    get language() {
        return this.languages[this.$store.getters[LOCALE.GET]];
    }

    get disabledDates() {
        const yesterday = new Date(Date.now() - 86400000);
        return {
            to: yesterday,
        };
    }

    get canCreateLabel() {
        return !this.saving && this.issue.project_id;
    }

    openCreateLabels() {
        if (!this.canCreateLabel) return;
        this.$modal.show('label-creator-modal', this.issue.project_id);
    }

    get canSubmit() {
        return !this.saving && this.issue.project_id && !this.isReadonly;
    }

    get isNew() {
        return this.issue && !this.issue.id;
    }

    @DebugToggleComputed({ label: 'Force readonly', factory: () => true })
    @DebugToggleComputed({ label: 'Force editable', factory: () => false })
    get isReadonly() {
        return this.issue.state === 'closed';
    }

    save() {
        this.$emit('submit');
    }

    get mdeConfigs() {
        return {
            toolbar: [
                'bold',
                'italic',
                'strikethrough',
                '|',
                'heading',
                'heading-smaller',
                'heading-bigger',
                '|',
                'quote',
                'code',
                '|',
                'unordered-list',
                'ordered-list',
                '|',
                'link',
                'image',
                '|',
                'preview',
                '|',
                'guide',
            ],
            shortcuts: {
                // disable side by side and fullscreen shortcuts since they break the Vue Modal
                toggleSideBySide: '',
                toggleFullScreen: '',
            },
            spellChecker: false,
        };
    }

    @Watch('issue.project_id')
    resetLabels() {
        this.issue.labels = [];
        const project = this.projects.find(p => p.id === this.issue.project_id);
        if (project) {
            Vue.set(this.issue, 'project', project);
        } else {
            Vue.set(this.issue, 'project', null);
        }
    }

    addLabel(label) {
        this.issue.labels.push(label);
    }

    get headerText() {
        return this.editMode
            ? this.$t('feature_modal.edit_feature')
            : this.$t('feature_modal.add_feature');
    }

    goBack() {
        this.$router.go(-1);
    }
}
</script>
