<template>
    <input v-model="usertext" placeholder="Search by name..." class="search-box" />
</template>
<style scoped lang="scss">
.search-box {
    line-height: 1em;
    font-size: 1em;
}
</style>
<script>
import Vue from 'vue';
import _ from 'lodash';
import { Component, Model, Watch } from 'vue-property-decorator';
import { componentName } from '@/devbar/component-info';
import { getDevbarOptions } from '@/devbar/decorators';

function Debounce(delay) {
    return (obj, key, descriptor) => {
        const method = descriptor.value;
        descriptor.value = _.debounce(method, delay);
        return descriptor;
    };
}

const MODEL_EVENT = 'input';

@Component
export default class SearchBox extends Vue {
    @Model(MODEL_EVENT)
    value;

    get model() {
        return this.value;
    }

    set model(value) {
        this.$emit(MODEL_EVENT, value);
    }

    usertext = '';

    @Watch('usertext')
    @Debounce(300)
    parseUsertext(usertext) {
        const filters = usertext
            .split(' ')
            .filter(part => part.length > 0)
            .map(part => {
                switch (part) {
                    case ':toggle':
                    case ':toggles':
                        return new HasTogglesFilter();
                    case ':action':
                    case ':actions':
                        return new HasActionsFilter();
                    default:
                        return new NameIncludesFilter(part);
                }
            });
        this.model = filters;
    }
}

class NameIncludesFilter {
    query = '';

    constructor(query) {
        this.query = query;
    }

    allowsComponent(component) {
        const name = componentName(component).toLowerCase();
        return name.includes(this.query.toLowerCase());
    }
}

class HasTogglesFilter {
    allowsComponent(component) {
        const options = getDevbarOptions(component);
        return options && options.toggles.length;
    }
}

class HasActionsFilter {
    allowsComponent(component) {
        const options = getDevbarOptions(component);
        return options && options.actions.length;
    }
}
</script>
