<template>
    <div class="component-tree">
        <div class="component-tree__controls">
            <sniper-button
                @request-hover="onHover"
                @request-focus="onSnipe"
                :filter-external="filterExternal"
                class="component-tree__controls__button"
            />
            <search-box class="component-tree__controls__search" v-model="filters" />
            <toggle-button
                v-model="filterExternal"
                icon="mdi-filter"
                class="component-tree__controls__button"
            />
            <toggle-button
                v-model="followRouter"
                icon="mdi-router-wireless"
                class="component-tree__controls__button"
            />
        </div>
        <div class="component-tree__tree">
            <component-node
                :component="inspect"
                @request-hover="onHover"
                @request-focus="onFocus"
                ref="componentTree"
                :filter="filter"
            />
        </div>
    </div>
</template>
<style scoped lang="scss">
@import '../devbar';

.component-tree {
    @include element(controls) {
        @include flexbox();

        @include element(search) {
            flex-grow: 1;
        }
        @include element(button) {
        }

        justify-content: center;
    }
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import SniperButton from '@/devbar/tree/SniperButton';
import ComponentNode from '@/devbar/tree/ComponentNode';
import ToggleButton from '@/devbar/tree/ToggleButton';
import SearchBox from '@/devbar/tree/SearchBox';
import { componentIsExternal } from '@/devbar/component-info';

const STORAGEKEY_DEVBAR_FILTEREXTERNAL = 'elnino.devbar.filterexternal';
const STORAGEKEY_DEVBAR_FOLLOWROUTER = 'elnino.devbar.followrouter';

@Component({
    components: {
        SearchBox,
        ToggleButton,
        SniperButton,
        ComponentNode,
    },
})
export default class ComponentTree extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, required: true })
    inspect;

    /**
     * @param {Vue} component
     */
    onHover(component) {
        this.$devbar.hover = component;
    }

    /**
     * @param {Vue} component
     */
    onFocus(component) {
        this.$devbar.focus = component;
        if (component.$el && component.$el.scrollIntoView) {
            component.$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        this.searchQuery = null;
    }

    /**
     * @param {Vue} component
     */
    onSnipe(component) {
        this.onFocus(component);
        this.updateComponentTreeForFocus(component);
    }

    /**
     * @param {Vue} value
     * @returns {Promise<void>}
     */
    async updateComponentTreeForFocus(value) {
        if (value) {
            /** @type {Vue} */
            let current = value;
            /** @type {Vue[]} */
            const path = [];
            while (current !== current.$root) {
                path.push(current);
                current = current.$parent;
            }
            path.reverse();
            let currentTreeBranch = this.$refs.componentTree;
            if (currentTreeBranch) {
                currentTreeBranch.expandTrace(path);
            }
        }
    }

    filterExternalInternal = localStorage.getItem(STORAGEKEY_DEVBAR_FILTEREXTERNAL) === 'true';

    get filterExternal() {
        return this.filterExternalInternal;
    }

    set filterExternal(value) {
        this.filterExternalInternal = value;
        localStorage.setItem(STORAGEKEY_DEVBAR_FILTEREXTERNAL, value);
    }

    followRouterInternal = localStorage.getItem(STORAGEKEY_DEVBAR_FOLLOWROUTER) === 'true';

    get followRouter() {
        return this.followRouterInternal;
    }

    set followRouter(value) {
        this.followRouterInternal = value;
        localStorage.setItem(STORAGEKEY_DEVBAR_FOLLOWROUTER, value);
    }

    @Watch('followRouter')
    @Watch('$route', { immediate: true, deep: true })
    focusOnRoute() {
        if (!this.followRouter) return;
        if (!this.$route.matched.length) return;
        const deepest = this.$route.matched[this.$route.matched.length - 1];
        if (!deepest) return;
        this.onSnipe(deepest.instances.default);
    }

    async mounted() {
        // After the first mount the followrouter logic has to run again because the page was not rendered the first time the watches fired the code
        await this.$nextTick();
        this.focusOnRoute();
    }

    filters = [];

    filter(component) {
        if (this.filterExternal && componentIsExternal(component)) {
            return false;
        }
        return this.filters.every(filter => filter.allowsComponent(component));
    }
}
</script>
