<template>
    <div class="wrapper" id="app" :class="{ 'wrapper--auth': isAuth() }">
        <the-header :class="{ auth: isAuth() }"></the-header>
        <portal-target name="notification-bar" />
        <main class="main">
            <div :class="containerClass">
                <breadcrumb-bar />
            </div>
            <div :class="containerClass">
                <router-view></router-view>
            </div>
        </main>
        <the-footer></the-footer>
        <image-modal />
        <error-modal />
    </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import TheFooter from '@/components/general/TheFooter';
import TheHeader from '@/components/general/TheHeader';
import BreadcrumbBar from '@/components/general/BreadcrumbBar';
import ImageModal from '@/components/modals/ImageModal';
import ErrorModal from '@/components/general/ErrorModal';
import { DebugAction } from '@/devbar/decorators';
import { CatchErrors } from '@/mixins/catch-errors';

@Component({
    components: {
        ErrorModal,
        ImageModal,
        BreadcrumbBar,
        TheFooter,
        TheHeader,
    },
})
export default class App extends Vue {
    name = 'app';

    isAuth() {
        const currentPage = window.location.href;
        return currentPage.indexOf('auth') > -1;
    }

    get containerClass() {
        return this.$route.meta.fluidContainer ? 'container-fluid' : 'container';
    }

    @DebugAction({ label: 'Show error modal' })
    @CatchErrors()
    throwError() {
        throw new Error('test error');
    }
}
</script>

<!-- Import SCSS boilerplate -->
<style src="./assets/css/styles.scss" lang="scss"></style>
