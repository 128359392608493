<template>
    <div v-if="target" class="highlighter" :style="rootStyle">
        <div class="highlighter__region" :style="regionStyle">
            <div
                v-for="(mark, index) in markings"
                :key="index"
                class="highlighter__region__area"
                :style="markingStyle(mark)"
            />
        </div>
        <div class="highlighter__badges" :class="badgesPosition">
            <badge
                class="badge--title"
                color="#35495e"
                background-color="#42b883"
                shadow
                icon="mdi-vuejs"
                :text="title"
            />
            <badge v-for="(badge, index) in badges" :key="index" v-bind="badge" />
        </div>
    </div>
</template>
<style scoped lang="scss">
@import '../devbar';

$highlighter-color: $color-theme;
$highlighter-alpha: 0.7;
$highlighter-outline-offset: 2px;
$highlighter-bubble-pointer-size: 8px;
$highlighter-children-color: #ff007f;

.highlighter {
    position: fixed;
    /* Force the overlay over everything, still has to be lower than the z-index of devbar!*/
    z-index: 9999;

    @include element(region) {
        pointer-events: none;
        &:after {
            outline: 1px dashed $color-cta;
            outline-offset: $highlighter-outline-offset;
            box-shadow: inset 0 0 0 1px rgba(darken($highlighter-color, 70%), $highlighter-alpha);
            content: ' ';
            z-index: 9998;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            /*background-color: rgba(lighten($highlighter-color, 20%), $highlighter-alpha);*/
            /*filter: invert(0%);*/
        }

        @include element(area) {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            outline: 1px solid $highlighter-children-color;
            outline-offset: $highlighter-outline-offset;
        }
    }

    @include element(badges) {
        position: absolute;
        top: -26px - $highlighter-bubble-pointer-size;
        left: -$highlighter-outline-offset - 1px;
        overflow: visible;
        white-space: nowrap;
        width: 100%;

        transition: top 0.2s, bottom 0.2s linear;
        z-index: 9999;

        &.inside {
            top: $highlighter-bubble-pointer-size;
        }

        &.inside-bottom {
            position: relative;
        }

        &.bottom {
            position: relative;
            top: $highlighter-bubble-pointer-size;
        }

        &.bottom,
        &.inside {
            .badge--title:after {
                top: 0;
                bottom: auto;
                border: $highlighter-bubble-pointer-size solid transparent;
                border-bottom-color: #42b883;
                border-top: 0;
                margin-top: -$highlighter-bubble-pointer-size;
            }
        }

        &.inside-center {
            position: fixed;
            top: $highlighter-bubble-pointer-size;
            left: $highlighter-bubble-pointer-size;
            .badge--title:after {
                border: none;
            }
        }
    }
}

.badge--title {
    position: relative;
    /* Add a pointer down to connect the bubble to the highlighter */
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 8px + $highlighter-bubble-pointer-size;
        width: 0;
        height: 0;
        border: $highlighter-bubble-pointer-size solid transparent;
        border-top-color: #42b883;
        border-bottom: 0;
        margin: 0;
        margin-left: -$highlighter-bubble-pointer-size;
        margin-bottom: -$highlighter-bubble-pointer-size;
    }
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { componentName } from '@/devbar/component-info';
import { DebugNoSnipe, getBadges, getDevbarOptions } from '@/devbar/decorators';
import Badge from '@/devbar/Badge';

@Component({
    components: { Badge },
})
@DebugNoSnipe
export default class Highlighter extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, default: null })
    target;

    bounds = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };
    markings = [];

    created() {
        this._scrollHandler = e => this.onScroll(e);
        window.addEventListener('scroll', this._scrollHandler);
        this.$devbar.$on('dom-mutation', this._scrollHandler);
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this._scrollHandler);
        this.$devbar.$off('dom-mutation', this._scrollHandler);
    }

    onScroll() {
        if (this.target && this.target.$el) {
            this.bounds = this.getElementBounds(this.target.$el);
            this.markings = Array.from(this.target.$children).map(child => {
                return this.getElementBounds(child.$el);
            });
        }
    }

    @Watch('target')
    onTargetChanged() {
        this.onScroll();
    }

    get rootStyle() {
        return {
            top: this.bounds.y + 'px',
            left: this.bounds.x + 'px',
            width: this.bounds.width + 'px',
            height: '0px',
        };
    }

    get regionStyle() {
        return {
            height: this.bounds.height + 'px',
        };
    }

    getElementBounds(el) {
        if (!el.getBoundingClientRect) {
            return {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                bottom: 0,
            };
        }
        const targetBounds = el.getBoundingClientRect();
        const devbarBounds = this.$devbar.$el.querySelector('.devbar').getBoundingClientRect();
        return {
            x: targetBounds.left,
            y: targetBounds.top,
            width: targetBounds.width,
            height: targetBounds.height,
            bottom: devbarBounds.top - targetBounds.bottom,
        };
    }

    markingStyle(mark) {
        return {
            top: `${mark.y - this.bounds.y}px`,
            left: `${mark.x - this.bounds.x}px`,
            width: `${mark.width}px`,
            height: `${mark.height}px`,
        };
    }

    get badgesPosition() {
        const estimateBadgesHeight = 50;
        const badgePointerSize = 8;
        if (this.bounds.y < estimateBadgesHeight) {
            if (this.bounds.y >= badgePointerSize) {
                return 'inside';
            } else if (this.bounds.bottom < estimateBadgesHeight) {
                if (this.bounds.bottom >= badgePointerSize) {
                    return 'inside-bottom';
                } else {
                    return 'inside-center';
                }
            } else {
                return 'bottom';
            }
        }
        return '';
    }

    /**
     * @returns {string | undefined}
     */
    get title() {
        return this.target && componentName(this.target);
    }

    get badges() {
        const devbar = getDevbarOptions(this.target);
        const factories = getBadges(devbar);
        return factories.map(f => f(this.target)).filter(badge => badge);
    }
}
</script>
