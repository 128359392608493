import Devbar from './Devbar';

export const devbar = {
    /** @member {Devbar} */
    instance: null,
    /**
     * @param {Vue} app
     */
    launch(app) {
        this.instance = new Devbar({
            parent: app,
            el: document.createElement('div'),
            app,
            devbar: this,
        });
        app.$el.insertAdjacentElement('afterend', this.instance.$el);
    },
    plugin: {
        /**
         * @param {VueConstructor<Vue>} vue
         */
        install(vue) {
            vue.mixin(devbarGlobalMixin);
            Object.defineProperty(vue.prototype, '$devbar', {
                get() {
                    return this._devbar ? this._devbar.instance : null;
                },
            });
        },
    },
};

const devbarGlobalMixin = {
    beforeCreate() {
        const options = this.$options;
        if (options.devbar) {
            this._devbar = options.devbar;
        } else if (this.$root && this.$root._devbar) {
            this._devbar = this.$root._devbar;
        }
    },
};

/**
 * Define the $devbar property on Vue instances since it gets added by install
 * @class Vue
 * @property {Devbar} $devbar
 */
