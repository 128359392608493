<template>
    <div class="block">
        <h2 class="capitalize">{{ $t('project.invoices.title') }}</h2>
        <div v-if="loading">
            <div class="spinner" />
        </div>
        <div v-else-if="noData">
            {{ $t('project.invoices.no_data') }}
        </div>
        <div v-else class="row">
            <div class="col-12">
                {{ $t('project.invoices.hours_worked', { hoursWorked }) }}
            </div>
            <div class="col-12">
                {{ $t('project.invoices.pending_invoice', invoiceData) }}
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import { DebugToggleComputed } from '@/devbar/decorators';
import { invoicesCaches } from '@/api/caches';

@Component({
    components: {},
    mixins: [ProjectsStoremap],
})
export default class ProjectInvoices extends Vue {
    @Prop()
    projectId;
    noData = false;

    get project() {
        return this.projects.find(project => project.id === this.projectId) || null;
    }

    get invoice() {
        return invoicesCaches.get(this.projectId);
    }

    get loading() {
        return !(invoicesCaches.has(this.projectId) || this.noData);
    }

    @Watch('projectId', { immediate: true })
    async onProjectIdChanged() {
        if (this.projectId) {
            this.noData = false;
            try {
                await invoicesCaches.fetch({ ids: [this.projectId] });
            } catch (err) {
                console.log('invoices caches', err);
                this.noData = true;
            }
        }
    }

    @DebugToggleComputed({ label: 'Invoice: 0', factory: () => 0 })
    @DebugToggleComputed({ label: 'Invoice: 1000', factory: () => 1000 })
    get invoiceCost() {
        return this.invoice ? this.invoice.costs : 0;
    }

    get invoiceData() {
        const numberFormat = { style: 'currency', currency: 'EUR' };
        return {
            invoiceCost: this.$n(this.invoiceCost, numberFormat),
        };
    }

    @DebugToggleComputed({ label: 'Hours worked: 0', factory: () => 0 })
    @DebugToggleComputed({ label: 'Hours worked: 3.5', factory: () => 3.5 })
    @DebugToggleComputed({ label: 'Hours worked: 5', factory: () => 5 })
    get hoursWorked() {
        return this.invoice ? this.invoice.hours : 0;
    }
}
</script>
