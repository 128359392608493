<template>
    <div class="issues">
        <div class="row">
            <div class="col-12">
                <div class="alert">
                    {{ $t('priority_edit.instructions') }}
                </div>
            </div>
            <div class="col-12 alert--auth" v-if="errorCode">
                <p>{{ localizedErrorMessage }}</p>
            </div>
        </div>
        <button @click="$router.go(-1)" class="button--theme">
            {{ $t('general.back') }}
        </button>
        <div class="issues__status_text" :class="{ saving }">
            <i class="mdi mdi-information-outline"></i>
            {{ localizedLastSave }}
        </div>
        <issues-order-table :issues="features" :loading="loading" :disabled="saving" />
        <button v-if="!loading" @click="$router.go(-1)" class="button--theme">
            {{ $t('general.back') }}
        </button>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import IssuesOrderTable from '@/components/issues/IssuesOrderTable';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';
import { issues } from '@/api';
import { ISSUES } from '@/store/issues';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import { ConfirmLeaveMixin } from '@/mixins/confirm-leave-guard';

const ERROR_CANCEL = 'wubwub:cancel:hihi';

@Component({
    components: {
        IssuesOrderTable,
    },
    mixins: [ProjectIdMixin, ConfirmLeaveMixin],
})
export default class PrioritiesPage extends Vue {
    features = [];
    loading = true;
    errorCode = ERROR_CODES.NONE;
    ongoingRequest = null;
    saving = false;
    lastSaveTimestamp = null;
    skipSave = false;
    changes = false;

    @Watch('project_id', { immediate: true })
    fetchIssues() {
        if (this.ongoingRequest) {
            this.ongoingRequest.cancel(ERROR_CANCEL);
        }
        this.loading = true;
        const request = issues.getPriorities(this.project_id);
        this.ongoingRequest = request.cancelSource;
        request
            .then(({ pagination, issues }) => {
                this.pageCount = pagination.pages;
                return this.$store.dispatch(ISSUES.STORE, issues).then(cachedIssues => {
                    this.features = cachedIssues;
                    this.skipSave = true;
                });
            })
            .catch(error => {
                if (error.message !== ERROR_CANCEL) {
                    this.errorCode = error.errorCode;
                }
            })
            .finally(() => {
                this.loading = false;
                this.ongoingRequest = null;
            });
    }

    get localizedErrorMessage() {
        return localizeNetworkErrorCode(this.errorCode);
    }

    save() {
        this.saving = true;
        issues
            .setPriorities(this.features)
            .then(() => {
                this.lastSaveTimestamp = new Date();
                this.changes = false;
            })
            .catch(error => {
                this.errorCode = error.errorCode;
            })
            .finally(() => {
                this.saving = false;
            });
    }

    @Watch('features')
    onPrioritiesChanged() {
        if (this.skipSave) {
            this.skipSave = false;
            return;
        }
        this.changes = true;
        clearTimeout(this.scheduledSave);
        this.scheduledSave = setTimeout(() => this.save(), 400);
    }

    get localizedLastSave() {
        if (this.changes) {
            return this.$t('priority_edit.unsaved_changes');
        } else if (this.lastSaveTimestamp) {
            return this.$t('priority_edit.last_save');
        } else {
            return this.$t('priority_edit.no_edits');
        }
    }

    get confirmLeaveQuestion() {
        if (this.changes || this.saving) {
            return this.$t('priority_edit.confirm_exit');
        } else {
            return undefined;
        }
    }
}
</script>
