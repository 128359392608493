<template>
    <router-link tag="div" :to="link" class="scrum_card" :class="{ 'is-overdue': isOverdue }">
        <i v-if="issue.has_files" class="scrum_card__files mdi mdi-file"></i>
        <span class="scrum_card__title">{{ issue.title }}</span>
        <span class="scrum_card__deadline" v-if="issue.due_date">{{ issue.due_date | date }}</span>
        <span class="scrum_card__estimate" v-if="estimate">
            <span class="scrum_card__estimate__label">Estimate:</span>
            {{ estimate }}
        </span>
        <span class="scrum_card__description" v-if="issue.description.length > 1">
            {{ issue.description }}
        </span>
    </router-link>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { DebugBadge, DebugToggleComputed } from '@/devbar/decorators';
import { localizeWorktime } from '@/languages';

@Component
@DebugBadge(vm => 'issue: ' + vm.issue.iid)
export default class ScrumCard extends Vue {
    @Prop({ type: Object })
    issue;

    get link() {
        return {
            name: 'Issue',
            params: {
                issueId: this.issue.iid,
                project_id: this.issue.project_id,
            },
        };
    }

    @DebugToggleComputed({ label: 'force overdue', factory: () => true })
    get isOverdue() {
        return this.issue.due_date && moment(this.issue.due_date).isBefore();
    }

    @DebugToggleComputed({
        label: 'fake estimate',
        factory: () => moment.duration(3, 'hours').humanize(),
    })
    get estimate() {
        if (!this.issue.estimated_time) return undefined;
        return localizeWorktime(this.issue.estimated_time || 0, this.$t('general.unknown'));
    }
}
</script>
