/**
 * Triggers a download to let the client download a file as some data
 * @param {Blob} blob - The data to let the client download
 * @param {string} filename - The name of the file to download
 */
export function presentDownload(blob, filename) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
