<template>
    <div class="scrum_board__responsive">
        <div class="scrum_board">
            <scrum-column
                v-for="col in labelRenders"
                :key="col.key"
                :title="col.title"
                :filter="col.filter"
                class="scrum_board__column"
            />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ScrumColumn from '@/components/scrum/ScrumColumn';
import { SCRUMLABELS } from '@/store/scrumlabels';
import { localizeEnum } from '@/languages';

@Component({
    components: { ScrumColumn },
})
export default class ScrumBoard extends Vue {
    @Prop({ type: Object })
    filter;

    created() {
        this.$store.dispatch(SCRUMLABELS.FETCH);
    }

    get labels() {
        return this.$store.getters[SCRUMLABELS.GET_ALL];
    }

    get labelRenders() {
        return [
            ...this.labels.map(l => ({
                key: l.id,
                title: localizeEnum('common.scrum-labels', l.name),
                filter: {
                    ...this.filter,
                    scrumLabel: l.id,
                    state: 'opened',
                },
            })),
            {
                key: 'closed-issues',
                title: this.$t('common.scrum-labels.done'),
                filter: {
                    ...this.filter,
                    state: 'closed',
                },
            },
        ];
    }
}
</script>
