<template>
    <div v-if="loading" class="loading">
        <h2>{{ $t('feature_modal.header_loading') }}</h2>
        <div class="spinner"></div>
    </div>
    <div v-else-if="loadingErrorCode" class="block">
        <h1>{{ $t('feature_modal.header_loaderror') }}</h1>
        <p class="alert--error">{{ localizedLoadingErrorCode }}</p>
    </div>
    <div v-else class="row">
        <div class="col-12 col-lg-8">
            <issue-editor :edit-mode="editMode" :issue="issue" :saving="saving" @submit="save" />
        </div>
        <div class="col-12 col-lg-4">
            <attachments-list :issue="issue" :edit-mode="editMode" ref="attachments" />
        </div>
        <portal to="notification-bar" v-if="savingErrorCode">
            <div class="notification--error">
                <i class="mdi mdi-close-circle"></i>
                {{ localizedSavingErrorCode }}
                <button class="button--link" @click.prevent="savingErrorCode = null">
                    {{ $t('general.close') }}
                </button>
            </div>
        </portal>
        <portal to="notification-bar" v-else-if="showSavedNotification">
            <div class="notification">
                <i class="mdi mdi-check-circle"></i>
                {{ $t('issue_editor.save_successful') }}
                <button class="button--link" @click.prevent="showSavedNotification = false">
                    {{ $t('general.close') }}
                </button>
            </div>
        </portal>
        <portal to="notification-bar" v-else-if="isIssueClosed">
            <div class="notification">
                <i class="mdi mdi-lock"></i>
                <span v-html="$t('issue_editor.issue_is_deactivated')"></span>
                <button class="button--cta" @click.prevent="changeState('opened')">
                    {{ $t('issue_editor.activate_issue') }}
                </button>
            </div>
        </portal>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { ISSUES } from '@/store/issues';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';
import { issues } from '@/api';
import IssueEditor from '@/components/issues/IssueEditor';
import AttachmentsList from '@/components/blocks/AttachmentsList';
import { DebugToggle, DebugToggleComputed } from '@/devbar/decorators';
import { PROJECTS } from '@/store/projects';
import { MonoProjectMixin } from '@/mixins/mono-project';
import { MonoProjectRedirectMixin } from '@/mixins/mono-project-redirect';

function skeletonIssue(project_id) {
    return {
        id: null,
        project_id,
        project: null,
        title: '',
        description: '',
        due_date: null,
        files: [],
        state: 'opened',
        labels: [],
    };
}

@Component({
    components: { AttachmentsList, IssueEditor },
    mixins: [ProjectIdMixin, MonoProjectMixin, MonoProjectRedirectMixin],
})
export default class IssueEditorPage extends Vue {
    get editMode() {
        return this.$route.name === 'Issue.Edit';
    }

    get issueId() {
        return parseInt(this.$route.params.issueId);
    }

    issue = null;
    loading = false;
    loadingErrorCode = null;
    saving = false;
    savingErrorCode = null;
    @DebugToggle({ label: 'Save popup' })
    showSavedNotification = false;
    attachmentError = false;

    get fetchIssueParam() {
        if (!this.editMode) return undefined;
        return {
            project_id: this.project_id,
            iid: this.issueId,
        };
    }

    @Watch('issueFetchParam', { immediate: true })
    @Watch('project_id', { immediate: true })
    fetchIssueData() {
        this.issue = skeletonIssue(this.project_id);
        const param = this.fetchIssueParam;
        if (!param) return;
        this.loading = true;
        this.$store
            .dispatch(ISSUES.GET, param)
            .then(
                issue => {
                    this.issue = issue;
                },
                error => {
                    this.loadingErrorCode = error;
                    throw error;
                },
            )
            .finally(() => {
                this.loading = false;
            });
    }

    get localizedLoadingErrorCode() {
        switch (this.loadingErrorCode) {
            case ERROR_CODES.FORBIDDEN:
                return this.$t('feature_modal.project_not_allowed');
            case ERROR_CODES.NOT_FOUND:
                return this.$t('feature_modal.issue_not_found');
            default:
                return localizeNetworkErrorCode(this.loadingErrorCode);
        }
    }

    @DebugToggleComputed({ label: 'force closed', factory: () => true })
    @DebugToggleComputed({ label: 'force opened', factory: () => false })
    get isIssueClosed() {
        return this.issue.state === 'closed';
    }

    save() {
        this.saving = true;
        this.savingErrorCode = ERROR_CODES.NONE;
        this.makeSaveRequest()
            .then(issue => {
                console.log(issue);
                this.$store.commit(ISSUES.STORE, [issue]);
                this.$store.dispatch(PROJECTS.FETCH, { forceRefresh: true });
                if (!this.editMode) {
                    this.$store.commit(ISSUES.CREATED_NEW, {
                        showCreationNotification: true,
                        showAttachmentError: this.attachmentError,
                    });
                } else {
                    this.issue = _.clone(
                        this.$store.getters[ISSUES.GET_FROM_CACHE](this.fetchIssueParam),
                    );
                }
                this.$router.replace({
                    name: 'Issue',
                    params: {
                        project_id: issue.project_id,
                        issueId: issue.iid,
                    },
                });
                this.showSavedNotification = true;
            })
            .catch(error => {
                this.savingErrorCode = error.errorCode;
                throw error;
            })
            .finally(() => {
                this.saving = false;
            });
    }

    async makeSaveRequest() {
        this.attachmentError = false;
        if (this.editMode) {
            return await issues.update(this.issue);
        } else {
            const issue = await issues.create(this.issue);
            if (this.$refs.attachments) {
                try {
                    await this.$refs.attachments.startQueue(issue);
                } catch (error) {
                    this.attachmentError = true;
                }
            }
            return issue;
        }
    }

    get localizedSavingErrorCode() {
        return localizeNetworkErrorCode(this.savingErrorCode);
    }

    async changeState(value) {
        const clone = {
            iid: this.issue.iid,
            project_id: this.issue.project_id,
            state: value,
        };
        this.saving = true;
        await issues.update(clone);
        this.saving = false;
        this.issue.state = value;
        this.$store.commit(ISSUES.STORE, [this.issue]);
    }

    @Watch('showSavedNotification', { immediate: true })
    onNotificationChanged(value) {
        if (value) {
            window.scrollTo(0, 0);
        }
    }
}
</script>
