<template>
    <div class="upload-queue">
        <upload-button
            class="upload-queue__button"
            @upload="onUploadCreated"
            :disabled="isClosed"
        />
        <div class="upload-queue__list">
            <upload-queue-item
                v-for="(upload, index) in uploads"
                :key="index"
                :upload="upload"
                :delayed="delayedUploads"
                @cancel="cancelUpload"
                @remove="removeUpload"
                @retry="retryUpload"
            />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import UploadButton from '@/components/general/UploadButton';
import { FakeUpload, Upload, UPLOAD_STATE } from '@/components/attachments/Upload';
import UploadQueueItem from './UploadQueueItem';
import { DebugAction } from '@/devbar/decorators';

@Component({
    components: { UploadQueueItem, UploadButton },
})
export default class UploadQueue extends Vue {
    @Prop({ type: Object, default: null })
    issue;
    @Prop({ type: Boolean, default: true })
    autoUpload;
    @Prop({ type: Boolean, default: false })
    delayedUploads;
    /** @member {Upload[]} */
    uploads = [];

    /**
     * Starts a new upload for a file and diplays the upload in the list
     * @param {File} file - The file to upload
     */
    onUploadCreated(file) {
        console.log('[upload-queue] starting upload', file.name);
        const upload = new Upload(this.issue, file);
        this.uploads.push(upload);
        if (this.autoUpload) {
            this.startUpload(upload);
        }
    }

    /**
     * Hooks an upload instance such that the component can manage the lifecycle
     * @param {Upload} upload
     */
    startUpload(upload) {
        upload.start().then(() => {
            this.removeUpload(upload);
        });
    }

    /**
     * Cancels the given upload
     * @param {Upload} upload
     */
    cancelUpload(upload) {
        upload.cancel();
    }

    /**
     * Removes an upload from the upload queue being displayed
     * @param {Upload} upload
     */
    removeUpload(upload) {
        console.log('[upload-queue] removing upload', upload);
        const index = this.uploads.indexOf(upload);
        console.assert(index >= 0, 'trying to remove unknown completed upload %s', upload);
        this.uploads.splice(index, 1);
    }

    /**
     * Starts a new upload for the same file, replaces the original upload with a new instance and places it at the same index of the uploads array.
     * @param {Upload} upload
     */
    retryUpload(upload) {
        console.log('[upload-queue] retry upload', upload);
        const index = this.uploads.indexOf(upload);
        console.assert(index >= 0, 'trying to retry upload that is not known');
        // Replace the instance in the list so it also performs an in-place update of the UI
        const retry = upload.createRetry();
        this.uploads.splice(index, 1, retry);
        this.startUpload(retry);
    }

    get isClosed() {
        return this.issue.state === 'closed';
    }

    @DebugAction({ label: 'Fake uploads' })
    debugFakeUpload() {
        this.uploads.push(new FakeUpload(this.issue, UPLOAD_STATE.WAITING));
        this.uploads.push(new FakeUpload(this.issue, UPLOAD_STATE.DONE));
        this.uploads.push(new FakeUpload(this.issue, UPLOAD_STATE.UPLOADING));
        this.uploads.push(new FakeUpload(this.issue, UPLOAD_STATE.CANCELLED));
        this.uploads.push(new FakeUpload(this.issue, UPLOAD_STATE.ERROR));
    }

    startQueue(issue) {
        console.assert(this.delayedUploads, 'starting queue for non-delayed uploads');
        const promises = this.uploads
            .filter(upload => upload.isWaiting)
            .map(upload => {
                upload.issue = issue;
                return upload.start();
            });
        return Promise.all(promises);
    }
}
</script>
