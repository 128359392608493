import { createDecorator } from 'vue-class-component';

const DEFAULT_EVENT_NAME = 'input';
const DEFAULT_PROP_NAME = 'modelProxyValue';

/**
 * @callback ValidatorCallback<T>
 * @param {T} value
 * @returns {boolean}
 */
/**
 * @typedef {Object} ModelProxySettings
 * @property {?any} type
 * @property {?boolean} required
 * @property {?(any|function)} default
 * @property {?ValidatorCallback} validator
 * @property {?string} eventName
 * @property {?string} propName
 */
/**
 * @param {ModelProxySettings} settings
 * @returns {VueDecorator}
 */
export function ModelProxy(settings = {}) {
    const eventName = settings.eventName || DEFAULT_EVENT_NAME;
    const propName = settings.propName || DEFAULT_PROP_NAME;
    return createDecorator((options, key) => {
        options.props[propName] = {
            type: settings.type,
            required: settings.required,
            default: settings.default,
            validator: settings.validator,
        };
        options.computed[key] = {
            get() {
                return this[propName];
            },
            set(value) {
                this.$emit(eventName, value);
            },
        };
        options.model = {
            prop: propName,
            event: eventName,
        };
    });
}
