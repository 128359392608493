import axios from 'axios';

export const featurebot = axios.create();

featurebot.defaults.withCredentials = true;
featurebot.defaults.headers.common['Content-Type'] = 'application/json';

export const frontend = axios.create();
frontend.defaults.baseURL = '/';
frontend.defaults.headers.common['Content-Type'] = 'application/json';
