<template>
    <ul class="object">
        <li v-for="{ key, value } in pairs" :key="key" class="entry" :class="entryClass(value)">
            <div class="entry-head" @click="toggle(key)">
                <span class="key">
                    <span v-if="showExpander(value)" class="expander">
                        <i
                            class="mdi"
                            :class="isExpanded(key) ? 'mdi-arrow-down' : 'mdi-arrow-right'"
                        />
                    </span>
                    {{ key }}:
                </span>
                <span class="value">{{ stringify(value) }}</span>
            </div>
            <object-tree
                v-if="showExpander(value) && isExpanded(key)"
                :object="value"
                class="entry-subtree"
            />
        </li>
    </ul>
</template>
<style scoped lang="scss">
@import '../devbar';

@mixin typedValueStyle($type) {
    &.type--#{$type} > .entry-head .value {
        @content;
    }
}

.object {
    .entry {
        .entry-head {
            overflow: hidden;
            display: flex;

            .key {
                .expander {
                }
                margin-right: 0.5em;
            }
            .value {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                flex-basis: 0;
                flex-grow: 1;

                color: $color-cta;
            }
        }
        .entry-subtree {
        }

        @include typedValueStyle(string) {
            &:before,
            &:after {
                content: '"';
                color: $color-grey;
            }
        }
        &.type--object,
        &.type--array,
        &.type--function {
            & > .entry-head .value {
                color: $color-grey;
            }
        }
        @include typedValueStyle(error) {
            color: $color-alert;
        }
    }
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

const INDEX_ERROR = {};

@Component
export default class ObjectTree extends Vue {
    @Prop({ type: [Object, Array] })
    object;
    @Prop({ type: Array })
    keys;
    @Prop({ type: Boolean })
    readonly;

    get pairs() {
        const keys = this.keys || Object.keys(this.object);
        return (
            keys
                .map(key => {
                    try {
                        return { key, value: this.object[key] };
                    } catch (error) {
                        return { key, value: INDEX_ERROR };
                    }
                })
                // Sort by key alphabetically
                .sort((a, b) => {
                    const nameA = a.key.toUpperCase();
                    const nameB = b.key.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
        );
    }

    entryClass(value) {
        return (
            'type--' +
            (() => {
                if (value === undefined) return 'null';
                if (value === null) return 'null';
                if (value === INDEX_ERROR) return 'error';
                if (Array.isArray(value)) return 'array';
                if (value._isVue) return 'component';
                return typeof value;
            })()
        );
    }

    showExpander(value) {
        return typeof value === 'object' && value !== null && value !== INDEX_ERROR;
    }

    stringify(value) {
        if (value === undefined) return 'undefined';
        if (value === null) return 'null';
        if (value === INDEX_ERROR) return 'error reading value';
        if (Array.isArray(value)) return `Array [${value.length}]`;
        if (typeof value === 'object') return 'Object';
        if (typeof value === 'function') return 'Function';
        return value;
    }

    expandedKeys = [];

    isExpanded(key) {
        return this.expandedKeys.includes(key);
    }

    toggle(key) {
        const index = this.expandedKeys.indexOf(key);
        if (index >= 0) {
            this.expandedKeys.splice(index, 1);
        } else {
            this.expandedKeys.push(key);
        }
    }
}
</script>
