import { PROJECTS } from '@/store/projects';

export const ProjectsStoremap = {
    created() {
        this.$store
            .dispatch(PROJECTS.FETCH)
            .then(() => {
                this.projects_loading = false;
                if (this.projects_onLoaded) {
                    this.projects_onLoaded();
                }
            })
            .catch(error => {
                this.projects_error = error.errorCode;
            });
    },
    data: () => ({
        projects_loading: true,
        projects_error: null,
    }),
    computed: {
        projects: {
            get() {
                return this.$store.getters[PROJECTS.GET_ALL];
            },
        },
    },
};
