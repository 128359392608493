<template>
    <span class="badge" :style="badgeStyle">
        <slot>
            <i v-if="icon" class="mdi" :class="icon" />
            {{ text }}
        </slot>
    </span>
</template>
<style scoped lang="scss">
@import 'devbar';
.badge {
    background: white;
    color: #35495e;
    padding: 0 $indent-1;
    border-radius: 0.4em;
    margin-left: $indent-xs;
    margin-right: $indent-xs;
    /* Add dark box-shadow in case the background is the same as some other element on the page */
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.58);
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Badge extends Vue {
    /** @member {String} */
    @Prop({ type: String })
    icon;
    /** @member {Object} */
    @Prop({ default: null })
    text;
    /** @member {String} */
    @Prop({ type: String, default: '#35495e' })
    color;
    /** @member {String} */
    @Prop({ type: String, default: 'white' })
    backgroundColor;

    get badgeStyle() {
        return {
            color: this.color,
            backgroundColor: this.backgroundColor,
        };
    }
}
</script>
