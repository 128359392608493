<template>
    <modal
        :class="modalClass"
        :name="modalName"
        @before-open="beforeOpen"
        @before-close="beforeClose"
        @opened="opened"
        @closed="closed"
    >
        <a class="modal__close" type="button" @click="closeModal"><i class="mdi mdi-close"></i></a>

        <slot></slot>
    </modal>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import vmodal from 'vue-js-modal';

Vue.use(vmodal);

@Component({
    props: {
        modalName: String,
        modalClass: String,
    },
})
export default class ModalWrapper extends Vue {
    name = 'modal-wrapper';

    beforeOpen(event) {
        document.getElementsByTagName('body')[0].className += ' modal__open';
        this.$emit('before-open', event);
    }

    beforeClose(event) {
        document.getElementsByTagName('body')[0].className = document
            .getElementsByTagName('body')[0]
            .className.replace('modal__open', '');
        this.$emit('before-close', event);
    }

    opened() {
        this.modalOpen = true;
        this.$emit('modalIsOpen', this.modalOpen);
    }

    closed() {
        this.modalOpen = false;
        this.$emit('modalWasClosed', this.modalOpen);
    }

    closeModal() {
        this.$modal.hide(this.modalName);
    }
}
</script>
