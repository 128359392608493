<template>
    <footer class="footer">
        <img
            class="footer__logo"
            src="/img/logo-white.svg"
            alt="El Niño"
            title="El Niño"
            height="32px"
        />
        <div class="footer__text">
            &copy; {{ new Date().getFullYear() }} {{ $t('footer.copyrights') }}
        </div>
    </footer>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class TheFooter extends Vue {
    name = 'the-footer';
}
</script>
