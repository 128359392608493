<template>
    <button @click.prevent="toggle" class="button button--toggle" :class="{ active }">
        <i class="mdi" :class="icon"></i>
    </button>
</template>
<style scoped lang="scss">
@import '../devbar';

.button {
    @extend .button--mdi;
}
</style>
<script>
import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';

const MODEL_EVENT = 'input';

@Component
export default class FilterExternalToggle extends Vue {
    @Model(MODEL_EVENT, { type: Boolean, default: false })
    model;

    get active() {
        return this.model;
    }

    set active(value) {
        this.$emit(MODEL_EVENT, value);
    }

    toggle() {
        this.active = !this.active;
    }

    @Prop({ type: String, default: null })
    icon;
}
</script>
