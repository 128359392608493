<template>
    <div class="comment">
        <img :src="comment.commenter.image" class="comment__avatar" />
        <h5 class="comment__author">{{ author }}</h5>
        <h5 class="comment__timestamp">{{ timestamp }}</h5>
        <div class="comment__reply">
            <a @click.prevent="reply">
                <i class="mdi mdi-reply"></i>
                {{ $t('comment.reply') }}
            </a>
            <div v-if="canEdit" class="pull-right">
                <i
                    v-if="isEditing"
                    class="mdi mdi-trash-can"
                    style="padding-left: 25px;"
                    @click="deleteComment()"
                ></i>
                <i
                    class="mdi mdi-pencil"
                    :style="isEditing ? '' : 'padding-left: 25px;'"
                    @click="editComment()"
                ></i>
            </div>
        </div>
        <edit-comment-form
            v-if="isEditing"
            :comment="comment"
            :issue="issue"
            @cancel="editComment()"
            @updateComment="updateComment"
            ref="editForm"
        />
        <div v-else class="comment__message">
            <markdown :source="comment.message" />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import Markdown from '@/components/general/Markdown';
import { AUTH } from '@/store/auth';
import EditCommentForm from '@/components/blocks/EditCommentForm';

@Component({
    components: {
        Markdown,
        EditCommentForm,
    },
})
export default class IssueComment extends Vue {
    @Prop({ type: Object })
    comment;
    @Prop({ type: Object })
    issue;
    isEditing = false;
    commentBusy = false;
    get author() {
        if (this.comment.commenter.known) {
            return this.comment.commenter.name;
        } else {
            return this.$t('issue_comments.you_said');
        }
    }

    get timestamp() {
        return moment(this.comment.date).format('LLL');
    }

    reply() {
        this.$emit('reply', this.comment);
    }

    get canEdit() {
        return this.$store.getters[AUTH.GET_USERNAME] === this.comment.commenter.name;
    }

    editComment() {
        this.isEditing = !this.isEditing;
    }

    updateComment(comment, msg) {
        this.$emit('updateComment', comment, msg);
    }

    deleteComment() {
        this.$refs.editForm.commentBusy = true;
        this.$emit('deleteComment', this.comment.id);
    }
}
</script>
