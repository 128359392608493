<template>
    <div class="block">
        <router-link :to="newIssueRoute" class="button--cta pull-right hidden-xs hidden-sm">
            <i class="mdi mdi-plus-circle-outline"></i>
            {{ $t('general.add') }}
        </router-link>

        <h2>{{ $t('project.issue_hotlist.title', { n: length }) }}</h2>

        <router-link
            :to="newIssueRoute"
            class="button--cta block__button--top visible-xs visible-sm"
        >
            <i class="mdi mdi-plus-circle-outline"></i>
            {{ $t('general.add') }}
        </router-link>

        <div v-if="loading" class="spinner"></div>

        <p v-else-if="!issues.length">
            {{ $t('project.issue_hotlist.empty') }}
        </p>

        <div v-else class="row">
            <ol class="block_list--ordered">
                <li v-for="issue in issues" :key="issue.iid" class="col-12">
                    <router-link :to="showIssueDetailsRoute(issue)">
                        {{ issue.title }}
                    </router-link>
                </li>
            </ol>
        </div>

        <div class="block__buttons row">
            <div class="col-12">
                <router-link :to="allIssuesRoute" class="button--theme">
                    <i class="mdi mdi-view-list"></i>
                    {{ $t('project.issue_hotlist.more') }}
                </router-link>
            </div>
            <div class="col-12 col-md-6">
                <router-link :to="scrumBoardRoute" class="button--theme">
                    <i class="mdi mdi-view-week"></i>
                    {{ $t('project.issue_hotlist.scrumboard') }}
                </router-link>
            </div>
            <div class="col-12 col-md-6">
                <router-link :to="prioritiesRoute" class="button--theme">
                    <i class="mdi mdi-sort-descending"></i>
                    {{ $t('project.issue_hotlist.priorities') }}
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { issues as issuesApi } from '@/api';

@Component({
    components: {},
})
export default class IssueHotlist extends Vue {
    @Prop()
    projectId;

    issues = [];
    length = 5;
    loading = false;

    @Watch('project_id', { immediate: true })
    @Watch('length')
    fetchIssues() {
        this.loading = true;
        issuesApi
            .search({
                page: 0,
                per_page: this.length,
                project_id: this.projectId,
            })
            .then(
                ({ issues }) => {
                    this.issues = issues;
                },
                error => {
                    this.errorCode = error.errorCode;
                },
            )
            .finally(() => {
                this.loading = false;
            });
    }

    get allIssuesRoute() {
        return {
            name: 'Issues',
            params: {
                project_id: this.projectId,
            },
        };
    }

    get newIssueRoute() {
        return {
            name: 'Issue.New',
            params: {
                project_id: this.projectId,
            },
        };
    }

    get prioritiesRoute() {
        return {
            name: 'ProjectPriorities',
            params: {
                project_id: this.projectId,
            },
        };
    }

    get scrumBoardRoute() {
        return {
            name: 'ScrumBoard',
            params: {
                project_id: this.projectId,
            },
        };
    }

    showIssueDetailsRoute(issue) {
        return {
            name: 'Issue',
            params: {
                project_id: this.projectId,
                issueId: issue.iid,
            },
        };
    }
}
</script>
