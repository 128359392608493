import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export function connectSentry(config) {
    if (config.errorTracking && config.errorTracking.enabled) {
        console.assert(typeof config.errorTracking.url === 'string', 'Illegal error tracking url');
        Sentry.init({
            dsn: config.errorTracking.url,
            integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        });
    }
}
