<template>
    <div class="block issue-details" :class="{ collapsed }">
        <div class="issue-details__status-bar">
            <i class="mdi mdi-circle-medium" :class="statusColor"></i>
            <span class="issue-details__status-bar__text">
                {{ $t('issue_details.status') + ': ' }}
                <span :class="statusColor">
                    {{ statusText }}
                </span>
            </span>
            <i
                class="pull-right mdi issue-details__status-bar__collapse-button"
                :class="toggleCollapseIcon"
                @click="toggleCollapse"
            ></i>
        </div>
        <div class="issue-details__description">
            <div class="issue-details__section issue-details__deadline">
                <h5>{{ $t('issue_details.section_deadline') }}</h5>
                <p>
                    {{ deadline }}
                    <span v-if="issue.due_date" class="issue-details__deadline__remaining">
                        {{ timeLeft }}
                    </span>
                </p>
            </div>
            <div v-if="closedAt" class="issue-details__description">
                <div class="issue-details__section issue-details__deadline">
                    <h5>{{ $t('issue_details.section_closed_at') }}</h5>
                    <p>
                        {{ closedAt }}
                    </p>
                </div>
            </div>
            <div class="issue-details__section issue-details__time-tracking" v-if="rawSpentTime">
                <h5>{{ $t('issue_details.section_spend') }}</h5>
                <div v-tooltip="timeSpentTooltip">
                    <progress-bar
                        v-if="rawEstimateTime"
                        :min="0"
                        :max="rawEstimateTime"
                        :value="rawSpentTime"
                    />
                    <span>
                        {{ $t('issue_details.spent_time') }}
                        {{ spentTime }}
                    </span>
                    <span class="pull-right">
                        {{ $t('issue_details.estimate') }}
                        {{ estimate }}
                    </span>
                </div>
            </div>
            <div class="issue-details__section" v-else>
                <h5>{{ $t('issue_details.section_estimate') }}</h5>
                <p>{{ estimate }}</p>
            </div>
            <show-more-list
                v-if="issue.labels.length"
                :items="issue.labels"
                :title="$t('issue_details.section_labels')"
                class="issue-details__section"
            >
                <template slot="item" slot-scope="props">
                    <project-label-badge :color="props.item.color">
                        {{ props.item.name }}
                    </project-label-badge>
                </template>
            </show-more-list>
            <show-more-list
                v-if="issue.files.length"
                :items="issue.files"
                :title="$t('issue_details.section_attachments')"
                class="issue-details__section"
            >
                <template slot="item" slot-scope="props">
                    <issue-attachment :issue="issue" :attachment="props.item" />
                </template>
            </show-more-list>
            <div class="issue-details__section" v-if="advancedSubscription">
                <h5>{{ $t('issue_details.section_subscriptions') }}</h5>
                <div class="checkbox" v-for="user in sortedProjectUsers" :key="user.id">
                    <input
                        type="checkbox"
                        :id="'issue-subscribed-' + user.id"
                        class="checkbox__cb"
                        v-model="dynamicIsSubscribed(user).state"
                    />
                    <label :for="'issue-subscribed-' + user.id" class="checkbox__label">
                        {{ user.name }}
                        <i v-if="user.id === session.user.id">({{ $t('issue_details.you') }})</i>
                    </label>
                </div>
                <p>
                    <a
                        href="#"
                        @click.prevent="advancedSubscription = false"
                        v-html="$t('issue_details.subscribed_settings_close')"
                    ></a>
                </p>
            </div>
            <div class="issue-details__section" v-else>
                <h5>{{ $t('issue_details.section_subscriptions') }}</h5>
                <div class="checkbox">
                    <input
                        type="checkbox"
                        id="issue-subscribed"
                        class="checkbox__cb"
                        v-model="subscribed"
                    />
                    <label
                        for="issue-subscribed"
                        class="checkbox__label"
                        v-tooltip.top="$t('issue_details.subscribed_tooltip')"
                    >
                        {{ $t('issue_details.subscribed_checkbox') }}
                    </label>
                </div>
                <p>
                    <a
                        href="#"
                        @click.prevent="advancedSubscription = true"
                        v-html="$t('issue_details.subscribed_settings')"
                    ></a>
                </p>
            </div>
            <div class="issue-details__section--toggle">
                <button
                    class="button--theme"
                    :class="{ loading: savingState }"
                    @click.prevent="toggle"
                >
                    <i class="mdi" :class="toggleIcon"></i>
                    {{ toggleText }}
                </button>
            </div>
        </div>
        <div class="issue-details__collapsed-menu-buttons">
            <div class="issue-details__section" v-tooltip="deadline" @click="toggleCollapse">
                <i class="mdi mdi-calendar-range"></i>
            </div>
            <div
                class="issue-details__section"
                v-tooltip="`${$t('issue_details.section_estimate')}: ${estimate}`"
                @click="toggleCollapse"
            >
                <i class="mdi mdi-clock-outline"></i>
            </div>
            <div
                class="issue-details__section"
                v-if="issue.files.length"
                v-tooltip="`${$t('issue_details.section_attachments')} (${issue.files.length})`"
                @click="toggleCollapse"
            >
                <i class="mdi mdi-paperclip"></i>
            </div>
            <div class="issue-details__section" v-tooltip="!savingState && toggleText">
                <div class="spinner--small" v-if="savingState" />
                <i class="mdi" :class="toggleIcon" @click.prevent="toggle" v-else></i>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import IssueAttachment from '@/components/general/IssueAttachment';
import ProjectLabelBadge from '@/components/general/ProjectLabelBadge';
import ShowMoreList from '@/components/general/ShowMoreList';
import { issues, issueSubscribers } from '@/api';
import { ISSUES } from '@/store/issues';
import { DebugToggleComputed } from '@/devbar/decorators';
import { localizeEnum, localizeWorktime } from '@/languages';
import ProgressBar from '@/components/general/ProgressBar';
import { AUTH } from '@/store/auth';
import { CatchErrors } from '@/mixins/catch-errors';
import { SCRUMLABELS } from '@/store/scrumlabels';

@Component({
    components: { ProgressBar, ShowMoreList, ProjectLabelBadge, IssueAttachment },
})
export default class IssueDetails extends Vue {
    @Prop({ type: Object, default: null })
    issue;

    @Prop({ type: String, default: '' })
    closedAt;

    advancedSubscription = false;
    collapsed = false;

    get deadline() {
        if (this.issue.due_date) {
            return moment(this.issue.due_date).format('LL');
        } else {
            return this.$t('issue_details.no_due_date');
        }
    }

    get timeLeft() {
        return moment(this.issue.due_date).fromNow();
    }

    get statusText() {
        if (this.issue.state === 'closed') {
            return localizeEnum('common.issue_state', this.issue.state);
        }
        var scrumlabel;
        if (this.issue.scrumlabels.length) {
            scrumlabel = this.$store.getters[SCRUMLABELS.GET_BY_ID](this.issue.scrumlabels[0]);
        }
        if (this.issue.scrumlabels.length > 1) {
            this.issue.scrumlabels.forEach(label => (scrumlabel = scrumlabel + ' - ' + label));
        }
        return scrumlabel
            ? localizeEnum('common.scrum-labels', scrumlabel.name)
            : this.$t('issue_details.no_scrum_labels');
    }

    get statusColor() {
        return this.issue.state === 'opened' ? 'is-open' : 'is-closed';
    }

    @DebugToggleComputed({ label: 'Estimate: none', factory: () => undefined })
    @DebugToggleComputed({ label: 'Estimate: 3m', factory: () => 3 * 60 })
    @DebugToggleComputed({ label: 'Estimate: 4h', factory: () => 4 * 60 * 60 })
    @DebugToggleComputed({ label: 'Estimate: 5d', factory: () => 5 * 60 * 60 * 8 })
    @DebugToggleComputed({ label: 'Estimate: 6w', factory: () => 6 * 60 * 60 * 8 * 5 })
    @DebugToggleComputed({ label: 'Estimate: 7mn', factory: () => 6 * 60 * 60 * 8 * 5 })
    get rawEstimateTime() {
        return this.issue.estimated_time;
    }

    get estimate() {
        return localizeWorktime(this.rawEstimateTime || 0, this.$t('general.unknown'));
    }

    @DebugToggleComputed({ label: 'spentTime: none', factory: () => undefined })
    @DebugToggleComputed({ label: 'spentTime: 3m', factory: () => 3 * 60 })
    @DebugToggleComputed({ label: 'spentTime: 4h', factory: () => 4 * 60 * 60 })
    @DebugToggleComputed({ label: 'spentTime: 5d', factory: () => 5 * 60 * 60 * 8 })
    @DebugToggleComputed({ label: 'spentTime: 6w', factory: () => 6 * 60 * 60 * 8 * 5 })
    @DebugToggleComputed({ label: 'spentTime: 7mn', factory: () => 6 * 60 * 60 * 8 * 5 })
    get rawSpentTime() {
        return this.issue.spent_time;
    }

    get spentTime() {
        return localizeWorktime(this.rawSpentTime);
    }

    get timeSpentTooltip() {
        const estimate = this.rawEstimateTime;
        const spent = this.rawSpentTime;
        if (Number.isInteger(estimate) && Number.isInteger(spent)) {
            if (estimate > spent) {
                return this.$t('issue_details.worktime_remaining', {
                    worktime: localizeWorktime(estimate - spent),
                });
            } else {
                return this.$t('issue_details.worktime_overtime', {
                    worktime: localizeWorktime(spent - estimate),
                });
            }
        } else {
            return null;
        }
    }

    allLabels = false;

    get expandLabelsText() {
        return this.createToggleText(this.allLabels);
    }

    get visibleLabels() {
        const result = this.issue.labels;
        return this.allLabels ? result : result.slice(0, 3);
    }

    toggleAllLabels() {
        this.allLabels = !this.allLabels;
    }

    allAttachments = false;

    get expandAttachmentsText() {
        return this.createToggleText(this.allAttachments);
    }

    get visibleAttachments() {
        const result = this.issue.files;
        return this.allAttachments ? result : result.slice(0, 3);
    }

    toggleAllAttachments() {
        this.allAttachments = !this.allAttachments;
    }

    createToggleText(showingAll) {
        return showingAll ? this.$t('issue_details.show_less') : this.$t('issue_details.show_all');
    }

    toggle() {
        if (this.issue.state === 'opened') {
            this.changeState('closed');
        } else {
            this.changeState('opened');
        }
    }

    savingState = false;

    changeState(state) {
        return this.patchIssue({ state });
    }

    async patchIssue(updatedProperties) {
        const clone = {
            ...updatedProperties,
            iid: this.issue.iid,
            project_id: this.issue.project_id,
        };
        this.savingState = true;
        try {
            await issues.update(clone);
            Object.keys(updatedProperties).forEach(key => {
                Vue.set(this.issue, key, updatedProperties[key]);
            });
            this.$store.commit(ISSUES.STORE, [this.issue]);
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.savingState = false;
        }
    }

    get toggleText() {
        switch (this.issue.state) {
            case 'opened':
                return this.$t('issue_details.button_close');
            case 'closed':
                return this.$t('issue_details.button_open');
            default:
                return undefined;
        }
    }

    get toggleIcon() {
        if (!this.savingState) {
            switch (this.issue.state) {
                case 'opened':
                    return 'mdi-lock';
                case 'closed':
                    return 'mdi-lock-open';
            }
        }
        return undefined;
    }

    get toggleCollapseIcon() {
        return this.collapsed ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right';
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
    }

    @DebugToggleComputed({ label: 'Assignee: None', factory: () => null })
    @DebugToggleComputed({
        label: 'Assignee: Bram Kamies',
        factory: () => 'Bram Kamies',
    })

    /**
     * @returns UserSession
     */
    get session() {
        return this.$store.getters[AUTH.GET_SESSION];
    }

    get subscribed() {
        return this.isUserSubscribed(this.session.user);
    }

    set subscribed(subscribed) {
        this.setUserSubscribed(this.session.user, subscribed);
    }

    isUserSubscribed(user) {
        return this.issue.subscribers.includes(user.id);
    }

    @CatchErrors()
    async setUserSubscribed(user, state) {
        this.savingState = true;
        try {
            const subscribers = this.issue.subscribers;
            if (state) {
                await issueSubscribers.subscribe(this.issue.project.id, this.issue.iid, user.id);
                if (!subscribers.includes(user.id)) {
                    subscribers.push(user.id);
                }
            } else {
                await issueSubscribers.unsubscribe(this.issue.project.id, this.issue.iid, user.id);
                const index = this.issue.subscribers.indexOf(user.id);
                if (index >= 0) {
                    subscribers.splice(index, 1);
                }
            }
        } catch (error) {
            const oldSubs = this.issue.subscribers.slice();
            this.issue.subscribers = [];
            await this.$nextTick();
            this.issue.subscribers = oldSubs;
            throw error;
        } finally {
            this.savingState = false;
        }
    }

    dynamicIsSubscribed(user) {
        // The returned object proxies acces to isUserSubscribed so it can be used in a v-model
        const self = this;
        return {
            get state() {
                return self.isUserSubscribed(user);
            },
            set state(value) {
                self.setUserSubscribed(user, value);
            },
        };
    }

    get sortedProjectUsers() {
        const sorted = this.issue.project.users.slice();
        sorted.sort((a, b) => {
            if (a.id === this.session.user.id) {
                return -1;
            } else if (b.id === this.session.user.id) {
                return 1;
            } else {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            }
        });
        return sorted;
    }
}
</script>
