<template>
    <vue-multiselect v-model="model" :options="states" :clearable="false" :searchable="false">
        <template slot="option" slot-scope="{ option }">
            {{ getStateLabel(option) }}
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            {{ getStateLabel(option) }}
        </template>
    </vue-multiselect>
</template>
<script>
import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

const ALL_STATES = -1;
const OPEN_ISSUES = 'opened';
const CLOSED_ISSUES = 'closed';
const MODEL_EVENT = 'input';

@Component({
    components: {
        VueMultiselect,
    },
})
export default class IssueStateDropdown extends Vue {
    @Model(MODEL_EVENT, { type: String })
    _value;

    get model() {
        return this._value || ALL_STATES;
    }

    set model(value) {
        const emitValue = value === ALL_STATES ? null : value;
        this.$emit(MODEL_EVENT, emitValue);
    }

    @Prop({ type: Boolean, default: false })
    allowAll;

    get states() {
        if (this.allowAll) {
            return [ALL_STATES, OPEN_ISSUES, CLOSED_ISSUES];
        } else {
            return [OPEN_ISSUES, CLOSED_ISSUES];
        }
    }

    getStateLabel(state) {
        if (!state) return undefined;
        switch (state) {
            case ALL_STATES:
                return this.$t('common.issue_state.all');
            case OPEN_ISSUES:
                return this.$t('common.issue_state.opened');
            case CLOSED_ISSUES:
                return this.$t('common.issue_state.closed');
        }
        return this.$t(state);
    }
}
</script>
