import { milestones } from '@/api';

const namespace = 'milestones/';

export const MILESTONES = Object.freeze({
    SET_FETCHING: namespace + 'set_fetching',
    SET_MILESTONES: namespace + 'set_labels',
    FETCH: namespace + 'fetch',
    GET_ALL: namespace + 'get_all',
    GET_BY_ID: namespace + 'get_by_id',
});

export default {
    state: () => {
        return {
            milestones: [],
            fetchPromise: null,
        };
    },
    mutations: {
        [MILESTONES.SET_FETCHING]: (state, promise) => {
            state.fetchPromise = promise;
        },
        [MILESTONES.SET_MILESTONES]: (state, milestones) => {
            state.milestones = milestones;
        },
    },
    actions: {
        [MILESTONES.FETCH]: ({ state, commit }) => {
            // If we are already fetching (or have fetched the milestones) then return the promise for that fetch
            const alreadyFetchedPromise = state.fetchPromise;
            if (alreadyFetchedPromise) {
                return alreadyFetchedPromise;
            }
            // No successful fetch yet, create a new promise that fetches the milestones
            const promise = milestones
                .get()
                .then(milestones => {
                    commit(MILESTONES.SET_MILESTONES, milestones);
                    return milestones;
                })
                .catch(error => {
                    // On error, clear the fetching promise from the store and propagate the error
                    console.warn('[store] [milestones] Fetch failed', error);
                    commit(MILESTONES.SET_FETCHING, null);
                    return Promise.reject(error);
                });
            commit(MILESTONES.SET_FETCHING, promise);
            return promise;
        },
    },
    getters: {
        [MILESTONES.GET_ALL]: state => state.milestones,
        [MILESTONES.GET_BY_ID]: state => id => state.milestones.find(m => m.id === id),
    },
};
