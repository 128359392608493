<template>
    <vue-multiselect
        v-model="value"
        :multiple="true"
        :showLabels="true"
        :preselect-first="false"
        :options="options"
        label="name"
        track-by="id"
        :clearable="false"
        :searchable="false"
        :placeholder="$t('dashboard.scrum_label_placeholder')"
    >
        <template slot="option" slot-scope="{ option }">
            {{ option.name }}
        </template>
    </vue-multiselect>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SCRUMLABELS } from '@/store/scrumlabels';
import VueMultiselect from 'vue-multiselect';
import { localizeEnum } from '@/languages';

@Component({
    components: { VueMultiselect },
})
export default class ScrumLabelsDropdown extends Vue {
    value = [];

    created() {
        this.$store.dispatch(SCRUMLABELS.FETCH);
    }

    @Watch('value')
    onChangedValue() {
        this.$emit('change', this.value.map(selected => selected.id));
    }

    get scrumLabels() {
        var result = this.$store.getters[SCRUMLABELS.GET_ALL];

        result.unshift({
            color: '#8e44ac',
            id: -2,
            name: 'needs-customer-input',
            priority: 7,
        });

        return result;
    }

    get options() {
        var result = this.scrumLabels.map(label => ({
            id: label.id,
            name: this.getLabel(label.id),
        }));

        return result;
    }

    get scrumLabelNameMap() {
        return this.scrumLabels.reduce((rx, label) => {
            rx[label.id] = localizeEnum('common.scrum-labels', label.name);
            return rx;
        }, {});
    }

    getLabel(option) {
        return this.scrumLabelNameMap[option] || option;
    }
}
</script>
