<template>
    <modal-wrapper @before-open="beforeOpen" :modal-name="modalName" modal-class="modal">
        <div class="modal__header">
            <h1>{{ header }}</h1>
        </div>
        <!-- TODO: Nienke: Style the modal -->
        <div class="modal__content" style="text-align: center;">
            <p class="alert--error">{{ body }}</p>
        </div>
        <div class="modal__footer">
            <p>{{ $t('error-modal.footer') }}</p>
        </div>
    </modal-wrapper>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ModalWrapper from '@/components/general/ModalWrapper';
import {
    localizeNetworkErrorCode,
    getNetworkErrorCode,
    ERROR_CODES,
} from '@/api/util/network-errors';

const ERROR_MODAL_NAME = 'error-modal';

@Component({
    components: { ModalWrapper },
})
export default class ErrorModal extends Vue {
    modalName = ERROR_MODAL_NAME;
    error = null;
    beforeOpen(event) {
        this.error = event.params.error;
    }

    get networkErrorCode() {
        return getNetworkErrorCode(this.error);
    }

    get isNetworkError() {
        return this.networkErrorCode !== ERROR_CODES.UNKNOWN;
    }

    get localizedErrorCode() {
        return localizeNetworkErrorCode(this.networkErrorCode);
    }

    get header() {
        return this.$t(
            this.isNetworkError
                ? 'error-modal.title-network-error'
                : 'error-modal.title-general-error',
        );
    }

    get body() {
        if (this.isNetworkError) {
            return this.localizedErrorCode;
        } else if (this.error) {
            return this.error.message;
        } else {
            return 'UNKNOWN';
        }
    }

    close() {
        this.$modal.hide(this.modalName);
    }
}

Vue.prototype.$showError = function(error) {
    if (error) {
        this.$modal.show(ERROR_MODAL_NAME, { error });
    }
};
</script>
