import { featurebot } from '../util/servers';
import { UserInfo } from '@/api/modules/auth';
import { ApiObject } from '@/api/util/api-object';

/**
 * @typedef {string} HexColorString
 */

export class ProjectLabel extends ApiObject {
    /** @member {Project} */ project;
    /** @member {string} */ name;
    /** @member {HexColorString} */ color;
    /** @member {number} */ openIssues;
    /** @member {number} */ closedIssues;

    constructor(project = null) {
        super();
        this.project = project;
        this.name = '';
        this.color = '#ff0000';
        this.openIssues = 0;
        this.closedIssues = 0;
    }

    parseApiData(apiData) {
        super.parseApiData(apiData);
        this.name = apiData.name;
        this.color = apiData.color;
        this.openIssues = apiData.issues.opened;
        this.closedIssues = apiData.issues.closed;
        return this;
    }

    getEditableClone() {
        const clone = new ProjectLabel(this.project);
        clone.id = this.id;
        clone.name = this.name;
        clone.color = this.color;
        clone.openIssues = this.openIssues;
        clone.closedIssues = this.closedIssues;
        return clone;
    }

    static nameComparator(a, b) {
        const nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
        if (nameA < nameB)
            //sort string ascending
            return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
    }
}

export class ProjectUser extends UserInfo {
    /** @member {Project} */ project;

    constructor(project = null) {
        super();
        this.project = project;
    }

    getEditableClone() {
        const clone = new ProjectUser(this.project);
        return clone.parseApiData(this);
    }
}

export class Project extends ApiObject {
    /** @member {string} */ name;
    /** @member {ProjectLabel[]} */ labels;
    /** @member {ProjectUser[]} */ users;

    constructor() {
        super();
        this.name = '';
        this.labels = [];
        this.users = [];
        this.can_create_issues = true;
    }

    parseApiData(apiData) {
        super.parseApiData(apiData);
        this.name = apiData.name;
        this.labels = apiData.labels.map(apiLabel => new ProjectLabel(this).parseApiData(apiLabel));
        this.labels.sort(ProjectLabel.nameComparator);
        this.users = apiData.customers.map(apiUser => new ProjectUser(this).parseApiData(apiUser));
        this.can_create_issues = apiData.can_create_issues;
        return this;
    }

    getEditableClone() {
        const clone = new Project();
        clone.id = this.id;
        clone.name = this.name;
        clone.labels = this.labels.slice();
        clone.users = this.users.slice();
        clone.can_create_issues = this.can_create_issues;
        return clone;
    }

    insertLabel(label) {
        const labelIndex = this.labels.findIndex(l => l.id === label.id);
        console.assert(labelIndex < 0, 'Trying to add existing label');
        this.labels.push(label);
        this.labels.sort(ProjectLabel.nameComparator);
    }

    replaceLabel(label) {
        const labelIndex = this.labels.findIndex(l => l.id === label.id);
        console.assert(labelIndex >= 0, 'Trying to update missing label');
        this.labels.splice(labelIndex, 1, label);
        this.labels.sort(ProjectLabel.nameComparator);
    }

    removeLabel(label) {
        const labelIndex = this.labels.findIndex(l => l.id === label.id);
        console.assert(labelIndex >= 0, 'Trying to remove missing label');
        this.labels.splice(labelIndex, 1);
    }
}

export class ProjectInvoice {
    /** @member {number} */
    costs;
    /** @member {number} */
    hours;
    constructor(apiData) {
        this.costs = apiData.costs;
        this.hours = apiData.num_hours;
    }
}

export const projectsApi = {
    projects: {
        /**
         * Fetches the list of project that the current user has access to
         * @returns {Promise<Project[]>}
         */
        async get() {
            // TODO: Fix callers
            const { projects } = await featurebot.get(`projects/`);
            if (!projects) throw new Error('No projects in user account');
            return projects.map(projectData => new Project().parseApiData(projectData));
        },
        /**
         * Fetches the invoice information for a given project
         * @param {number} projectId
         * @returns {Promise<ProjectInvoice>}
         */
        async getInvoice(projectId) {
            console.assert(!!projectId, 'project id is required');
            const response = await featurebot.get(`projects/${projectId}/invoice/`);
            console.log('response invoice', response);
            return new ProjectInvoice(response);
        },
    },
    labels: {
        /**
         * @param {ProjectLabel} label
         * @returns {Promise<ProjectLabel>}
         */
        async create(label) {
            const project = label.project;
            console.assert(project.id, 'project id required');
            const payload = { name: label.name, color: label.color };
            const data = await featurebot.post(`projects/${project.id}/labels/`, payload);
            return new ProjectLabel(project).parseApiData({
                ...payload,
                id: data.id,
                issues: {
                    opened: 0,
                    closed: 0,
                },
            });
        },
        /**
         * Saves the properties of a label and returns the saved version of the label
         * @param {ProjectLabel} label
         * @returns {Promise<ProjectLabel>}
         */
        async update(label) {
            const project = label.project;
            console.assert(project.id, 'project id required');
            console.assert(label.id, 'label id required');
            const payload = {
                name: label.name,
                color: label.color,
            };
            await featurebot.put(`projects/${project.id}/labels/${label.id}/`, payload);
            return label;
        },
        /**
         * Deletes the project label from the backend
         * @param {ProjectLabel} label
         * @returns {Promise<void>}
         */
        async destroy(label) {
            const project = label.project;
            await featurebot.delete(`projects/${project.id}/labels/${label.id}/`);
        },
    },
};
