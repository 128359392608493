import { scrumlabels } from '@/api';

const namespace = 'scrumlabels/';

export const SCRUMLABELS = Object.freeze({
    SET_FETCHING: namespace + 'set_fetching',
    SET_LABELS: namespace + 'set_labels',
    FETCH: namespace + 'fetch',
    GET_ALL: namespace + 'get_all',
    GET_BY_ID: namespace + 'get_by_id',
});

export default {
    state: () => {
        return {
            scrumlabels: [],
            fetchPromise: null,
        };
    },
    mutations: {
        [SCRUMLABELS.SET_FETCHING]: (state, promise) => {
            state.fetchPromise = promise;
        },
        [SCRUMLABELS.SET_LABELS]: (state, projects) => {
            state.scrumlabels = projects;
        },
    },
    actions: {
        [SCRUMLABELS.FETCH]: ({ state, commit }) => {
            // If we are already fetching (or have fetched the projects) then return the promise for that fetch
            const alreadyFetchedPromise = state.fetchPromise;
            if (alreadyFetchedPromise) {
                return alreadyFetchedPromise;
            }
            // No successful fetch yet, create a new promise that fetches the projects
            const promise = scrumlabels
                .get()
                .then(projects => {
                    commit(SCRUMLABELS.SET_LABELS, projects);
                    return projects;
                })
                .catch(error => {
                    // On error, clear the fetching promise from the store and propagate the error
                    console.warn('[store] [scrumlabels] Fetch failed', error);
                    commit(SCRUMLABELS.SET_FETCHING, null);
                    return Promise.reject(error);
                });
            commit(SCRUMLABELS.SET_FETCHING, promise);
            return promise;
        },
    },
    getters: {
        [SCRUMLABELS.GET_ALL]: state => state.scrumlabels,
        [SCRUMLABELS.GET_BY_ID]: state => id => state.scrumlabels.find(label => label.id === id),
    },
};
