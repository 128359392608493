<template>
    <div class="node">
        <div
            @mouseenter="hover(component)"
            @mouseleave="hover(null)"
            class="node__main"
            :class="{
                'node__main--external': isExternal,
                'node__main--hidden': isHidden,
                'node__main--focussed': isFocussed,
            }"
        >
            <span @click="toggle" class="node__main__expander" :style="indentStyle">
                <i class="mdi" :class="expanderIcon"></i>
            </span>
            <span @click="focus(component)" class="node__main__name">
                {{ nameText }}
            </span>
            <badge
                v-for="(badge, index) in badges"
                :key="index"
                class="node__main__badge"
                v-bind="badge"
            />
            <span @click.prevent="jumpTo" class="node__main__focus">
                <i class="mdi mdi-eye"></i>
            </span>
        </div>
        <!-- Using v-if so that collapsing children causes it to collapse all children as well -->
        <div v-if="expanded" class="node__children">
            <component-tree-node
                v-for="(child, index) in children"
                :key="index"
                :component="child"
                :depth="childrenDepth"
                @request-hover="hover"
                @request-focus="focus"
                ref="treeBranch"
                :filter="filter"
            />
        </div>
    </div>
</template>
<style lang="scss">
@import '../../assets/css/general/mixins';
@import '../../assets/css/general/settings';

.node__main {
    display: flex;

    .node__main__expander .mdi {
        width: 24px;
        font-size: 24px;
    }

    .node__main__name {
        flex-grow: 1;
    }

    .node__main__badge {
    }

    .node__main__focus {
        margin-right: 1rem;
    }

    &:hover {
        background-color: $color-grey-light;
        span:hover {
            color: $color-theme-dark;
        }
    }
    &--focussed {
        span {
            color: $color-cta;
        }
    }
    &--external {
        color: $color-grey;
    }
    &--hidden {
        display: none;

        + .node__children > .node > .node__main {
            border-top: 1px solid red;
        }
    }
}
</style>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { componentIsExternal, componentName } from '@/devbar/component-info';
import { getDevbarOptions } from '@/devbar/decorators';
import Badge from '@/devbar/Badge';

@Component({
    components: { Badge },
})
export default class ComponentTreeNode extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue })
    component;
    @Prop({ type: Number, default: 0 })
    depth;
    @Prop({ type: Function, default: null })
    filter;

    get indentStyle() {
        return `padding-left:${this.depth * 2}rem`;
    }

    expanded = true; // Default expanded state of a newly rendered child

    get expanderIcon() {
        if (!this.children.length) {
            return 'mdi-checkbox-blank-outline';
        }
        return this.expanded ? 'mdi-minus-box' : 'mdi-plus-box-outline';
    }

    get isExternal() {
        return componentIsExternal(this.component);
    }

    get nameText() {
        return componentName(this.component);
    }

    get badges() {
        const devbar = getDevbarOptions(this.component);
        if (devbar) {
            return devbar.badges.map(badge => badge(this.component));
        } else {
            return [];
        }
    }

    get isHidden() {
        if (!this.filter) return false;
        return !this.filter(this.component);
    }

    get children() {
        return this.component.$children;
    }

    toggle() {
        this.expanded = !this.expanded;
    }

    hover(component) {
        this.$emit('request-hover', component);
    }

    focus(component) {
        this.$emit('request-focus', component);
    }

    /**
     * Gets the depth to render the children at, increases with each recursive render of a component.
     * Unless the component is filtered out (but its children are not).
     * @returns {*}
     */
    get childrenDepth() {
        return this.isHidden ? this.depth : this.depth + 1;
    }

    get isFocussed() {
        if (!this.$devbar.focus) {
            return false;
        }
        return this.$devbar.focus === this.component;
    }

    /**
     * @param {Vue[]} trace
     */
    async expandTrace(trace, skipRefresh = false) {
        if (this.component === trace[0]) {
            if (skipRefresh) {
                this.expanded = false;
                await this.$nextTick();
            }
            this.expanded = true;
            await this.$nextTick();
            if (trace.length > 1) {
                const subtrace = trace.slice(1);
                this.$refs.treeBranch.forEach(node => {
                    node.expandTrace(subtrace, true);
                });
            } else {
                // This node is the target of the trace, scroll to it
                this.$el.scrollIntoView(true);
            }
        }
    }

    jumpTo() {
        this.component.$el.scrollIntoView(true);
    }

    _updateHandler;

    created() {
        this._updateHandler = this.onDomMutation.bind(this);
        this.$devbar.$on('dom-mutation', this._updateHandler);
    }

    beforeDestroy() {
        this.$devbar.$off('dom-mutation', this._updateHandler);
    }

    onDomMutation() {
        this.$forceUpdate();
    }
}
</script>
