/**
 * Indicates whether a given component is pre-compiled or resides in node_modules
 * @param {Vue} component
 * @returns {boolean}
 */
export function componentIsExternal(component) {
    return !component.$options.__file || component.$options.__file.startsWith('node_modules/');
}

/**
 * Gets the name of a component, either the original name or the tag with which it has been used
 * @param {Vue} component
 * @returns {string}
 */
export function componentName(component) {
    if (componentIsExternal(component)) {
        return component.$vnode.componentOptions.tag;
    } else {
        return component.$options.name;
    }
}

/**
 * Gets the file the component was compiled from or an empty string if it was not compiled
 * @param {Vue} component
 * @returns {string}
 */
export function componentSourceFile(component) {
    return component.$options.__file ? component.$options.__file : '';
}
