const namespace = 'breadcrumbs/';

export const BREADCRUMBS = Object.freeze({
    ISSUES_PAGE_VISIT: namespace + 'issues_page_visit',
});

export default {
    state: () => ({
        issuesPageType: 'Issues',
    }),
    mutations: {
        [BREADCRUMBS.ISSUES_PAGE_VISIT]: (state, pageType) => {
            state.issuesPageType = pageType;
        },
    },
};
