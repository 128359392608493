<template>
    <div class="progress_bar" :class="progressClass">
        <div class="progress_bar__filling" :style="fillingStyle" />
    </div>
</template>

<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DebugToggleComputed } from '@/devbar/decorators';

@Component
export default class ProgressBar extends Vue {
    @Prop({ type: Number, default: 0.0 })
    min;
    @Prop({ type: Number, default: 1.0 })
    max;
    @Prop({ type: Number, default: 0.0 })
    value;

    @DebugToggleComputed({ label: 'fraction: 0%', factory: () => 0 })
    @DebugToggleComputed({ label: 'fraction: 1%', factory: () => 0.01 })
    @DebugToggleComputed({ label: 'fraction: 33%', factory: () => 1 / 3 })
    @DebugToggleComputed({ label: 'fraction: 50%', factory: () => 1 / 2 })
    @DebugToggleComputed({ label: 'fraction: 99%', factory: () => 0.99 })
    @DebugToggleComputed({ label: 'fraction: 100%', factory: () => 1 })
    get fraction() {
        const min = this.min;
        const max = this.max;
        const value = this.value;
        if (!Number.isFinite(min)) return 0;
        if (!Number.isFinite(max)) return 0;
        const clampedValue = Math.max(min, Math.min(max, value));
        const rangeValue = clampedValue - min;
        const rangeMax = max - min;
        return rangeValue / rangeMax;
    }

    get fillingStyle() {
        return {
            minWidth: this.fraction > 0 ? '1em' : undefined,
            width: `${this.fraction * 100}%`,
        };
    }

    get isOverflown() {
        return this.value > this.max;
    }

    get progressClass() {
        return this.isOverflown ? 'progress_bar--overflow' : null;
    }
}
</script>
