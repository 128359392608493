import { PROJECTS } from '@/store/projects';

// When the user is a mono user this mixin will redirect the current page automatically to one with project_id set to the users mono project id.
// Requires ProjectIdMixin to be present
export const MonoProjectRedirectMixin = {
    watch: {
        hasMonoProject: {
            immediate: true,
            async handler(isMonoProject) {
                if (isMonoProject) {
                    // Promise is recycled so if loaded it resolves immediately.
                    // If the current page is all_projects then we can jump to the specific project instead
                    if (!this.project_id) {
                        const monoProjectId = this.$store.getters[PROJECTS.GET_MONO_PROJECT_ID];
                        this.$router.replace({
                            params: { project_id: monoProjectId },
                        });
                    }
                }
            },
        },
    },
};
