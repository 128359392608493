import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from '@/pages/PageNotFound';
import AuthenticationPage from '@/pages/AuthenticationPage';
import Profile from '@/pages/Profile';
import IssuesPage from '@/pages/IssuesPage';
import PrioritiesPage from '@/pages/PrioritiesPage';
import ProjectPage from '@/pages/ProjectPage';
import ProjectLabelPage from '@/pages/ProjectLabelPage';
import IssueEditorPage from '@/pages/IssueEditorPage';
import IssueDetailsPage from '@/pages/IssueDetailsPage';
import ScrumBoardPage from '@/pages/ScrumBoardPage';

// Authentication Pages
import LoginForm from '@/components/auth/forms/LoginForm';
import PasswordResetForm from '@/components/auth/forms/PasswordResetForm';
import PasswordForgottenForm from '@/components/auth/forms/PasswordForgottenForm';
import ChangePasswordForm from '@/components/auth/forms/ChangePasswordForm';
import SSOHandler from '@/components/auth/SSOHandler';
import store from '@/store';
import { AUTH } from '@/store/auth';
import { PROJECTS } from '@/store/projects';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/',
            name: 'Home',
            beforeEnter: async (to, from, next) => {
                // Redirect based on whether the user is a mono-project user or not.
                // First ensure that the projects have been fetched (this redirects to auth if not authenticated)
                await store.dispatch(PROJECTS.FETCH);
                next({
                    name: 'Issues',
                    params: {
                        project_id: store.getters[PROJECTS.IS_MONO]
                            ? store.getters[PROJECTS.GET_MONO_PROJECT_ID]
                            : 'all',
                    },
                });
                return false;
            },
        },
        {
            path: '/projects/:project_id',
            name: 'Project',
            component: ProjectPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/issues',
            name: 'Issues',
            component: IssuesPage,
            meta: {
                auth: true,
                fluidContainer: true,
            },
        },
        {
            path: '/projects/:project_id/issues/new',
            name: 'Issue.New',
            component: IssueEditorPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/issues/:issueId',
            name: 'Issue',
            component: IssueDetailsPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/issues/:issueId/edit',
            name: 'Issue.Edit',
            component: IssueEditorPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/priorities',
            name: 'ProjectPriorities',
            component: PrioritiesPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/label/:labelId',
            name: 'ProjectLabel',
            component: ProjectLabelPage,
            meta: {
                auth: true,
            },
        },
        {
            path: '/projects/:project_id/scrumboard',
            name: 'ScrumBoard',
            component: ScrumBoardPage,
            meta: {
                auth: true,
                fluidContainer: true,
            },
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                auth: true,
            },
        },
        {
            path: '/auth',
            component: AuthenticationPage,
            children: [
                {
                    path: '/',
                    name: 'sign-in',
                    component: LoginForm,
                    meta: {
                        auth: false,
                    },
                },
                {
                    path: 'reset',
                    component: PasswordForgottenForm,
                    name: 'reset',
                    meta: {
                        auth: false,
                    },
                },
                {
                    path: 'reset/token/:token',
                    component: PasswordResetForm,
                    name: 'reset/token',
                    meta: {
                        auth: false,
                    },
                },
                {
                    path: 'change-password',
                    name: 'change-password',
                    component: ChangePasswordForm,
                    meta: {
                        auth: true,
                    },
                },
                {
                    path: 'sso',
                    name: 'sso',
                    component: SSOHandler,
                },
            ],
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: PageNotFound,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (!to.meta) {
        next();
        return;
    }
    if (to.meta.auth) {
        // Route requires sign-in
        // Check if user is signed in. Route requires sign in.
        if (store.getters[AUTH.IS_AUTHENTICATED]) {
            next();
        } else {
            next({
                name: 'sign-in',
                query: {
                    redirect: to.path,
                },
            });
        }
    } else if (to.meta.auth === false) {
        // Route demands no login
        if (store.getters[AUTH.IS_AUTHENTICATED]) {
            next({ name: 'Home' });
        } else {
            next();
        }
    } else {
        next();
    }
});

/**
 * Disable debug routes (stylesheet, etc) on production
 */
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.debugRoute)) {
        if (!Vue.prototype.$config.debug) {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
