<template>
    <div v-if="loading" class="loading">
        <h2>{{ $t('feature_modal.header_loading') }}...</h2>
        <div class="spinner"></div>
    </div>
    <div v-else-if="errorCode || !issue">
        <h2>{{ $t('feature_modal.header_loaderror') }}</h2>
        <p class="alert--error">{{ localizedIssueErrorCode }}</p>
    </div>
    <div v-else class="row">
        <portal to="notification-bar" v-if="showAttachmentErrorNotification">
            <div class="notification">
                <i class="mdi mdi-alert-circle"></i>
                {{ $t('feature_modal.notification_attachment_error') }}
                <button class="button--link" @click.prevent="dismissCreationNotification">
                    {{ $t('general.close') }}
                </button>
            </div>
        </portal>
        <portal to="notification-bar" v-else-if="showCreationNotification">
            <div class="notification">
                <i class="mdi mdi-check-circle"></i>
                {{ $t('feature_modal.notification_creation_successful') }}
                <router-link :to="createNewIssueLink" tag="button" class="button--cta">
                    {{ $t('feature_modal.button_create_another') }}
                </router-link>
                <button class="button--link" @click.prevent="dismissCreationNotification">
                    {{ $t('general.close') }}
                </button>
            </div>
        </portal>
        <div class="col-12">
            <div class="issue-details__toggle">
                <div class="issue-details__toggle__status" :class="{ 'is-inactive': showDetails }">
                    <i class="mdi mdi-circle-medium" :class="statusColor"></i>
                    <span>
                        {{ $t('issue_details.status') + ': ' }}
                        <span :class="statusColor">
                            {{ statusText }}
                        </span>
                    </span>
                </div>
                <div
                    class="issue-details__toggle__button"
                    :class="{ 'is-active': showDetails }"
                    @click="showDetails = !showDetails"
                >
                    <i class="mdi mdi-chevron-double-left"></i>
                    Show details
                    <i class="mdi mdi-chevron-double-right"></i>
                </div>
            </div>
        </div>
        <div
            class="col-12 col-lg issue-details__issue_container"
            :class="{ 'is-inactive': showDetails }"
        >
            <div class="block">
                <div class="issue-details__issue_container__issue-heading">
                    <router-link :to="editIssueRoute" class="pull-right is-heading-h2">
                        <i class="mdi mdi-pencil"></i>
                    </router-link>
                    <h2>{{ issue.title }}</h2>
                    <p>
                        {{ issue.project.name }}
                        <span class="mdi mdi-circle-small"></span>
                        #{{ issue.iid }}
                    </p>
                    <p>{{ creationInfoString }}</p>
                    <b>{{ $t('issue_details.description') }}</b>
                </div>
                <div class="block__external_content">
                    <markdown :source="issue.description" />
                </div>
            </div>
            <div class="issue-details__issue_container__issue-comments">
                <issue-comments :issue="issue" />
            </div>
        </div>
        <div
            class="col-12 col-lg-auto issue-details__container"
            :class="{ 'is-active': showDetails }"
        >
            <issue-details :closedAt="closedDate" :issue="issue" />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { ProjectIdMixin } from '../mixins/project-id-route';
import { ISSUES } from '../store/issues';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';
import AttachmentsList from '@/components/blocks/AttachmentsList';
import IssueDetails from '@/components/blocks/IssueDetails';
import IssueComments from '@/components/blocks/IssueComments';
import { DebugToggle, DebugToggleComputed } from '@/devbar/decorators';
import Markdown from '../components/general/Markdown';
import { localizeEnum } from '@/languages';

@Component({
    components: {
        Markdown,
        IssueComments,
        IssueDetails,
        AttachmentsList,
    },
    mixins: [ProjectIdMixin],
})
export default class IssueDetailsPage extends Vue {
    showDetails = false;
    get issueId() {
        return parseInt(this.$route.params.issueId);
    }

    get issue() {
        return this.$store.getters[ISSUES.GET_FROM_CACHE](this.fetchIssueParam);
    }

    @DebugToggle({ label: 'Loading' })
    loading = false;
    @DebugToggle({
        label: 'ErrorCode',
        on: ERROR_CODES.UNKNOWN,
        off: ERROR_CODES.NONE,
    })
    errorCode = null;

    get fetchIssueParam() {
        return {
            project_id: this.project_id,
            iid: this.issueId,
            details: true,
        };
    }

    @Watch('fetchIssueTrigger', { immediate: true })
    fetchIssueData() {
        this.loading = true;
        this.$store
            .dispatch(ISSUES.GET, this.fetchIssueParam)
            .catch(error => {
                this.errorCode = error.errorCode;
                throw error;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    get localizedIssueErrorCode() {
        switch (this.errorCode) {
            case ERROR_CODES.FORBIDDEN:
                return this.$t('feature_modal.project_not_allowed');
            case ERROR_CODES.NOT_FOUND:
                return this.$t('feature_modal.issue_not_found');
            default:
                return localizeNetworkErrorCode(this.errorCode);
        }
    }

    get editIssueRoute() {
        return {
            name: 'Issue.Edit',
            params: {
                ...this.$route.params,
            },
            query: {
                ...this.$route.query,
            },
        };
    }

    formatDate(date) {
        return moment(date).format('LL');
    }

    formatDateTime(dateTime) {
        return moment(dateTime).fromNow();
    }

    get deadline() {
        if (!this.issue.due_date) return null;
        return moment(this.issue.due_date).format('LL');
    }

    get creationDate() {
        return moment(this.issue.add_date)
            .utc()
            .format('DD-MM-YYYY');
    }

    get closedDate() {
        return this.issue.closed_at
            ? moment(this.issue.closed_at)
                  .utc()
                  .format('DD-MM-YYYY')
            : '';
    }

    get statusText() {
        return localizeEnum('common.issue_state', this.issue.state);
    }

    get statusColor() {
        return this.issue.state === 'opened' ? 'is-open' : 'is-closed';
    }

    get creationInfoString() {
        let dateAndAuthor = this.$t('issue_details.opened-by', {
            date: this.creationDate,
            author: this.issue.author_name || this.$t('general.unknown'),
        });
        return `${dateAndAuthor}`;
    }

    beforeRouteUpdate(to, from, next) {
        this.dismissCreationNotification();
        next();
    }

    beforeRouteLeave(to, from, next) {
        this.dismissCreationNotification();
        next();
    }

    dismissCreationNotification() {
        this.$store.commit(ISSUES.CREATED_NEW, {
            showCreationNotification: false,
            showAttachmentError: false,
        });
    }

    @DebugToggleComputed({
        label: 'Creation notification',
        factory: () => true,
    })
    get showCreationNotification() {
        return this.$store.state.issues.showCreationNotification;
    }

    @DebugToggleComputed({
        label: 'Attachment error notification',
        factory: () => true,
    })
    get showAttachmentErrorNotification() {
        return this.$store.state.issues.showAttachmentError;
    }

    get createNewIssueLink() {
        return {
            name: 'Issue.New',
            params: {
                project_id: this.$route.params.project_id,
            },
        };
    }
}
</script>
