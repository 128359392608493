/* eslint-disable no-undef */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './logging';
import './polyfills';
import './router/class-component-hooks';
import './api/util/install-interceptors';
import Vue from 'vue';
import App from './App';
import router from './router';
import i18n from './languages';
import store from './store';
import vmodal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import { frontend, featurebot } from './api/util/servers';
import VueAxios from 'vue-axios';
import VueSimplemde from 'vue-simplemde';
import 'simplemde/dist/simplemde.min.css';
import '@/components/general/VueSelect';
import PortalVue from 'portal-vue';
import { devbar } from '@/devbar';
import '@/filters';
import { connectSentry } from '@/error-reporting';

Vue.config.productionTip = false;

// Register the config independent modules.
Vue.use(vmodal);
Vue.use(VTooltip);
Vue.use(VueSimplemde);
Vue.use(PortalVue);
if (process.env.NODE_ENV === 'development') {
    Vue.use(devbar.plugin);
}

// Override the config with the one from /static/config
const configFileName =
    process.env.NODE_ENV === 'production' ? 'config.json' : process.env.NODE_ENV + '.json';
frontend
    .get('/config/' + configFileName)
    .then(config => config.data)
    .then(
        config => {
            Vue.prototype.$config = config;
            Vue.prototype.$config.debug = process.env.NODE_ENV === 'development';

            // Set the baseURL according to the latest config and register the instance.
            featurebot.defaults.baseURL = Vue.prototype.$config.api.url;
            Vue.use(VueAxios, featurebot);

            connectSentry(config);
            connectAnalytics();

            const app = new Vue({
                el: '#app',
                router,
                i18n,
                store,
                devbar,
                render: h => h(App),
            });
            if (process.env.NODE_ENV === 'development') {
                devbar.launch(app);
            }
        },
        () => console.log('No configuration file provided.'),
    );

function connectAnalytics() {
    // Connect analytics if enabled.
    let config = Vue.prototype.$config;
    if (!config.debug && config.analytics && config.analytics.enabled) {
        const VueAnalytics = require('vue-analytics');
        Vue.use(VueAnalytics, {
            id: config.analytics.id,
            router,
            autoTracking: {
                screenview: true,
                pageviewTemplate(route) {
                    return {
                        page: route.path,
                        title: route.name,
                        location: window.location.href,
                    };
                },
            },
        });
    }
}
