<template>
    <modal-wrapper modalClass="modal--image" :modalName="name" @before-open="beforeOpen">
        <img :src="url" />
    </modal-wrapper>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ModalWrapper from '@/components/general/ModalWrapper';

@Component({
    components: {
        ModalWrapper,
    },
})
export default class ImageModal extends Vue {
    name = 'image-modal';

    url = null;

    beforeOpen(event) {
        this.url = event.params.url;
    }
}
</script>
