var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issues--full_width"},[_c('div',[_c('div',{staticClass:"row issues__header"},[_c('div',{staticClass:"col-10"},[_c('h1',[_vm._v(_vm._s(_vm.$t('common.features')))]),(!_vm.hasMonoProject)?_c('projects-dropdown',{staticClass:"multiselect--alt",model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}}):(_vm.project)?_c('span',{staticClass:"col-10"},[_c('h1',[_vm._v(_vm._s(_vm.project.name))])]):_vm._e()],1),_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"pull-right button--cta",attrs:{"to":_vm.createIssueRoute}},[_c('i',{staticClass:"mdi mdi-plus-circle-outline"}),_vm._v("\n                    "+_vm._s(_vm.$t('feature_modal.add_feature'))+"\n                ")])],1)]),_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12 col-xl order-last order-md-0 order-xl-first issues__tabs"},[_c('div',{staticClass:"row"},[_c('a',{staticClass:"button--tab col col-md-auto",class:{ active: _vm.statusFilter === null },attrs:{"href":"#"},on:{"click":function($event){return _vm.onTabClick(null)}}},[_vm._v("\n                        "+_vm._s(_vm.$t('common.issue_state.all'))+"\n                        "+_vm._s(!_vm.loadingIssues && _vm.statusFilter === null
                                ? (" (" + (_vm.allIssuesCount.all) + ")")
                                : '')+"\n                    ")]),_c('a',{staticClass:"button--tab col col-md-auto",class:{ active: _vm.statusFilter === 'opened' },attrs:{"href":"#"},on:{"click":function($event){return _vm.onTabClick('opened')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('common.issue_state.opened'))+"\n                        "+_vm._s(!_vm.loadingIssues && (_vm.statusFilter === 'opened' || !_vm.statusFilter)
                                ? (" (" + (_vm.allIssuesCount.open_issues) + ")")
                                : '')+"\n                    ")]),_c('a',{staticClass:"button--tab col col-md-auto",class:{ active: _vm.statusFilter === 'closed' },attrs:{"href":"#"},on:{"click":function($event){return _vm.onTabClick('closed')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('common.issue_state.closed'))+"\n                        "+_vm._s(!_vm.loadingIssues && (_vm.statusFilter === 'closed' || !_vm.statusFilter)
                                ? (" (" + (_vm.allIssuesCount.closed_issues) + ")")
                                : '')+"\n                    ")])])]),_c('div',{staticClass:"col-12 col-xl-auto order-0 order-md-first"},[_c('div',{staticClass:"row issues__actions justify-content-end"},[_c('div',{staticClass:"col-12 col-md-auto"},[_c('sprint-dropdown',{staticClass:"multiselect--alt",model:{value:(_vm.sprint),callback:function ($$v) {_vm.sprint=$$v},expression:"sprint"}})],1),_c('div',{staticClass:"col-12 col-md-auto"},[_c('scrum-labels-dropdown',{staticClass:"multiselect--alt",attrs:{"disabled":_vm.scrumFilterDisabled},on:{"change":_vm.setScrumLabels}})],1),(_vm.project_id)?_c('div',{staticClass:"col col-md-auto"},[_c('router-link',{staticClass:"button--alt",attrs:{"to":{
                                name: 'ProjectPriorities',
                                params: { project_id: _vm.project_id },
                            }}},[_c('i',{staticClass:"mdi mdi-sort"}),_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.button_edit_priorities')))])])],1):_vm._e(),_c('div',{staticClass:"col col-md-auto"},[_c('issue-view-links')],1)])]),_c('div',{staticClass:"col-12 order-first order-md-last"},[_c('div',{staticClass:"issues__search-row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-8 col-xl-8"},[_c('search-box',{attrs:{"placeholder":_vm.$t('general.search_feature')},on:{"search":_vm.submitQuery},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 col-xl-4"},[_c('sort-order-dropdown',{attrs:{"options":_vm.sortOptions,"placeholder":_vm.$t('dashboard.sort_dropdown_placeholder')},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1)])])]),_c('div',{staticClass:"row"},[(_vm.errorCode)?_c('div',{staticClass:"col-12 issues__error"},[_c('div',{staticClass:"alert--auth"},[_c('p',[_vm._v(_vm._s(_vm.localizedErrorMessage))])])]):_vm._e()])]),_c('div',{staticClass:"block--table"},[_c('issues-table',{attrs:{"issues":_vm.issues,"loading":_vm.loadingIssues},on:{"view":_vm.showDetails,"edit":_vm.openEdit}}),(_vm.pageCount > 1)?_c('div',{staticClass:"issues__pagination"},[_c('pagination',{attrs:{"page-count":_vm.pageCount},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }