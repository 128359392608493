export class TemplateDebugAction {
    /** @member {string} */
    name;
    /** @member {Function} */
    method;

    /**
     * @param {string} name
     * @param {Function} method
     */
    constructor({ name, method }) {
        this.name = name;
        this.method = method;
    }

    /**
     * @param {Vue} component
     * @returns {ComponentDebugAction}
     */
    bind(component) {
        return new ComponentDebugAction(this, component);
    }

    /**
     * @param {Vue} component
     * @returns {*}
     */
    execute(component) {
        return this.method.call(component);
    }
}

export class ComponentDebugAction extends TemplateDebugAction {
    /** @member {Vue} */
    component;

    /**
     * @param {TemplateDebugAction} action
     * @param {Vue} component
     */
    constructor(action, component) {
        super(action);
        this.component = component;
    }

    execute() {
        return super.execute(this.component);
    }
}

export class TemplateDebugToggle {
    /** @member {string} */
    label;
    /** @member {string} */
    property;
    /** @member {*} */
    on;
    /** @member {*} */
    off;

    /**
     * @param {string} label
     * @param {string} property
     * @param {*} on
     * @param {*} off
     */
    constructor({ label, property, on, off }) {
        this.label = label;
        this.property = property;
        console.assert(typeof on !== 'object' || on === null, 'Objects not supported for toggles');
        this.on = on;
        console.assert(
            typeof off !== 'object' || off === null,
            'Objects not supported for toggles',
        );
        this.off = off;
    }

    /**
     * @param {Vue} component
     * @returns {ComponentDebugToggle}
     */
    bind(component) {
        return new ComponentDebugToggle(this, component);
    }
}

export class ComponentDebugToggle extends TemplateDebugToggle {
    /** @member {Vue} */
    component;

    /**
     * @param {TemplateDebugToggle} toggle
     * @param {Vue} component
     */
    constructor(toggle, component) {
        super(toggle);
        this.component = component;
    }

    get state() {
        return this.component[this.property] == this.on;
    }

    set state(value) {
        this.component[this.property] = value ? this.on : this.off;
    }
}

/**
 * @callback DebugForceComputeCallback
 * @param {Vue} component
 * @returns {*}
 */

export class TemplateDebugToggleComputed {
    /** @member {string} */
    label;
    /** @member {string} */
    key;
    /** @member {number} */
    id;
    /** @member {DebugForceComputeCallback} */
    factory;

    constructor({ label, key, id, factory }) {
        this.label = label;
        this.key = key;
        this.id = id;
        this.factory = factory;
    }

    /**
     * @param {Vue} component
     * @returns {ComponentDebugToggleComputed}
     */
    bind(component) {
        return new ComponentDebugToggleComputed(this, component);
    }
}

export class ComponentDebugToggleComputed extends TemplateDebugToggleComputed {
    /** @member {Vue} */
    component;

    constructor(toggleComputed, component) {
        super(toggleComputed);
        this.component = component;
    }

    get state() {
        return this.component[this.key] === this.id;
    }

    set state(value) {
        if (value) {
            this.component[this.key] = this.id;
        } else {
            this.component[this.key] = -1;
        }
    }
}

ComponentDebugToggleComputed.unset = {};
