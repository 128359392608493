export class ApiObject {
    /** @member {number} */ id;

    /**
     * Fills the object with data from the api.
     * Each implementation of ApiObject should fill based on the raw entity from the api response.
     * Additionally, the method returns `this` to allow for chaining
     * @param {number} id - The id of the object
     * @returns {ApiObject} - The object itself
     */
    parseApiData({ id = null } = {}) {
        this.id = id;
        return this;
    }

    /**
     * Creates a clone of the object that is mutable.
     * Mutations should be supported up to common operations on the object that the api is able to store.
     * This generally means shallow cloning the object.
     * For fields that are mutable through the api (via put requests) they should be cloned deeply.
     * @returns {ApiObject} - Shallow clone of the object
     */
    getEditableClone() {
        throw new Error('getEditableClone() not implemented');
    }
}
