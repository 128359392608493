import { createDecorator } from 'vue-class-component';

export function CatchErrors({ rethrow = false } = {}) {
    return createDecorator((options, key) => {
        const originalMethod = options.methods[key];
        function catchErrorsWrapper() {
            const vm = this;
            console.log('handler', this, arguments);
            const handler = error => {
                vm.$showError(error);
                if (rethrow) {
                    throw error;
                }
            };
            try {
                const result = originalMethod.call(vm, ...arguments);
                if (typeof result.then === 'function' && typeof result.catch === 'function') {
                    return result.catch(handler);
                } else {
                    return result;
                }
            } catch (error) {
                handler(error);
            }
        }
        options.methods[key] = catchErrorsWrapper;
    });
}
