<template>
    <div class="table__responsive">
        <table class="table--drag">
            <thead>
                <tr>
                    <th class="table__cell--draghandle"></th>
                    <th v-for="(column, index) in columns" :key="index">
                        {{ column.header }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr>
                    <td colspan="10000">
                        <div class="spinner"></div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="!issues.length">
                <tr>
                    <td colspan="10000" class="table__cell--center">
                        {{ $t('dashboard.no_issues') }}
                    </td>
                </tr>
            </tbody>
            <draggable v-else tag="tbody" :list="issues" :options="dragOptions">
                <tr v-for="issue in issues" :key="issue.iid" :class="styleIssueRow(issue)">
                    <td class="table__cell--draghandle">
                        <i class="table__icon mdi mdi-drag-vertical"></i>
                    </td>
                    <td
                        v-for="(column, index) in columns"
                        :key="index"
                        :class="getDisplayClass(column, issue)"
                    >
                        <span>{{ column.displayValue(issue) }}</span>
                    </td>
                </tr>
            </draggable>
        </table>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import draggable from 'vuedraggable';
import { localizeWorktime } from '@/languages';

function createValidMoment(date) {
    const instance = moment(date);
    return instance.isValid() ? instance : undefined;
}

@Component({
    components: {
        draggable,
    },
})
export default class IssuesTable extends Vue {
    @Prop({ type: Array, default: () => [] })
    issues;
    @Prop({ type: Boolean, default: true })
    loading;
    @Prop({ type: Boolean, default: false })
    disabled;

    get dragOptions() {
        return {
            ghostClass: 'table__row--ghost',
            handle: '.table__cell--draghandle',
            disabled: this.disabled,
        };
    }

    get columns() {
        return [
            {
                header: this.$t('dashboard.priority'),
                displayValue: issue => issue.priority,
            },
            {
                header: this.$t('dashboard.name'),
                displayValue: issue => issue.title,
            },
            {
                header: this.$t('dashboard.deadline'),
                displayValue: issue =>
                    issue.due_date ? moment(issue.due_date).format('LL') : undefined,
                displayClass: 'table__cell--deadline',
            },
            {
                apiColumn: 'estimated_time',
                header: this.$t('dashboard.estimate'),
                displayValue: issue => localizeWorktime(issue.estimated_time),
                displayClass: issue => {
                    const result = ['table__cell--small_font', 'table__cell--estimate'];
                    const estimate = issue.estimated_time;
                    const spent = issue.spent_time;
                    if (estimate && spent && estimate < spent) {
                        result.push('table__cell--alert');
                    }
                    return result;
                },
                displayTooltip: issue => {
                    if (issue.state === 'opened') {
                        const estimate = issue.estimated_time;
                        const spent = issue.spent_time;
                        if (estimate && spent) {
                            if (estimate < spent) {
                                return this.$t('dashboard.tooltip_past_estimate', {
                                    worktime: localizeWorktime(spent - estimate),
                                });
                            } else {
                                return this.$t('dashboard.tooltip_remaining_estimate', {
                                    worktime: localizeWorktime(estimate - spent),
                                });
                            }
                        }
                    }
                },
            },
            {
                header: this.$t('dashboard.created'),
                displayValue: issue =>
                    moment(issue.add_date)
                        .utc()
                        .format('LL'),
            },
        ];
    }

    getDisplayClass(column, issue) {
        if (!column.displayClass) return undefined;
        if (typeof column.displayClass === 'string') return column.displayClass;
        return column.displayClass(issue);
    }

    styleIssueRow(issue) {
        // Style the row to become red when past deadline
        const deadline = createValidMoment(issue.due_date);
        if (!deadline) return undefined;
        if (deadline.isBefore()) {
            return 'table__row--alert';
        }
    }
}
</script>
