<template>
    <div>
        <div class="scrum_board__column__header">
            {{ title }}
        </div>
        <div class="scrum_board__column__issues">
            <scrum-card v-for="issue in issues" :key="issue.iid" :issue="issue" />
            <div v-if="loading" class="scrum_board__column__issues__extra">
                <div class="spinner" />
            </div>
            <template v-else>
                <div v-if="errorCode" class="scrum_board__column__issues__extra">
                    {{ localizedErrorCode }}
                </div>
                <div v-if="!issues.length" class="scrum_board__column__issues__extra">
                    {{ $t('scrum_board.column_no_issues') }}
                </div>
                <button
                    v-if="canLoadMore"
                    @click="loadNextBlock"
                    class="scrum_board__column__issues__extra--button button--theme"
                >
                    {{ $t('scrum_board.column_load_more') }}
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ScrumCard from '@/components/scrum/ScrumCard';
import { issues } from '@/api';
import { DebugBadge, DebugToggle } from '@/devbar/decorators';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';

@Component({
    components: { ScrumCard },
})
@DebugBadge(vm => 'scrumLabel: ' + vm.filter.scrumLabel)
export default class ScrumColumn extends Vue {
    @Prop({ type: String })
    title;
    @Prop({ type: Object })
    filter;
    issues = [];
    @DebugToggle()
    loading = false;
    @DebugToggle()
    errorCode = ERROR_CODES.NONE;
    pageCounter = 0;
    @DebugToggle()
    canLoadMore = false;

    @Watch('filter', { immediate: true })
    async loadColumnData() {
        this.pageCounter = 0;
        this.issues = [];
        this.loadNextBlock();
    }

    async loadNextBlock() {
        this.loading = true;
        try {
            const response = await issues.search({
                ...this.filter,
                page: this.pageCounter,
            });
            this.issues.push(...response.issues);
            this.pageCounter++;
            this.canLoadMore = this.pageCounter < response.pagination.pages;
            this.errorCode = ERROR_CODES.NONE;
        } catch (error) {
            if (error.errorCode) {
                this.errorCode = error.errorCode;
            }
            throw error;
        } finally {
            this.loading = false;
        }
    }

    get localizedErrorCode() {
        return localizeNetworkErrorCode(this.errorCode);
    }
}
</script>
