<template>
    <div class="breadcrumb">
        <li>
            <router-link v-for="link in crumbs" :key="link.display" :to="link">
                {{ link.display }}
            </router-link>
        </li>
    </div>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PROJECTS } from '@/store/projects';

@Component
export default class BreadcrumbBar extends Vue {
    get crumbs() {
        switch (this.$route.name) {
            default:
            case 'Issues':
            case 'ScrumBoard':
                return [this.profile, this.project, this.issues];
            case 'Issue':
                return [this.profile, this.project, this.issues, this.issueDetails];
            case 'Issue.Edit':
                return [
                    this.profile,
                    this.project,
                    this.issues,
                    this.issueDetails,
                    this.issueEditor,
                ];
            case 'Issue.New':
                return [this.profile, this.project, this.issues, this.issueCreator];
            case 'ProjectPriorities':
                return [this.profile, this.project, this.issues, this.projectPriorities];
            case 'ProjectLabel':
                return [this.profile, this.project, this.projectLabel];
            case 'Profile':
                return [this.profile];
            case 'Project':
                return [this.profile, this.project];
        }
    }

    get home() {
        return {
            display: this.$t('breadcrumbs.home'),
            name: 'Home',
        };
    }

    get profile() {
        return {
            display: this.$t('breadcrumbs.profile'),
            name: 'Profile',
        };
    }

    get project() {
        if (this.$route.params.project_id === 'all') {
            return this.home;
        }
        const id = parseInt(this.$route.params.project_id);
        const project = this.$store.getters[PROJECTS.GET_BY_ID](id);
        return {
            display: project ? project.name : this.$t('breadcrumbs.project'),
            name: 'Project',
            params: this.$route.params,
        };
    }

    get issues() {
        // The breadcrumbs store tracks the latest type of issues page visited
        const pageType = this.$store.state.breadcrumbs.issuesPageType;
        if (pageType === 'Issues') {
            return {
                display: this.$t('breadcrumbs.issues'),
                name: 'Issues',
                params: this.$route.params,
            };
        } else {
            return {
                display: this.$t('breadcrumbs.scrumboard'),
                name: 'ScrumBoard',
                params: this.$route.params,
            };
        }
    }

    get issueDetails() {
        return {
            display: '#' + this.$route.params.issueId,
            name: 'Issue',
            params: this.$route.params,
        };
    }

    get issueEditor() {
        return {
            display: this.$t('breadcrumbs.issueEditor'),
            name: 'Issue.Edit',
            params: this.$route.params,
        };
    }

    get issueCreator() {
        return {
            display: this.$t('breadcrumbs.issueCreator'),
            name: 'Issue.New',
            params: this.$route.params,
        };
    }

    get projectLabel() {
        const projectId = parseInt(this.$route.params.project_id);
        const project = this.$store.getters[PROJECTS.GET_BY_ID](projectId);
        const labelId = parseInt(this.$route.params.labelId);
        const label = project ? project.labels.find(l => l.id === labelId) : undefined;
        return {
            display: label ? label.name : this.$t('breadcrumbs.projectLabel'),
            name: 'Project',
            params: this.$route.params,
        };
    }

    get projectPriorities() {
        return {
            display: this.$t('breadcrumbs.projectPriorities'),
            name: 'ProjectPriorities',
            params: this.$route.params,
        };
    }
}
</script>
