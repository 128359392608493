<template>
    <vue-markdown :source="source" :task-lists="false" ref="rendered" />
</template>
<script>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueMarkdown from 'vue-markdown';
import { attachments } from '@/api';
import { presentDownload } from '@/api/downloads';
import { ProjectIdMixin } from '@/mixins/project-id-route';

const ATTACHMENT_URL_REGEX = /api\/web\/issues\/(\d+)\/(\d+)\/files\/(\d+)\//i;
const GITLAB_ATTACHMENT_URL_REGEX = /\/uploads\/([0-9a-fA-F]+)\/(.*)/i;

@Component({
    components: {
        VueMarkdown,
    },
    mixins: [ProjectIdMixin],
})
export default class Markdown extends Vue {
    @Prop({ type: String })
    source;
    @Watch('source', { immediate: true })
    async onSourceChanged() {
        await this.$nextTick();
        if (this.$refs.rendered && this.$refs.rendered.$el) {
            const markdown = this.$refs.rendered.$el;
            markdown.querySelectorAll('img').forEach(elem => {
                elem.onclick = () => {
                    this.$modal.show('image-modal', { url: elem.src });
                };
                // Check if video and if so replace by video element
                this.convertToVideo(elem, elem.src, elem.src);
            });
            markdown.querySelectorAll('a').forEach(elem => {
                elem.target = '_blank';
                if (this.handleApiAttachment(elem)) return;
                if (this.handleGitlabAttachment(elem)) return;
            });
        }
    }

    handleApiAttachment(elem) {
        const link = elem.href;
        const apiMatch = link.match(ATTACHMENT_URL_REGEX);
        if (!apiMatch) return false;
        console.assert(apiMatch.length === 4, 'wrong apiMatchLength %s', apiMatch);
        const projectId = Number(apiMatch[1]);
        const issueId = Number(apiMatch[2]);
        const attachmentId = Number(apiMatch[3]);
        const filename = elem.innerText;
        elem.href = '#';
        elem.addEventListener('click', async e => {
            e.preventDefault();
            e.stopPropagation();
            const blob = await attachments.download(projectId, issueId, attachmentId);
            presentDownload(blob, filename);
        });

        // Check if video and if so replace by video element
        this.convertToVideo(elem, link, filename);
        return true;
    }
    handleGitlabAttachment(elem) {
        const link = elem.href;
        const gitlabMatch = link.match(GITLAB_ATTACHMENT_URL_REGEX);
        if (!gitlabMatch) return false;
        console.assert(gitlabMatch.length === 3, 'wrong gitlabMatch %s', gitlabMatch);
        const projectId = this.project_id;
        const hash = gitlabMatch[1];
        const filename = gitlabMatch[2];
        elem.href = '#';
        elem.addEventListener('click', async e => {
            e.preventDefault();
            e.stopPropagation();
            const blob = await attachments.downloadFromGitlab(projectId, hash, filename);
            presentDownload(blob, filename);
        });

        // Check if video and if so replace by video element
        this.convertToVideo(elem, link, filename);
        return true;
    }

    convertToVideo(e, videosrc, filename) {
        // Declare a list of extensions supported for video files
        let extensions = ['.mp4', '.mov', '.wmv', '.avi', '.mkv'];

        // If a supported extension is found, convert it to a video file
        if (extensions.includes(filename.slice(-4))) {
            let vid = document.createElement('video');
            vid.controls = 'controls';
            vid.src = videosrc;
            e.replaceWith(vid);
        }
    }
}
</script>
