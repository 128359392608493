<template>
    <div class="row">
        <div class="col-12 col-md-8 offset-md-1 col-lg-6 offset-lg-3">
            <div class="block">
                <h1>
                    {{ $t(isCreatingNew ? 'edit_label.title_create' : 'edit_label.title_edit') }}
                </h1>
                <div v-if="projects_loading">
                    <p class="alert">
                        {{ $t('edit_label.loading_project_data') }}
                    </p>
                </div>
                <form v-else @submit.prevent="save" class="form">
                    <p class="alert--error" v-if="errorCode">
                        {{ localizedErrorCode }}
                    </p>
                    <div class="form__fields">
                        <div class="form__field">
                            <label class="form__label">{{ $t('edit_label.field_project') }}</label>
                            <input type="text" readonly :value="project.name" />
                        </div>
                        <div class="form__field">
                            <label class="form__label">{{ $t('edit_label.field_name') }}</label>
                            <input
                                type="text"
                                v-model="label.name"
                                required
                                :placeholder="$t('edit_label.placeholder_name')"
                            />
                        </div>
                        <div class="form__field">
                            <label class="form__label">{{ $t('edit_label.field_color') }}</label>
                            <swatches v-model="label.color" colors="text-advanced" />
                        </div>
                        <div class="form__field">
                            <label>{{ $t('edit_label.title_example') }}</label>
                            <project-label-badge :color="label.color">
                                {{ exampleText }}
                            </project-label-badge>
                        </div>
                    </div>
                    <router-link :to="backRoute" :disabled="saving" class="button--alt pull-left">
                        {{ $t('general.back') }}
                    </router-link>
                    <button
                        type="submit"
                        :disabled="saving"
                        class="button--cta pull-right"
                        :class="{ loading: saving }"
                    >
                        {{ $t('general.save') }}
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import { ReactiveRouteParam } from '@/mixins/reactive-route-param';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import Swatches from 'vue-swatches';
import { PROJECTS } from '@/store/projects';
import ProjectLabelBadge from '@/components/general/ProjectLabelBadge';
import {
    ERROR_CODES,
    getNetworkErrorCode,
    localizeNetworkErrorCode,
} from '@/api/util/network-errors';
import { ProjectLabel } from '@/api/modules/projects';

@Component({
    components: {
        ProjectLabelBadge,
        Swatches,
    },
    mixins: [ProjectIdMixin, ProjectsStoremap],
})
export default class ProjectLabelsPage extends Vue {
    @ReactiveRouteParam({
        deserialize: string => (string === 'new' ? 'new' : parseInt(string)),
    })
    labelId;

    get isCreatingNew() {
        return this.labelId === 'new';
    }

    /**
     * @returns {Project}
     */
    get project() {
        return this.projects.find(project => project.id === this.project_id);
    }

    @Watch('labelId')
    projects_onLoaded() {
        if (this.projects_loading) return; // Projects are still loading, the mixin will call this function then again
        if (this.isCreatingNew) {
            this.editLabel(new ProjectLabel(this.project));
        } else {
            const projectLabel = this.project.labels.find(label => label.id === this.labelId);
            this.editLabel(projectLabel);
        }
    }

    /**
     * @type {ProjectLabel}
     */
    label = null;

    /**
     * @param {ProjectLabel} instance
     */
    editLabel(instance) {
        console.log('editlabel', instance);
        this.label = instance.getEditableClone();
        this.labelId = instance.id || 'new';
    }

    saving = false;
    errorCode = null;

    async save() {
        this.saving = true;
        this.errorCode = null;
        const action = this.isCreatingNew ? PROJECTS.ADD_LABEL : PROJECTS.UPDATE_LABEL;
        try {
            const storedLabel = await this.$store.dispatch(action, {
                project: this.project,
                label: this.label,
            });
            this.editLabel(storedLabel);
            this.$router.push(this.backRoute);
        } catch (error) {
            this.errorCode = getNetworkErrorCode(error);
            throw error;
        } finally {
            this.saving = false;
        }
    }

    get backRoute() {
        return {
            name: 'Project',
            params: {
                project_id: this.project_id,
            },
        };
    }

    get exampleText() {
        return this.label.name || this.$t('edit_label.example');
    }

    get localizedErrorCode() {
        switch (this.errorCode) {
            case ERROR_CODES.NOT_FOUND:
                return this.$t('edit_label.label_disappeared');
            case ERROR_CODES.CONFLICT:
                return this.$t('edit_label.name_conflict');
            default:
                return localizeNetworkErrorCode(this.errorCode);
        }
    }
}
</script>
