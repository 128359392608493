<template>
    <button v-if="!component || !toggles.length" class="button col-12" disabled>
        No toggles
    </button>
    <div v-else class="row">
        <div v-for="toggle in toggles" :key="toggle.label" class="col-12 col-md-6">
            <div class="checkbox">
                <input
                    :id="'toggle-' + toggle.label"
                    type="checkbox"
                    v-model="toggle.state"
                    class="checkbox__cb"
                />
                <label :for="'toggle-' + toggle.label" class="checkbox__label">
                    {{ toggle.label }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getDevbarOptions } from '@/devbar/decorators';

@Component
export default class TogglesTab extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, default: null })
    component;

    /**
     * @returns {Array<ComponentDebugToggle>}
     */
    get toggles() {
        if (!this.component) return [];
        const data = getDevbarOptions(this.component);
        if (!data) return [];
        return data.toggles.map(toggle => toggle.bind(this.component));
    }
}
</script>
