<template>
    <div class="sorter">
        <vue-multiselect
            :options="options"
            v-model="pickedOption"
            label="label"
            :placeholder="placeholder"
            class="multiselect--alt"
        />
        <div class="checkbox button--theme">
            <input :id="inputId" type="checkbox" class="checkbox__cb" v-model="pickedOrder" />
            <label :for="inputId" class="checkbox__label">
                <i class="mdi" :class="iconClass"></i>
            </label>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ModelProxy } from '@/mixins/model-proxy-decorator';
import VueMultiselect from 'vue-multiselect';

/**
 * @typedef {{label: String}} SortOrderOption
 * @typedef {{option: SortOrderOption, ascending: boolean}} SortOrderSelection
 */

@Component({
    components: { VueMultiselect },
})
export default class SortOrderDropdown extends Vue {
    /** @type {SortOrderOption[]} */
    @Prop({ type: Array, default: () => [], required: true })
    options;

    /** @type {SortOrderSelection} */
    @ModelProxy({ type: Object, default: () => ({ option: null, ascending: true }) })
    model;

    get pickedOption() {
        const model = this.model;
        return model ? model.option : null;
    }
    set pickedOption(value) {
        this.model = {
            ...this.model,
            option: value,
        };
    }

    get pickedOrder() {
        const model = this.model;
        return model ? model.ascending : true;
    }
    set pickedOrder(value) {
        this.model = {
            ...this.model,
            ascending: value,
        };
    }

    @Prop({ type: String })
    placeholder;

    get inputId() {
        return 'sort-order-dropdown-' + this._uid;
    }

    get iconClass() {
        return this.pickedOrder ? 'mdi-sort-ascending' : 'mdi-sort-descending';
    }
}
</script>
