<template>
    <vue-multiselect
        @search-change="searchProject"
        v-model="model"
        :options="filteredProjects"
        track-by="id"
        :custom-label="getProjectLabel"
        :allow-empty="false"
        :searchable="true"
        :internal-search="false"
        :disabled="disabled"
        :placeholder="$t('general.select_project')"
    >
        <template slot="noResult">
            {{ $t('general.not_found') }}
        </template>
    </vue-multiselect>
</template>
<script>
import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { fusePromise } from '@/fuse';
import { ALL_PROJECTS } from '@/mixins/project-id-route';
import { PROJECTS } from '@/store/projects';
import { ProjectsStoremap } from '@/mixins/projects-storemap';

const MODEL_EVENT = 'input';

@Component({
    components: {
        VueMultiselect,
    },
    mixins: [ProjectsStoremap],
})
export default class ProjectsDropdown extends Vue {
    @Prop({ type: Boolean, default: true })
    allowAll;
    @Prop({ type: Boolean, default: false })
    disabled;
    @Model(MODEL_EVENT)
    value;

    Fuse = null;

    get model() {
        const projectId = this.value;
        if (!projectId || projectId === ALL_PROJECTS) {
            return this.allowAll ? this.allProjectsInstance : null;
        }
        return this.$store.getters[PROJECTS.GET_BY_ID](projectId);
    }

    set model(project) {
        const emitValue = project.id === ALL_PROJECTS ? null : project.id;
        this.$emit(MODEL_EVENT, emitValue);
    }

    query = '';

    searchProject(query) {
        this.query = query;
    }

    created() {
        fusePromise.then(Fuse => {
            this.Fuse = Fuse;
        });
    }

    get fuse() {
        if (!this.Fuse) return undefined;
        return new this.Fuse(this.projects, {
            keys: ['name'],
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
        });
    }

    get allProjectsInstance() {
        return Object.freeze({
            id: ALL_PROJECTS,
            name: this.$t('common.all_projects'),
        });
    }

    get filteredProjects() {
        if (this.query.length && this.fuse) {
            return this.fuse.search(this.query);
        } else if (this.allowAll) {
            return [this.allProjectsInstance, ...this.projects];
        } else {
            return this.projects.filter(project => project.can_create_issues);
        }
    }

    getProjectLabel(project) {
        if (project) {
            return project.name;
        } else if (this.projects_loading) {
            // If the project is unknown and the list of projects is still being fetched, then display an empty string.
            if (this.projects_loading) return this.$t('projects_dropdown.loading');
        } else {
            // Otherwise the project is truly not known and display the appropriate string.
            return this.$t('projects_dropdown.unknown');
        }
    }
}
</script>
