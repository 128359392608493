<template>
    <ul class="pagination">
        <!--Todo: show a limited amount / range of pages (see design)-->
        <li v-if="model != 0">
            <a @click.prevent="model--" class="pagination__icon">
                <i class="mdi mdi-chevron-left"></i>
            </a>
        </li>
        <li v-for="page in pageCount" :key="page" :class="{ active: model === page - 1 }">
            <a @click.prevent="onClick(page - 1)">
                <slot name="page-item">{{ page }}</slot>
            </a>
        </li>
        <!--<li>-->
        <!--<span class="pagination__dots">...</span>-->
        <!--</li>-->
        <li v-if="model != pageCount - 1">
            <a @click.prevent="model++" class="pagination__icon">
                <i class="mdi mdi-chevron-right"></i>
            </a>
        </li>
    </ul>
</template>
<script>
import Vue from 'vue';
import { Component, Prop, Model } from 'vue-property-decorator';

const MODEL_EVENT = 'navigate';

@Component
export default class Pagination extends Vue {
    @Prop({ type: Number, default: 1 })
    pageCount;
    @Model(MODEL_EVENT, { type: [Number, Object], default: 0 })
    value;

    get model() {
        return this.value;
    }

    set model(value) {
        this.$emit(MODEL_EVENT, value);
    }

    onClick(page) {
        this.model = page;
    }
}
</script>
