<template>
    <vue-multiselect v-model="model" :options="options" :clearable="false" :searchable="false">
        <template slot="option" slot-scope="{ option }">
            {{ getLabel(option) }}
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            {{ getLabel(option) }}
        </template>
    </vue-multiselect>
</template>
<script>
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';

const MODEL_EVENT = 'input';
const VALUE_ALL = 0;

@Component({
    components: { VueMultiselect },
})
export default class SprintDropdown extends Vue {
    @Model(MODEL_EVENT, { type: Number })
    _value;

    get model() {
        return this._value || VALUE_ALL;
    }

    set model(value) {
        this.$emit(MODEL_EVENT, value);
    }

    get options() {
        return [0, 1, 2];
    }

    get sprintLabels() {
        return [
            this.$t('common.issue_state.any_sprint'),
            this.$t('common.issue_state.this_sprint'),
            this.$t('common.issue_state.next_sprint'),
        ];
    }

    getLabel(option) {
        return this.sprintLabels[option] || option;
    }
}
</script>
