<template>
    <div class="block">
        <h2>{{ $t('attachments_list.title') }}</h2>
        <p>{{ subtitle }}</p>
        <upload-queue :issue="issue" :auto-upload="editMode" :delayed="!editMode" ref="queue" />
        <div>
            <issue-attachment
                v-for="attachment in issue.files"
                :key="attachment.id"
                :issue="issue"
                :attachment="attachment"
            />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import IssueAttachment from '@/components/general/IssueAttachment';
import UploadQueue from '@/components/attachments/UploadQueue';

@Component({
    components: { UploadQueue, IssueAttachment },
})
export default class AttachmentsList extends Vue {
    @Prop({ type: Object, default: null })
    issue;
    @Prop({ type: Boolean, default: false })
    editMode;

    get isNew() {
        return this.issue && !this.issue.id;
    }

    get subtitle() {
        if (this.issue.state === 'closed') {
            return this.$t('attachments_list.no_upload_issue_closed');
        } else if (this.isNew) {
            return this.$t('attachments_list.upload_on_save');
        } else {
            return this.$t('attachments_list.upload_automatically');
        }
    }

    async startQueue(issue) {
        if (this.$refs.queue) {
            await this.$refs.queue.startQueue(issue);
        }
    }
}
</script>
