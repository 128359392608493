// This file conditionally loads a fuse if the browser is not IE, otherwise we provide an alternative implementation
// The fuse.js package cannot be loaded on IE due to the way its distribution has been compiled.
// But the fuzzy search it provides is really good so there is this small work around to provide IE with an alternative

export const fusePromise = Promise.resolve().then(() => {
    const ua = window.navigator.userAgent;
    const isInternetExplorer = ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
    if (isInternetExplorer) {
        console.log('[fuse] loading ie-alternative.js');
        return import('./ie-alternative').then(module => module.default);
    } else {
        console.log('[fuse] loading fuse.js');
        return require('fuse.js');
    }
});
