import { ERROR_CODES, getNetworkErrorCode } from '@/api/util/network-errors';
import { ALL_PROJECTS } from '@/mixins/project-id-route';
import { projectsApi } from '@/api/modules/projects';

const namespace = 'projects/';

export const PROJECTS = Object.freeze({
    SET_FETCHING: namespace + 'set_fetching',
    SET_PROJECTS: namespace + 'set_projects',
    FETCH: namespace + 'fetch',
    GET_ALL: namespace + 'get_all',
    GET_BY_ID: namespace + 'get_by_id',
    ADD_LABEL: namespace + 'add_label',
    UPDATE_LABEL: namespace + 'edit_label',
    DELETE_LABEL: namespace + 'delete_label',
    IS_MONO: namespace + 'is_mono',
    GET_MONO_PROJECT_ID: namespace + 'get_mono_project_id',
    UNLOAD: namespace + 'unload',
});

const STORAGEKEY_MONO_PROJECT = 'mono-project-id';

function loadMonoProjectId() {
    try {
        const monoString = localStorage.getItem(STORAGEKEY_MONO_PROJECT);
        if (monoString) {
            const projectId = Number(monoString);
            if (Number.isInteger(projectId) && !Number.isNaN(projectId)) {
                return projectId;
            }
        }
    } catch {
        // empty on purpose
    }
    return null;
}

function saveMonoProjectId(id) {
    if (Number.isInteger(id) && !Number.isNaN(id) && id !== ALL_PROJECTS) {
        localStorage.setItem(STORAGEKEY_MONO_PROJECT, id.toString());
    } else {
        localStorage.removeItem(STORAGEKEY_MONO_PROJECT);
    }
}

export default {
    state: () => {
        return {
            projects: [],
            fetchPromise: null,
            // For isMono we use localStorage to reduce the amount of flickering of the UI elements there will be.
            // It is likely for the same user to login rather than the login changing over time.
            monoProjectId: loadMonoProjectId(),
        };
    },
    mutations: {
        [PROJECTS.SET_FETCHING]: (state, promise) => {
            state.fetchPromise = promise;
        },
        [PROJECTS.SET_PROJECTS]: (state, projects) => {
            state.projects = projects;
            if (projects.length === 1) {
                state.monoProjectId = projects[0].id;
                saveMonoProjectId(state.monoProjectId);
            } else {
                state.monoProjectId = null;
                saveMonoProjectId(null);
            }
        },
        [PROJECTS.ADD_LABEL]: (state, { label }) => {
            const project = label.project;
            const storedProject = state.projects.find(p => p.id === project.id);
            console.assert(
                storedProject,
                '[store] [projects] trying to add label to missing project',
            );
            storedProject.insertLabel(label);
        },
        [PROJECTS.UPDATE_LABEL]: (state, { label }) => {
            const project = label.project;
            const storedProject = state.projects.find(p => p.id === project.id);
            console.assert(
                storedProject,
                '[store] [projects] trying to update label for missing project',
            );
            storedProject.replaceLabel(label);
        },
        [PROJECTS.DELETE_LABEL]: (state, { label }) => {
            const project = label.project;
            const storedProject = state.projects.find(p => p.id === project.id);
            console.assert(
                storedProject,
                '[store] [projects] trying to delete label from missing project',
            );
            storedProject.removeLabel(label);
        },
        [PROJECTS.UNLOAD]: state => {
            state.projects = [];
            state.fetchPromise = null;
            state.monoProjectId = null;
        },
    },
    actions: {
        [PROJECTS.FETCH]: ({ state, commit }, { forceRefresh = false } = {}) => {
            // If we are already fetching (or have fetched the projects) then return the promise for that fetch
            // Unless we are force refreshing then pretend no promise is set yet
            const alreadyFetchedPromise = forceRefresh ? null : state.fetchPromise;
            if (alreadyFetchedPromise) {
                return alreadyFetchedPromise;
            }
            // No successful fetch yet, create a new promise that fetches the projects
            const promise = projectsApi.projects
                .get()
                .then(projects => {
                    commit(PROJECTS.SET_PROJECTS, projects);
                    return projects;
                })
                .catch(error => {
                    // On error, clear the fetching promise from the store and propagate the error
                    console.warn('[store] [projects] Fetch failed', error);
                    commit(PROJECTS.SET_FETCHING, null);
                    return Promise.reject(error);
                });
            commit(PROJECTS.SET_FETCHING, promise);
            return promise;
        },
        [PROJECTS.ADD_LABEL]: async ({ commit }, { label }) => {
            const newLabel = await projectsApi.labels.create(label);
            commit(PROJECTS.ADD_LABEL, { label: newLabel });
            return newLabel;
        },
        [PROJECTS.UPDATE_LABEL]: async ({ commit }, { label }) => {
            try {
                const updatedLabel = await projectsApi.labels.update(label);
                commit(PROJECTS.UPDATE_LABEL, { label: updatedLabel });
                return updatedLabel;
            } catch (error) {
                if (getNetworkErrorCode(error) === ERROR_CODES.NOT_FOUND) {
                    commit(PROJECTS.DELETE_LABEL, { label });
                }
                throw error;
            }
        },
        [PROJECTS.DELETE_LABEL]: async ({ commit }, { label }) => {
            try {
                await projectsApi.labels.destroy(label);
                commit(PROJECTS.DELETE_LABEL, { label });
            } catch (error) {
                if (getNetworkErrorCode(error) === ERROR_CODES.NOT_FOUND) {
                    commit(PROJECTS.DELETE_LABEL, { label });
                }
            }
        },
    },
    getters: {
        [PROJECTS.GET_ALL]: state => state.projects,
        [PROJECTS.GET_BY_ID]: state => id => state.projects.find(project => project.id === id),
        [PROJECTS.GET_MONO_PROJECT_ID]: state => state.monoProjectId,
        [PROJECTS.IS_MONO]: (state, getters) => Boolean(getters[PROJECTS.GET_MONO_PROJECT_ID]),
    },
};
