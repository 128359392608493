import { createDecorator } from 'vue-class-component';
import {
    TemplateDebugAction,
    TemplateDebugToggle,
    TemplateDebugToggleComputed,
} from '@/devbar/debugview';
import { componentIsExternal } from '@/devbar/component-info';

/**
 * @typedef {Object} BadgeConfig
 * @property {string} icon
 * @property {string} text
 * @property {string} color
 * @property {string} backgroundColor
 */

/**
 * @typedef {Function} BadgeCallback
 * @param {Vue} component
 * @returns {BadgeConfig}
 */

/**
 * Define the DevbarOptions type so auto-complete is useful for interacting with devbar component settings
 * @typedef {Object} DevbarOptions
 * @property {Array<TemplateDebugAction>} actions
 * @property {Array<TemplateDebugToggle>} toggles
 * @property {boolean} nosnipe
 * @property {Array<BadgeCallback>} badges
 */

/**
 * @param {ComponentOptions<Vue>} component
 * @returns {DevbarOptions}
 */
function initDevbarStorage(component) {
    if (!component.devbarOptions) {
        component.devbarOptions = {
            actions: [],
            toggles: [],
            nosnipe: false,
            badges: [],
        };
    }
    return component.devbarOptions;
}

/**
 * Gets the object that holds all the devbar data for the component
 * @param {Vue} component
 * @returns {?DevbarOptions}
 */
export function getDevbarOptions(component) {
    return component.$options.devbarOptions;
}

export function DebugAction({ label } = {}) {
    return createDecorator((component, key) => {
        const method = component.methods[key];
        console.assert(method, 'DebugAction must be applied to a method');
        const devbar = initDevbarStorage(component);
        devbar.actions.push(
            new TemplateDebugAction({
                name: label || method.name,
                method,
            }),
        );
    });
}

export function DebugToggle({ label, on = true, off = false } = {}) {
    return createDecorator((component, key) => {
        const devbar = initDevbarStorage(component);
        devbar.toggles.push(
            new TemplateDebugToggle({
                label: label || key,
                property: key,
                on,
                off,
            }),
        );
    });
}

export function DebugToggleComputed({ label, factory }) {
    return createDecorator((component, key) => {
        const devbar = initDevbarStorage(component);
        const computed = component.computed[key];
        computed.toggleOverrideCount = computed.toggleOverrideCount || 0;
        const id = computed.toggleOverrideCount++;
        const originalComputer = computed.get;
        console.assert(computed, 'DebugToggleComputed must be applied to a computed property');
        const overrideKey = `devbar__computedToggle__${key}`;
        // Override the getter with a function that will return the value override if set
        computed.get = function DebugToggleWrapper() {
            if (this[overrideKey] === id) {
                return factory.call(this, arguments[0]);
            } else {
                return originalComputer.call(this, arguments[0]);
            }
        };
        // Add a mixin to merge the new override key into the $data structure and have it be reactive from the start
        component.mixins.push({
            data: () => ({ [overrideKey]: -1 }),
        });
        // Add the toggle that is able to control the override value
        devbar.toggles.push(
            new TemplateDebugToggleComputed({
                label,
                key: overrideKey,
                id,
                factory,
            }),
        );
    });
}

export function DebugNoSnipe(something) {
    console.assert(something.__decorators__, 'Use decorator after @Component');
    something.__decorators__.push(component => {
        const devbar = initDevbarStorage(component);
        devbar.nosnipe = true;
    });
    return something;
}

function badgeCallbackWrapper(callback, vm) {
    try {
        let result = callback.call(vm, vm);
        if (typeof result === 'string') {
            result = {
                text: result,
            };
        }
        return result;
    } catch (e) {
        console.error('Badge error:', e);
    }
}

/**
 * @param {function(Vue):BadgeConfig} callback
 */
export function DebugBadge(callback) {
    return something => {
        something.__decorators__.push(component => {
            const devbar = initDevbarStorage(component);
            const wrappedCallback = badgeCallbackWrapper.bind(callback, callback);
            devbar.badges.push(wrappedCallback);
        });
    };
}

const globalBadges = [];

/**
 * @param {function(Vue):BadgeConfig} callback
 */
export function registerGlobalBadge(callback) {
    globalBadges.push(callback);
}

/**
 * @param {DevbarOptions} devbarOptions
 * @returns {BadgeCallback[]}
 */
export function getBadges(devbarOptions) {
    if (devbarOptions && devbarOptions.badges) {
        return globalBadges.concat(devbarOptions.badges);
    } else {
        return globalBadges;
    }
}

registerGlobalBadge(vm => {
    if (!vm.$children.length) return;
    return {
        icon: 'mdi-human-child',
        text: vm.$children.length,
    };
});

registerGlobalBadge(vm => {
    if (!componentIsExternal(vm)) return;
    return {
        icon: 'mdi-npm-variant-outline',
        text: null,
        color: '#cb3837',
        backgroundColor: 'white',
    };
});

registerGlobalBadge(vm => {
    const options = getDevbarOptions(vm);
    if (options && options.actions.length) {
        return {
            icon: 'mdi-flash',
            text: options.actions.length,
        };
    }
});

registerGlobalBadge(vm => {
    const options = getDevbarOptions(vm);
    if (options && options.toggles.length) {
        return {
            icon: 'mdi-toggle-switch',
            text: options.toggles.length,
        };
    }
});

registerGlobalBadge(vm => {
    const matched = vm.$route.matched.find(m => m.instances.default === vm);
    if (matched) {
        return {
            icon: 'mdi-router-wireless',
            text: matched.name || matched.path,
        };
    }
});

registerGlobalBadge(vm => {
    // Verify it is vue-router
    if (vm.$options.name === 'RouterLink') {
        const match = vm.$router.resolve(vm.to);
        if (match) {
            return {
                icon: 'mdi-link-variant',
                text: match.location.name || match.location.path,
            };
        }
    }
});

registerGlobalBadge(vm => {
    // Verify that its portal-vue
    if (vm.$options.name === 'portalTarget' && vm.ownTransports && vm.passengers) {
        return {
            icon: 'mdi-server-network',
            text: vm.name,
        };
    }
});
