<template>
    <router-link :to="linkIssueView" class="button--alt">
        <i class="mdi" :class="listView ? 'mdi-view-week' : 'mdi-view-list'"></i>
        {{ $t(listView ? 'general.board_view' : 'general.list_view') }}
    </router-link>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class IssueViewLinks extends Vue {
    get listView() {
        return this.$route.name === 'Issues';
    }

    get linkIssueView() {
        const result = {
            name: this.listView ? 'ScrumBoard' : 'Issues',
            params: {
                project_id: this.$route.params.project_id,
            },
        };
        if (this.$route.query.search && this.$route.query.search.length) {
            result.query = {
                search: this.$route.query.search,
            };
        }
        return result;
    }
}
</script>
