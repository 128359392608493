<template>
    <div v-if="app">
        <div class="devbar" :class="{ 'devbar--minimized': !expanded }" :style="devbarStyle">
            <div class="devbar__resize" @mousedown="startResize" />
            <div class="devbar__header">
                <div v-if="focus" class="devbar__header__tabs">
                    {{ focus.$options.__file }}
                </div>
                <button class="devbar__header__minimize button--link" @click.prevent="toggle">
                    <i
                        class="mdi"
                        :class="expanded ? 'mdi-window-minimize' : 'mdi-window-maximize'"
                    ></i>
                </button>
            </div>
            <div v-if="focus" class="devbar__body">
                <div class="devbar__body__tree">
                    <component-tree :inspect="inspectRoot" />
                </div>
                <div class="devbar__body__details">
                    <component-tabs :component="focus" />
                </div>
            </div>
        </div>
        <highlighter :target="hover" />
    </div>
    <!-- An element is required, otherwise the $el becomes null after mount and it cannot be appended to the page -->
    <div v-else />
</template>
<style lang="scss">
@import 'devbar';

$devbar-height: 40rem;
$devbar-resize-handle-size: 8px;
$devbar-resize-handle-overlap: 1px;

.devbar {
    /* Position the devbar on the bottom of the viewport and stretch it horizontally */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: $devbar-height;
    /* Force the devbar on top of other elements */
    z-index: 10000;

    /* Setup basic coloring */
    color: $color-theme;
    background-color: $color-default;
    border-top: 1px solid $color-border-dark;

    /* Setup the toolbox as a flexbox so the content will be scrollable */
    display: flex;
    flex-direction: column;

    @include element(resize) {
        left: 0;
        right: 0;
        position: absolute;
        top: $devbar-resize-handle-overlap - $devbar-resize-handle-size; /* 1px offset to overlap with the border */
        height: $devbar-resize-handle-size;
        background: transparent;
        cursor: ns-resize;
        z-index: 9;
    }

    @include element(header) {
        /* Use flexbox to grow the tabs automatically and shove the minimize button to the right */
        display: flex;

        @include element(tabs) {
            flex: 1;
            padding: $indent-xs $indent-1;
        }
        @include element(minimize) {
            padding: 0;
        }
    }

    @include element(body) {
        flex-grow: 1;

        border-top: 1px solid $color-border-dark;

        display: flex;
        flex-direction: row;
        min-height: 0; /* For firefox */

        @include element(tree) {
            flex: 1 1 50%;
            max-width: 50%;
            overflow: auto;
            min-height: 0; /* For firefox */
            border-right: 1px solid $color-border-dark;
        }
        @include element(details) {
            flex: 1 1 50%;
            max-width: 50%;
            overflow: auto;
            min-height: 0; /* For firefox */
        }
    }

    &--minimized {
        left: auto;
        height: auto;
        border-left: 1px solid $color-border-dark;
        padding-left: $indent-1;
        .devbar__header .devbar__header__tabs {
            display: none;
        }
        .devbar__body {
            display: none;
        }
    }
}

body.devbar-active {
    margin-bottom: $devbar-height;
}
</style>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import ComponentTree from '@/devbar/tree/ComponentTree';
import ActionsTab from '@/devbar/tabs/ActionsTab';
import TogglesTab from '@/devbar/tabs/TogglesTab';
import Highlighter from '@/devbar/highlighter/Highlighter';
import ComponentTabs from '@/devbar/tabs/ComponentTabs';

const STORAGEKEY_DEVBAR_EXPANDED = 'elnino.devbar.expanded';
const STORAGEKEY_DEVBAR_HEIGHT = 'elnino.devbar.height';
const DEVBAR_HEIGHT_DEFAULT = 300;
const DEVBAR_HEIGHT_MIN = 50;

@Component({
    components: {
        ComponentTabs,
        Highlighter,
        TogglesTab,
        ActionsTab,
        ComponentTree,
        VueMultiselect,
    },
})
export default class Devbar extends Vue {
    /** @member {Vue} */
    app = null;

    mounted() {
        console.assert(this.$options.app, 'Missing app instance');
        this.app = this.$options.app;
        try {
            this.height = parseInt(localStorage.getItem(STORAGEKEY_DEVBAR_HEIGHT));
        } catch (e) {
            this.height = DEVBAR_HEIGHT_DEFAULT;
        }
        if (MutationObserver) {
            this.observer = new MutationObserver(this.onDomMutation.bind(this));
        }
    }

    /**
     * @returns {Vue}
     */
    get inspectRoot() {
        if (this.app) {
            return this.app.$root.$children[0];
        } else {
            return null;
        }
    }

    // Region: Focus and Hover tracking

    /** @member {Vue} */
    focusInternal = null;
    /** @member {Vue} */
    hoverInternal = null;

    get hover() {
        return this.expanded ? this.hoverInternal || this.focus : null;
    }

    set hover(value) {
        this.hoverInternal = value;
    }

    /**
     * @returns {Vue}
     */
    get focus() {
        return this.focusInternal || this.inspectRoot;
    }

    /**
     * @param {Vue} value
     */
    set focus(value) {
        this.focusInternal = value;
    }

    /** @member {MutationObserver} */
    observer = null;

    @Watch('observerTargets')
    onObserverTargetsChanged() {
        if (this.observer) {
            // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserverInit
            const config = {
                subtree: true,
                childList: true,
            };
            this.observer.disconnect();
            this.observerTargets.forEach(target => this.observer.observe(target, config));
        }
    }

    get observerTargets() {
        const result = [];
        if (this.app) result.push(this.app.$el);
        if (this.focus) result.push(this.focus.$el);
        if (this.hover) result.push(this.hover.$el);
        return result;
    }

    onDomMutation(mutations) {
        this.$emit('dom-mutation', mutations);
    }

    // Region: Minimize feature

    /** @member {boolean} */
    expanded = localStorage.getItem(STORAGEKEY_DEVBAR_EXPANDED) === 'true';

    @Watch('expanded', { immediate: true })
    onExpandedChanged(value) {
        localStorage.setItem(STORAGEKEY_DEVBAR_EXPANDED, value);
    }

    @Watch('expanded')
    @Watch('height')
    updateBodyMargin() {
        if (this.expanded) {
            document.body.style.marginBottom = `${this.height}px`;
        } else {
            document.body.style.marginBottom = '';
        }
    }

    toggle() {
        this.expanded = !this.expanded;
    }

    // Region: Resize feature

    internalHeight = DEVBAR_HEIGHT_DEFAULT;

    get height() {
        return this.internalHeight;
    }

    set height(value) {
        console.assert(typeof value === 'number', 'height must be a number');
        if (isNaN(value)) return;
        this.internalHeight = value;
        localStorage.setItem(STORAGEKEY_DEVBAR_HEIGHT, value.toString());
    }

    get devbarStyle() {
        if (this.expanded) {
            return {
                height: `${Math.max(this.height, DEVBAR_HEIGHT_MIN)}px`,
            };
        } else {
            return undefined;
        }
    }

    resizing = false;
    listenerMouseMove = null;
    listenerMouseUp = null;

    startResize(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.resizing) {
            this.listenerMouseMove = e => this.onResizeMouseMove(e);
            this.listenerMouseUp = e => this.onResizeMouseUp(e);
            document.addEventListener('mousemove', this.listenerMouseMove, true);
            document.addEventListener('mouseup', this.listenerMouseUp, true);
            this.resizing = true;
        }
    }

    onResizeMouseMove(e) {
        e.preventDefault();
        e.stopPropagation();
        this.height -= e.movementY;
    }

    onResizeMouseUp(e) {
        e.preventDefault();
        e.stopPropagation();
        this.endResize();
    }

    endResize() {
        if (this.resizing) {
            document.removeEventListener('mousemove', this.listenerMouseMove, true);
            document.removeEventListener('mouseup', this.listenerMouseUp, true);
            this.resizing = false;
        }
    }
}
</script>
