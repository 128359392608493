<template>
    <div>
        <h5>
            {{ title }}
            <span>({{ items.length }})</span>
            <a v-if="canExpand" @click.prevent="toggle">{{ toggleText }}</a>
        </h5>
        <slot name="item" v-for="item in visibleItems" :item="item" />
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ShowMoreList extends Vue {
    @Prop({ type: String })
    title;
    @Prop({ type: Array, default: [] })
    items;
    @Prop({ type: Number, default: 3 })
    threshold;

    expanded = false;

    toggle() {
        this.expanded = !this.expanded;
    }

    get toggleText() {
        return this.expanded ? this.$t('general.show_less') : this.$t('general.show_more');
    }

    get visibleItems() {
        const items = this.items;
        return this.expanded ? items : items.slice(0, this.threshold);
    }

    get canExpand() {
        return this.items.length > this.threshold;
    }
}
</script>
