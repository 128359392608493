import { featurebot } from './servers';
import { cleanBearerPrefix, loadServerToken } from './token-storage';
import router from '@/router/index';
import { ERROR_CODES, getNetworkErrorCode } from '@/api/util/network-errors';
import { AUTH } from '@/store/auth';
import store from '@/store/index';

// Redirect user to sign-in page when requests fail with unauthorized status
featurebot.interceptors.response.use(
    response => {
        // token needs to be refreshed after each request, otherwise user will be logged out after 30 minutes.
        const token = response.headers.authorization;
        if (token) {
            const cleanedToken = cleanBearerPrefix(token);
            store.commit(AUTH.UPDATE_TOKEN, cleanedToken);
        }
        return response.data;
    },
    error => {
        // Find error code and store it on the error object
        Object.defineProperty(error, 'errorCode', {
            get() {
                console.warn('The errorCode property is deprecated');
                return getNetworkErrorCode(this);
            },
        });
        if (error.errorCode === ERROR_CODES.UNKNOWN && error.response) {
            console.warn('unknown error code for response status', error.response.status);
        }
        // If there is an authentication error then bounce to login page
        const isAuthenticationError = error && error.errorCode === ERROR_CODES.CREDENTIALS;
        const onAuthPage = router.currentRoute.name === 'sign-in';
        if (isAuthenticationError && !onAuthPage) {
            store.commit(AUTH.SET_USER, null);
            router.replace({
                name: 'sign-in',
            });
        }
        return Promise.reject(error);
    },
);

// Add a request interceptor
featurebot.interceptors.request.use(request => {
    // Inject authorization token if present
    const token = loadServerToken(featurebot);
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    // Override locale if present
    const lang = window.localStorage.getItem('lang');
    if (lang) {
        request.headers['Accept-Language'] = lang;
    }
    return request;
});
