<!--suppress JSMethodCanBeStatic -->
<template>
    <div class="table__responsive">
        <table class="table--mobile table--striped">
            <thead>
                <tr class="table__row">
                    <th v-for="column in columns" :key="column.apiColumn">
                        {{ column.header }}
                    </th>
                    <th class="table__cell--icon"></th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr class="table__row--spinner">
                    <td class="table__cell--spinner" colspan="10000">
                        <div class="spinner"></div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="!issues.length">
                <tr class="table__row--no_results">
                    <td colspan="10000" class="table__cell--center table__cell--no_results">
                        <span class="table__no_results">{{ $t('dashboard.no_issues') }}</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr
                    v-for="issue in issues"
                    :key="issue.id"
                    @click="view(issue)"
                    :class="styleIssueRow(issue)"
                >
                    <td
                        v-for="column in columns"
                        :key="column.apiColumn"
                        :class="getDisplayClass(column, issue)"
                        v-tooltip.left="column.displayTooltip ? column.displayTooltip(issue) : null"
                    >
                        <span class="table__cell__content">
                            <span v-html="column.displayValue(issue)"></span>
                            <!--Todo: show icon with tooltip in case of passed deadline or estimated time
                                Example text in case of deadline passed: '1d 5h from deadline'
                                Example text in case of estimated time passed: '10 hours more than estimated'
                            -->
                            <!--<span v-tooltip.left="'1w 2d from deadline'"-->
                            <!--class="table__cell__content__icon">-->
                            <!--<i class="mdi mdi-alert-circle-outline"></i>-->
                            <!--</span>-->
                        </span>
                    </td>
                    <td @click.stop="edit(issue)">
                        <span
                            v-tooltip.left="$t('general.edit')"
                            class="table__icon mdi mdi-pencil"
                        ></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { MILESTONES } from '@/store/milestones';
import { SCRUMLABELS } from '@/store/scrumlabels';
import { localizeEnum, localizeWorktime } from '@/languages';

function createValidMoment(date) {
    const instance = moment(date);
    return instance.isValid() ? instance : undefined;
}

@Component({
    components: {},
})
export default class IssuesTable extends Vue {
    @Prop({ type: Array, default: () => [] })
    issues;
    @Prop({ type: Boolean, default: true })
    loading;

    get columns() {
        return [
            {
                apiColumn: 'title',
                header: this.$t('dashboard.name'),
                displayValue: issue => `${issue.title}<br />
<span class="is-muted">
#${issue.iid} -
${this.$t('dashboard.subtitle', {
    date: moment(issue.add_date)
        .utc()
        .format('LL'),
    author: issue.author_name || this.$t('dashboard.author_unknown'),
})}
</span>`,
                displayClass: () => 'table__cell--title',
            },
            {
                apiColumn: 'project_name',
                header: this.$t('dashboard.project'),
                displayValue: issue => issue.project.name,
                displayClass: () => 'table__cell--small_font',
            },
            {
                apiColumn: 'due_date',
                header: this.$t('dashboard.deadline'),
                displayValue: issue =>
                    issue.due_date ? moment(issue.due_date).format('LL') : undefined,
                displayClass: issue => {
                    const result = ['table__cell--deadline'];
                    if (issue.state === 'opened') {
                        const deadline = createValidMoment(issue.due_date);
                        if (deadline && deadline.isBefore()) {
                            result.push('table__cell--alert');
                        }
                    }
                    return result;
                },
                displayTooltip: issue => {
                    if (issue.state === 'opened') {
                        const deadline = createValidMoment(issue.due_date);
                        if (deadline && deadline.isBefore()) {
                            return this.$t('dashboard.tooltip_past_deadline', {
                                elapsed: deadline.fromNow(true),
                            });
                        }
                    }
                },
            },
            {
                apiColumn: 'milestone',
                header: this.$t('dashboard.sprint_start_end'),
                displayValue: issue => {
                    if (issue.milestone_id == null) {
                        return '';
                    }
                    const milestone = this.$store.getters[MILESTONES.GET_BY_ID](issue.milestone_id);
                    return milestone ? `${milestone.start}/${milestone.end}` : '';
                },
            },
            {
                apiColumn: 'spent_time',
                header: this.$t('dashboard.spent_time'),
                displayValue: issue => localizeWorktime(issue.spent_time || 0),
                displayClass: () => ['table__cell--small_font', 'table__cell--spent'],
            },
            {
                apiColumn: 'estimated_time',
                header: this.$t('dashboard.estimate'),
                displayValue: issue => localizeWorktime(issue.estimated_time),
                displayClass: issue => {
                    const result = ['table__cell--small_font', 'table__cell--estimate'];
                    const estimate = issue.estimated_time;
                    const spent = issue.spent_time;
                    if (estimate && spent && estimate < spent) {
                        result.push('table__cell--alert');
                    }
                    return result;
                },
                displayTooltip: issue => {
                    if (issue.state === 'opened') {
                        const estimate = issue.estimated_time;
                        const spent = issue.spent_time;
                        if (estimate && spent) {
                            if (estimate < spent) {
                                return this.$t('dashboard.tooltip_past_estimate', {
                                    worktime: localizeWorktime(spent - estimate),
                                });
                            } else {
                                return this.$t('dashboard.tooltip_remaining_estimate', {
                                    worktime: localizeWorktime(estimate - spent),
                                });
                            }
                        }
                    }
                },
            },
            {
                apiColumn: 'priority',
                header: this.$t('dashboard.priority'),
                displayValue: issue => issue.priority,
            },
            {
                apiColumn: 'state',
                header: this.$t('dashboard.state'),
                displayValue: issue => this.getStatus(issue),
                displayClass: issue =>
                    issue.state === 'opened' ? 'table__cell--success' : 'table__cell--closed',
            },
        ];
    }

    getStatus(issue) {
        var scrumlabel;
        if (issue.scrumlabels.length && issue.state !== 'closed') {
            scrumlabel = this.$store.getters[SCRUMLABELS.GET_BY_ID](issue.scrumlabels[0]);
        }
        return scrumlabel ? localizeEnum('common.scrum-labels', scrumlabel.name) : null;
    }

    created() {
        this.$store.dispatch(MILESTONES.FETCH);
        this.$store.dispatch(SCRUMLABELS.FETCH);
    }

    view(issue) {
        this.$emit('view', issue);
    }

    edit(issue) {
        this.$emit('edit', IssuesTable.clone(issue));
    }

    getDisplayClass(column, issue) {
        if (!column.displayClass) return undefined;
        if (typeof column.displayClass === 'string') return column.displayClass;
        return column.displayClass(issue);
    }

    styleIssueRow(issue) {
        if (issue.state !== 'opened') return 'table__row--closed';
    }

    static clone(obj) {
        if (null == obj || 'object' !== typeof obj) return obj;
        const copy = obj.constructor();
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }
}
</script>
