import SimpleMDE from 'vue-simplemde/src/index';

export default {
    extends: SimpleMDE,
    methods: {
        initialize() {
            SimpleMDE.methods.initialize.call(this, arguments);
            this.simplemde.codemirror.on('drop', (cm, e) => {
                e.preventDefault();
                e.stopPropagation();
                this.$emit('drop', e);
                return false;
            });
        },
        insertText(text) {
            const cm = this.simplemde.codemirror;
            const doc = cm.getDoc();
            // gets the line number in the cursor position
            const cursor = doc.getCursor();
            // If we are not at the start of the line then insert a space before the text
            if (cursor.ch > 0) {
                text = '\n' + text;
            }
            doc.replaceRange(text + '\n', cursor);
        },
    },
};
