<template>
    <span>
        <a :class="buttonClass" @click.prevent="openFileSelect" :disabled="disabled">
            <i v-if="iconClass" :class="iconClass" />
            {{ $t('general.upload-files') }}
        </a>
        <input style="display:none;" type="file" multiple ref="fileUpload" @change="selectFile" />
    </span>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DebugNoSnipe } from '@/devbar/decorators';

@Component
@DebugNoSnipe
export default class UploadButton extends Vue {
    @Prop({ type: Boolean, default: false })
    disabled;
    @Prop({ type: String, default: 'button--theme' })
    buttonClass;
    @Prop({ type: String, default: '' })
    iconClass;

    openFileSelect() {
        if (this.disabled) return;
        console.log(this.$refs);
        this.$refs.fileUpload.click();
    }

    selectFile(e) {
        if (this.disabled) return;
        const files = e.target.files || e.dataTransfer.files;
        Array.prototype.forEach.call(files, file => {
            if (this.canUpload(file)) {
                this.$emit('upload', file);
            } else {
                console.log('unable to upload, file too large', file);
                // TODO: Show error notification to user
            }
        });
        e.target.value = '';
    }

    canUpload(file) {
        const maxUploadBytes = this.maxUploadBytes;
        if (maxUploadBytes < 0) return true;
        return file.size <= maxUploadBytes;
    }

    get maxUploadBytes() {
        if (this.$config && this.$config.attachments && this.$config.attachments.maxUploadSize) {
            return this.$config.attachments.maxUploadSize;
        } else {
            return -1;
        }
    }
}
</script>
