<template>
    <button v-if="!component || !actions.length" class="button col-12" disabled>
        No actions
    </button>
    <div v-else class="row">
        <button
            v-for="(action, index) in actions"
            :key="index"
            @click.prevent="action.execute()"
            class="button--alt col-12 col-md-6"
        >
            {{ action.name }}
        </button>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getDevbarOptions } from '@/devbar/decorators';

@Component
export default class ActionsTab extends Vue {
    /** @member {Vue} */
    @Prop({ type: Vue, default: null })
    component;

    /**
     * @returns {Array<ComponentDebugAction>}
     */
    get actions() {
        if (!this.component) return [];
        const data = getDevbarOptions(this.component);
        if (!data) return [];
        return data.actions.map(action => action.bind(this.component));
    }
}
</script>
