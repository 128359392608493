import { featurebot } from '@/api/util/servers';
import { authApi, UserSession } from '@/api/modules/auth';
import { loadServerToken, saveServerToken } from '@/api/util/token-storage';
import { PROJECTS } from '@/store/projects';

const namespace = 'auth/';

export const AUTH = Object.freeze({
    SET_USER: namespace + 'set_user',
    LOGIN: namespace + 'login',
    SSO: namespace + 'sso',
    LOGOUT: namespace + 'logout',
    IS_AUTHENTICATED: namespace + 'is_authenticated',
    GET_USERNAME: namespace + 'username',
    GET_SESSION: namespace + 'session',
    UPDATE_TOKEN: namespace + 'update_token',
});

export default {
    state: () => {
        const token = loadServerToken(featurebot);
        return {
            session: token ? new UserSession(token) : null,
        };
    },
    mutations: {
        [AUTH.SET_USER]: (state, session) => {
            state.session = session;
            saveServerToken(featurebot, session ? session.token : null);
        },
        [AUTH.UPDATE_TOKEN]: (state, token) => {
            state.session = new UserSession(token);
            saveServerToken(featurebot, token);
        },
    },
    actions: {
        [AUTH.LOGIN]: async ({ commit }, { email, password }) => {
            try {
                const session = await authApi.sessions.login(email, password);
                commit(AUTH.SET_USER, session);
            } catch (error) {
                // Login failure / network error
                commit(AUTH.SET_USER, null);
                throw error;
            }
        },
        [AUTH.SSO]: async ({ commit }, ssoToken) => {
            try {
                const session = await authApi.sessions.sso(ssoToken);
                commit(AUTH.SET_USER, session);
            } catch (error) {
                // Login failure / network error
                commit(AUTH.SET_USER, null);
                throw error;
            }
        },
        [AUTH.LOGOUT]: ({ commit }) => {
            commit(AUTH.SET_USER, null);
            commit(PROJECTS.UNLOAD);
        },
    },
    getters: {
        [AUTH.IS_AUTHENTICATED]: state => state.session && state.session.isValid,
        [AUTH.GET_USERNAME]: state => state.session.fullname,
        [AUTH.GET_SESSION]: state => state.session,
    },
};
