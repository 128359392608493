<template>
    <div class="block">
        <template v-if="loading">
            <h1>{{ $t('project.info.loading') }}</h1>
            <div class="spinner"></div>
        </template>
        <template v-else-if="project">
            <h1 class="capitalize">{{ project.name }}</h1>
            <div class="row">
                <div class="col-12 col-md-6">
                    {{ $t('project.info.id', project) }}
                </div>
            </div>
        </template>
        <template v-else>
            <h1>{{ $t('project.info.no_access') }}</h1>
            <router-link :to="{ name: 'Profile' }">
                {{ $t('project.info.back_to_profile') }}
            </router-link>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectsStoremap } from '@/mixins/projects-storemap';
import { DebugToggleComputed } from '@/devbar/decorators';

@Component({
    components: {},
    mixins: [ProjectsStoremap],
})
export default class ProjectDetails extends Vue {
    @Prop()
    projectId;

    get project() {
        return this.projects.find(project => project.id === this.projectId) || null;
    }

    @DebugToggleComputed({ label: 'Loading', factory: () => true })
    get loading() {
        return this.projects_loading;
    }
}
</script>
