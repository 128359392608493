import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './auth';
import breadcrumbs from './breadcrumbs';
import locale from './locale';
import issues from './issues';
import milestones from './milestones';
import projects from './projects';
import scrumlabels from './scrumlabels';

const store = new Vuex.Store({
    modules: {
        auth,
        breadcrumbs,
        locale,
        issues,
        milestones,
        projects,
        scrumlabels,
    },
});

export default store;
