<template>
    <div class="row flex-row">
        <div class="col-12 col-lg-6 flex">
            <div class="block flex__item flex--column">
                <h1>{{ $t('profile.header_info') }}</h1>
                <div class="block__content">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-6">
                            <b>{{ $t('profile.label_username') }}</b>
                        </div>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                            {{ username }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-6">
                            <b>{{ $t('profile.label_email') }}</b>
                        </div>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                            {{ email }}
                        </div>
                    </div>
                </div>

                <router-link
                    class="button--theme align-self-start"
                    :to="{ name: 'change-password' }"
                >
                    {{ $t('profile.link_change_password') }}
                </router-link>
            </div>
        </div>
        <div class="col-12 col-lg-6 flex__item flex">
            <div class="block flex__item flex--column">
                <h1>{{ $t('profile.header_projects') }}</h1>
                <div v-if="loading" class="spinner"></div>
                <div v-else-if="errorCode" class="alert--error">
                    {{ localizedProjectsError }}
                </div>
                <ul v-else class="block_list">
                    <li class="col-12" v-for="project in projects" :key="project.id">
                        <router-link :to="projectRoute(project)">
                            <span class="capitalize">{{ project.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AUTH } from '@/store/auth';
import { PROJECTS } from '@/store/projects';
import { localizeNetworkErrorCode } from '@/api/util/network-errors';
import Optional from 'optional-js';

@Component
export default class Profile extends Vue {
    loading = true;
    errorCode = null;

    /**
     * @returns {Optional<UserSession>}
     */
    get session() {
        return Optional.ofNullable(this.$store.getters[AUTH.GET_SESSION]);
    }

    get username() {
        return this.session.map(session => session.user.name).get();
    }

    get email() {
        return this.session.map(session => session.user.email).get();
    }

    get projects() {
        return this.$store.getters[PROJECTS.GET_ALL];
    }

    created() {
        this.$store
            .dispatch(PROJECTS.FETCH)
            .catch(error => {
                this.errorCode = error.errorCode;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    get localizedProjectsError() {
        return localizeNetworkErrorCode(this.errorCode);
    }

    projectRoute(project) {
        return {
            name: 'Project',
            params: {
                project_id: project.id,
            },
        };
    }
}
</script>
