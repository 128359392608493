<template>
    <div class="issues--full_width">
        <div>
            <div class="row issues__header">
                <div class="col-10">
                    <h1>{{ $t('common.features') }}</h1>
                    <projects-dropdown
                        v-model="project_id"
                        class="multiselect--alt"
                        v-if="!hasMonoProject"
                    />
                    <span class="col-10" v-else-if="project">
                        <h1>{{ project.name }}</h1>
                    </span>
                </div>
                <div class="col">
                    <router-link class="pull-right button--cta" :to="createIssueRoute">
                        <i class="mdi mdi-plus-circle-outline" />
                        {{ $t('feature_modal.add_feature') }}
                    </router-link>
                </div>
            </div>

            <div class="row align-items-end">
                <div class="col-12 col-xl order-last order-md-0 order-xl-first issues__tabs">
                    <div class="row">
                        <a
                            href="#"
                            class="button--tab col col-md-auto"
                            :class="{ active: statusFilter === null }"
                            @click="onTabClick(null)"
                        >
                            {{ $t('common.issue_state.all') }}
                            {{
                                !loadingIssues && statusFilter === null
                                    ? ` (${allIssuesCount.all})`
                                    : ''
                            }}
                        </a>
                        <a
                            href="#"
                            class="button--tab col col-md-auto"
                            :class="{ active: statusFilter === 'opened' }"
                            @click="onTabClick('opened')"
                        >
                            {{ $t('common.issue_state.opened') }}
                            {{
                                !loadingIssues && (statusFilter === 'opened' || !statusFilter)
                                    ? ` (${allIssuesCount.open_issues})`
                                    : ''
                            }}
                        </a>
                        <a
                            href="#"
                            class="button--tab col col-md-auto"
                            :class="{ active: statusFilter === 'closed' }"
                            @click="onTabClick('closed')"
                        >
                            {{ $t('common.issue_state.closed') }}
                            {{
                                !loadingIssues && (statusFilter === 'closed' || !statusFilter)
                                    ? ` (${allIssuesCount.closed_issues})`
                                    : ''
                            }}
                        </a>
                    </div>
                </div>
                <div class="col-12 col-xl-auto order-0 order-md-first">
                    <div class="row issues__actions justify-content-end">
                        <div class="col-12 col-md-auto">
                            <sprint-dropdown v-model="sprint" class="multiselect--alt" />
                        </div>
                        <div class="col-12 col-md-auto">
                            <scrum-labels-dropdown
                                @change="setScrumLabels"
                                :disabled="scrumFilterDisabled"
                                class="multiselect--alt"
                            />
                        </div>

                        <div class="col col-md-auto" v-if="project_id">
                            <router-link
                                :to="{
                                    name: 'ProjectPriorities',
                                    params: { project_id },
                                }"
                                class="button--alt"
                            >
                                <i class="mdi mdi-sort"></i>
                                <span>{{ $t('dashboard.button_edit_priorities') }}</span>
                            </router-link>
                        </div>
                        <div class="col col-md-auto">
                            <issue-view-links />
                        </div>
                    </div>
                </div>
                <div class="col-12 order-first order-md-last">
                    <div class="issues__search-row">
                        <div class="col-12 col-md-6 col-lg-8 col-xl-8">
                            <search-box
                                v-model="searchQuery"
                                @search="submitQuery"
                                :placeholder="$t('general.search_feature')"
                            />
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                            <sort-order-dropdown
                                v-model="sortOrder"
                                :options="sortOptions"
                                :placeholder="$t('dashboard.sort_dropdown_placeholder')"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 issues__error" v-if="errorCode">
                    <div class="alert--auth">
                        <p>{{ localizedErrorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="block--table">
            <issues-table
                :issues="issues"
                :loading="loadingIssues"
                @view="showDetails"
                @edit="openEdit"
            />

            <div class="issues__pagination" v-if="pageCount > 1">
                <pagination :page-count="pageCount" v-model="currentPage" />
            </div>
        </div>
        <router-view />
    </div>
</template>
<script>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import vSelect from 'vue-select';
import Pagination from '@/components/general/Pagination';
import IssuesTable from '@/components/issues/IssuesTable';
import { ProjectIdMixin } from '@/mixins/project-id-route';
import { ERROR_CODES, localizeNetworkErrorCode } from '@/api/util/network-errors';
import { ISSUES } from '@/store/issues';
import IssueStateDropdown from '@/components/general/IssueStateDropdown';
import ProjectsDropdown from '@/components/general/ProjectsDropdown';
import SprintDropdown from '../components/general/SprintDropdown.vue';
import { issues } from '@/api';
import { PROJECTS } from '@/store/projects';
import LabelDropdown from '@/components/general/LabelDropdown';
import { DebugToggle } from '@/devbar/decorators';
import ScrumLabelsDropdown from '@/components/general/ScrumLabelsDropdown';
import IssueViewLinks from '@/components/issues/IssueViewLinks';
import SearchBox from '@/components/general/SearchBox';
import { BREADCRUMBS } from '@/store/breadcrumbs';
import { MonoProjectMixin } from '@/mixins/mono-project';
import { MonoProjectRedirectMixin } from '@/mixins/mono-project-redirect';
import SortOrderDropdown from '@/components/general/SortOrderDropdown';
import { DEFAULT_ISSUES_TAB } from '@/constants';

const ERROR_CANCEL = 'wubwub:cancel:hihi';

function allLabels() {
    return { id: -1, name: 'All labels' };
}

@Component({
    components: {
        SortOrderDropdown,
        SearchBox,
        IssueViewLinks,
        ScrumLabelsDropdown,
        LabelDropdown,
        ProjectsDropdown,
        IssueStateDropdown,
        vSelect,
        Pagination,
        IssuesTable,
        SprintDropdown,
    },
    mixins: [ProjectIdMixin, MonoProjectMixin, MonoProjectRedirectMixin],
})
export default class IssuesPage extends Vue {
    issues = [];
    currentPage = 0;
    pageCount = 1;
    issuesCount = { closed_issues: 0, open_issues: 0 };
    sprint_value = '';

    @DebugToggle({ label: 'Loading' })
    loadingIssues = true;
    @DebugToggle({
        label: 'Error',
        on: ERROR_CODES.UNKNOWN,
        off: ERROR_CODES.NONE,
    })
    errorCode = ERROR_CODES.NONE;
    ongoingRequest = null;
    cancelled = false;
    searchQuery = '';
    filter = {
        state: DEFAULT_ISSUES_TAB,
        scrumLabel: [],
        query: '',
        sortColumn: 'updated_at',
        sortAscending: false,
        label: -1,
        sprint: 0,
        input: false,
    };

    finishedLabelFilter = false;

    labelObject = allLabels();

    updated() {
        if (this.$store.getters[PROJECTS.GET_ALL].length == 0) {
            this.$store.dispatch(PROJECTS.FETCH).catch(error => {
                this.projects_error = error.errorCode;
            });
        }
    }

    get sortOptions() {
        return [
            {
                apiColumn: 'title',
                label: this.$t('dashboard.name'),
            },
            {
                apiColumn: 'project',
                label: this.$t('dashboard.project'),
            },
            {
                apiColumn: 'due_date',
                label: this.$t('dashboard.deadline'),
            },
            {
                apiColumn: 'estimated_time',
                label: this.$t('dashboard.estimate'),
            },
            {
                apiColumn: 'state',
                label: this.$t('dashboard.state'),
            },
            {
                apiColumn: 'updated_at',
                label: this.$t('dashboard.updated_at'),
            },
            {
                apiColumn: 'created_at',
                label: this.$t('dashboard.created_at'),
            },
        ];
    }

    /**
     * @returns {SortOrderSelection}
     */
    get sortOrder() {
        return {
            option: this.filter.sortColumn
                ? this.sortOptions.find(o => o.apiColumn === this.filter.sortColumn)
                : null,
            ascending: this.filter.sortAscending,
        };
    }

    /**
     * @param {SortOrderSelection} value
     */
    set sortOrder(value) {
        if (value.option && value.option.apiColumn) {
            this.filter.sortColumn = value.option.apiColumn;
            this.filter.sortAscending = value.ascending;
        } else {
            this.filter.sortColumn = null;
            this.filter.sortAscending = true;
        }
        this.fetchFirstPage();
    }

    @Watch('$route.params.project_id')
    onProjectIdChanged() {
        this.filter.state = DEFAULT_ISSUES_TAB;
    }

    @Watch('$route.query.label', { immediate: true })
    onQueryLabelChanged(value) {
        if (value) {
            this.$store.dispatch(PROJECTS.FETCH).then(projects => {
                const project = projects.find(p => p.id === this.project_id);
                this.finishedLabelFilter = true;
                this.labelObject =
                    project.labels.find(l => l.id === parseInt(value)) || allLabels();
            });
        } else {
            this.finishedLabelFilter = true;
            this.labelObject = allLabels();
        }
    }

    @Watch('labelObject')
    setLabelFilter() {
        const query = {};
        if (this.filter.label === this.labelObject.id && !this.finishedLabelFilter) return;
        this.filter.label = this.labelObject.id;
        if (this.filter.label && this.filter.label >= 0) {
            Vue.set(query, 'label', this.filter.label);
        } else {
            Vue.set(query, 'label', undefined);
        }
        const route = {
            name: this.$route.name,
            params: this.$route.params,
            query,
        };
        this.$router.replace(route);
        this.fetchFirstPage();
    }

    @Watch('project_id')
    @Watch('filter.state')
    @Watch('filter.scrumLabel')
    @Watch('filter.sprint')
    fetchFirstPage() {
        // We can trigger the refetch using the watcher on currentPage
        // But if the page is already zero then the watcher won't trigger
        // So we trigger manually if the page already is zero
        const firstPage = 0;
        if (this.currentPage !== firstPage) {
            this.currentPage = 0;
        } else {
            this.fetchIssues();
        }
    }

    @Watch('currentPage', { immediate: true })
    fetchIssues() {
        this.loadingIssues = true;
        if (this.ongoingRequest) {
            this.ongoingRequest.cancel(ERROR_CANCEL);
        }
        // Adjust the filter for needing input
        var adjustedFilter = JSON.parse(JSON.stringify(this.filter));
        if (this.filter.scrumLabel.includes(-2)) {
            adjustedFilter.scrumLabel = adjustedFilter.scrumLabel.filter(e => e != -2);
            adjustedFilter.input = true;
            console.log(adjustedFilter);
        }
        const request = issues.search({
            ...adjustedFilter,
            project_id: this.project_id,
            page: this.currentPage,
        });
        this.ongoingRequest = request.cancelSource;
        request
            .then(({ pagination, issues, issues_count }) => {
                this.errorCode = ERROR_CODES.NONE;
                this.pageCount = pagination.pages;
                this.allIssuesCount = issues_count;

                return this.$store.dispatch(ISSUES.STORE, issues).then(cachedIssues => {
                    this.issues = cachedIssues;
                });
            })
            .catch(error => {
                if (error.message !== ERROR_CANCEL) {
                    if (error.errorCode === ERROR_CODES.FORBIDDEN) {
                        const route = {
                            name: 'Home',
                        };
                        this.$router.replace(route);
                    }
                    this.errorCode = error.errorCode;
                } else {
                    this.cancelled = true;
                }
            })
            .finally(() => {
                if (!this.cancelled) this.loadingIssues = false;
                this.ongoingRequest = null;
                this.cancelled = false;
            });
    }

    get createIssueRoute() {
        return {
            name: 'Issue.New',
            params: {
                project_id: this.$route.params.project_id,
            },
        };
    }

    openEdit(obj) {
        this.$router.push({
            name: 'Issue.Edit',
            params: {
                project_id: obj.project_id,
                issueId: obj.iid,
            },
        });
    }

    showDetails(obj) {
        this.$router.push({
            name: 'Issue',
            params: {
                project_id: obj.project_id,
                issueId: obj.iid,
            },
        });
    }

    get localizedErrorMessage() {
        return localizeNetworkErrorCode(this.errorCode);
    }

    submitQuery({ updateRoute = true } = {}) {
        // The query is being submitted by the user through the interface
        this.filter.query = this.searchQuery;
        if (updateRoute) {
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    search: this.filter.query.length ? this.filter.query : undefined,
                },
            });
        }
        this.fetchFirstPage();
    }

    @Watch('$route.query.search', { immediate: true })
    onRouteSearchChanged(newValue) {
        if (!newValue) {
            newValue = '';
        }
        this.searchQuery = newValue;
        this.submitQuery({ updateRoute: false });
    }

    onIssueStateChanged(newValue) {
        if (newValue) {
            window.sessionStorage.setItem('state', JSON.stringify(newValue));
        } else {
            window.sessionStorage.removeItem('state');
        }
    }

    setScrumLabels(selectedOptions) {
        this.$set(this.filter, 'scrumLabel', selectedOptions);
    }

    get sprint() {
        return this.filter.sprint;
    }

    set sprint(value) {
        this.filter.sprint = value;
    }

    get statusFilter() {
        return typeof this.filter.state === 'number' ? null : this.filter.state;
    }

    set statusFilter(value) {
        this.filter.state = value;
    }

    get scrumFilterDisabled() {
        return this.filter.state === 'closed';
    }

    get allIssuesCount() {
        return {
            ...this.issuesCount,
            all: this.issuesCount.open_issues + this.issuesCount.closed_issues,
        };
    }

    set allIssuesCount(newCount) {
        this.issuesCount.closed_issues = newCount.closed_issues ? newCount.closed_issues : 0;
        this.issuesCount.open_issues = newCount.open_issues ? newCount.open_issues : 0;
    }

    onTabClick(newFilter) {
        this.filter.state = newFilter;
    }

    created() {
        this.$store.commit(BREADCRUMBS.ISSUES_PAGE_VISIT, 'Issues');
    }

    get project() {
        return this.$store.getters[PROJECTS.GET_ALL][0];
    }
}
</script>
